import { useEffect, useRef } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AccountProvider } from "../context/account";
import { useSession } from "../context/session";
import { APP_ROUTE } from "./constants";

const usePrevious: <T>(value: T) => T = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Private = () => {
  const { keyPair } = useSession();
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const isLoggedIn = !!keyPair;

  const signedOut = previousLocation.key !== "default";

  return isLoggedIn ? (
    <AccountProvider>
      <Outlet />
    </AccountProvider>
  ) : (
    <Navigate
      to={
        signedOut || location.pathname === "/"
          ? APP_ROUTE.SIGN_IN
          : `${APP_ROUTE.SIGN_IN}?redirect_url=${location.pathname}`
      }
    />
  );
};

export default Private;
