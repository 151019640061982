import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { useEntitiesQuery } from "../../../../api/rest/entities";
import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../../components/design-system/FormGroup";
import { Input } from "../../../../components/design-system/Input";
import { Select } from "../../../../components/design-system/Select";
import { SelectEntity } from "../../../../components/design-system/SelectEntity";
import { AddEntity } from "../../../../components/Entities/AddEntity";
import type { CompanyInformation } from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import { OptionsProgramParticipant } from "../../../../types/models/options";

const formId = "edit-shareblock-form";

type AddParticipantDialogProps = {
  title: string;
  enableCompanies: boolean;
  programType: "Warrants" | "EmployeeStockOptions";
  onClose: () => void;
  onSuccess: (value: OptionsProgramParticipant) => void;
  selectedEntities: string[];
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const AddParticipantDialog = ({
  title,
  enableCompanies,
  programType,
  onClose,
  onSuccess,
  selectedEntities,
  entitiesQuery,
  currentCompany,
}: AddParticipantDialogProps) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<OptionsProgramParticipant>({ mode: "onChange" });
  const entities = (entitiesQuery.data || []).filter(
    (x) =>
      (enableCompanies || x.type === "Private") &&
      !selectedEntities.includes(x.id)
  );
  const typeOptions = [
    { value: "Employee", label: i18n.t("options.participants.role.employee") },
    {
      value: "BoardMember",
      label: i18n.t("options.participants.role.boardMember"),
    },
  ];

  return (
    <Dialog
      isOpen
      title={title}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>{i18n.t("label.cancel")}</Button>
          <Button type="submit" variant="solid" color="primary" form={formId}>
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();
          return handleSubmit((data) => onSuccess(data))(event);
        }}
        id={formId}
      >
        <FormGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState }) => {
              return (
                <>
                  <div className="tw-flex tw-items-end tw-justify-between">
                    <FormLabel htmlFor="shareholder">
                      {i18n.t(
                        enableCompanies
                          ? "options.participants.entity"
                          : "options.participants.person"
                      )}
                    </FormLabel>
                    <AddEntity
                      currentCompany={currentCompany}
                      onSuccess={(newEntity) => {
                        entitiesQuery.refetch();
                        onChange({
                          id: newEntity.id,
                          refId: newEntity.refId,
                          type: newEntity.type,
                        });
                      }}
                      enabledEntityTypes={
                        !enableCompanies
                          ? ["PrivateWithPassport", "PrivateWithSSN"]
                          : undefined
                      }
                    />
                  </div>
                  <SelectEntity
                    options={entities}
                    value={value?.id}
                    onChange={(newValue: string | string[] | null) => {
                      if (newValue && !Array.isArray(newValue)) {
                        const entity = entities.find((x) => x.id === newValue);
                        onChange({
                          id: entity!.id,
                          refId: entity!.refId,
                          type: entity!.type,
                        });
                      }
                    }}
                    menuPosition="fixed"
                    id="selectEntity"
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              );
            }}
            name="participant"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="amountOfOptions">
            {i18n.t("options.participants.options")}
          </FormLabel>
          <Input
            id="amountOfOptions"
            {...register("amountOfOptions", {
              required: i18n.t("error.validation.required"),
              valueAsNumber: true,
              min: {
                value: 1,
                message: i18n.t("error.validation.range.min", { min: 1 }),
              },
            })}
            type="number"
            step={1}
          />
          <FormError>{errors.amountOfOptions?.message}</FormError>
        </FormGroup>
        {programType === "EmployeeStockOptions" && (
          <Controller
            name="role"
            control={control}
            rules={{ required: i18n.t("error.validation.required") }}
            render={({ field: { value, onChange, onBlur }, fieldState }) => (
              <FormGroup>
                <FormLabel htmlFor="role">
                  {i18n.t("options.participants.role")}
                </FormLabel>
                <Select
                  name="role"
                  menuPosition="fixed"
                  value={typeOptions.find((option) => option.value === value)}
                  onChange={(option) => {
                    if (option) {
                      onChange(option.value);
                    }
                  }}
                  onBlur={onBlur}
                  options={typeOptions}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </FormGroup>
            )}
          />
        )}
      </form>
    </Dialog>
  );
};

export { AddParticipantDialog };
