import "./toast.css";

import { toast } from "react-toastify";

import { clsxm } from "../../../../utils/tailwind";
import type { AlertType } from "../../Alert";
import { Alert } from "../../Alert";

const notify = Object.assign(
  (
    message: React.ReactNode,
    { type, ...options }: { type: AlertType } = { type: "neutral" }
  ) =>
    toast(
      () => (
        <Alert type={type} className="tw-h-full">
          {message}
        </Alert>
      ),
      {
        className: clsxm(
          "!tw-min-h-0 tw-w-[calc(100vw-32px)] !tw-rounded !tw-border !tw-p-2 !tw-text-body !tw-shadow-md md:tw-w-full",
          {
            "tw-border-success": type === "success",
            "tw-border-warning": type === "warning",
            "tw-border-error": type === "error",
            "tw-border-blue-500": type === "info",
            "tw-border": type === "neutral",
          }
        ),
        ...options,
      }
    ),
  toast
);

export { notify };
