import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getLocale, locales, setLocale } from "../../i18n";
import type { LoggedInUser } from "../../types/models/auth";
import { UserIcon } from "../design-system/icons";
import { Menu } from "../design-system/Menu";
import { normaliseName } from "./Navigation.utils";

const UserProfile = ({
  companyName,
  user,
  onSignout = () => undefined,
}: {
  companyName?: string;
  user?: Pick<LoggedInUser, "name" | "role">;
  onSignout: () => void;
}) => {
  const { t } = useTranslation();
  const newLocale = getLocale() === "en-US" ? "sv-SE" : "en-US";

  return (
    <Menu data-testid="navigation-user-profile">
      <Menu.Button className="tw-p-0" isDropdown={false} variant="clean">
        <UserIcon />
        <div>
          <h6 className="tw-ml-2 tw-hidden lg:tw-block">
            {normaliseName(user?.name)}
          </h6>
          <h6 className="tw-ml-2 tw-hidden tw-text-gray-300 lg:tw-block">
            {companyName}
          </h6>
        </div>
      </Menu.Button>
      <Menu.Items align="bottomRight" className="tw-text-body">
        <Menu.Item
          as="button"
          onClick={() => {
            setLocale(newLocale);
          }}
        >
          {locales[newLocale]}
        </Menu.Item>
        <Menu.Item
          as="button"
          onClick={() => {
            onSignout();
          }}
        >
          {t("label.logout")}
        </Menu.Item>
        {user?.role === "Administrator" && (
          <Menu.Items align="bottomLeft">
            <Menu.Item
              className="tw-text-warning"
              closeOnClick={false}
              as="div"
              disabled
              // eslint-disable-next-line react/jsx-no-literals
            >
              Kvanta Admin
            </Menu.Item>
            <Menu.Item as={Link} to="/administration" className="tw-px-6">
              {t("label.administration")}
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/administration/features"
              className="tw-px-6"
            >
              {t("label.administration.features")}
            </Menu.Item>
          </Menu.Items>
        )}
      </Menu.Items>
    </Menu>
  );
};

export { UserProfile };
