/* eslint-disable jsx-a11y/no-static-element-interactions */
import type React from "react";

import { clsxm } from "../../../utils/tailwind";
import type { DescriptionProps, FontSizeType } from "../Description";
import { Description } from "../Description";

type DataCardProps = DescriptionProps & {
  className?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  titleSize?: FontSizeType;
  descriptionSize?: FontSizeType;
};

const DataCard: React.FC<DataCardProps> = ({
  title,
  description,
  align = "left",
  theme = "grayBlack",
  size = "sm",
  titleSize,
  descriptionSize,
  className,
  icon,
  onClick,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={clsxm(
        "tw-flex tw-max-h-[72px] tw-items-center tw-justify-between tw-rounded tw-border tw-p-4",
        className
      )}
      onClick={onClick}
      data-testid={`card-${title}`}
    >
      <Description
        title={title}
        description={description}
        align={align}
        theme={theme}
        size={size}
        titleSize={titleSize}
        descriptionSize={descriptionSize}
      />
      <div>{icon}</div>
    </div>
  );
};

export { DataCard };
