import { z } from "zod";

import { notify } from "../../components/design-system/Notifications";
import * as config from "../../config";
import { i18n } from "../../i18n";
import { DEFAULT_LOCALE } from "../../i18n/locale";
import * as monitoring from "../../utils/monitoring";
import type { RequestErrorElement } from "..";
import { Errors, RequestError } from "..";
import { getAuthHeader } from "../auth";
import { NO_CONTENT, NOT_FOUND, UNAUTHORIZED } from "./utils";

const URL = {
  API: config.apiUrl,
  ADMIN: config.adminUrl,
  SEARCH: config.searchUrl,
};

const NoContentSchema = z.undefined();
type NoContent = z.infer<typeof NoContentSchema>;

/**
 * Returns the error message that's the most helpful. If it's a non-general
 * error that's translated, it returns the translated error message. Otherwise,
 * it returns the error text. The aim is to make the error message useful in our
 * Sentry alerts.
 *
 * @param e Error-like object
 * @returns Error message
 */
const maybeErrorMessage = (e?: RequestErrorElement) => {
  if (e === undefined) {
    return undefined;
  }

  const errorMessagePrefix = e.field
    ? `Error in field ${JSON.stringify(e.field)}`
    : undefined;

  const errorMessageByCode = i18n.t(e.message.code, { lng: DEFAULT_LOCALE });
  const errorMessageByText = e.message.text ?? "";

  const hasUsefulCode = e.message.code !== "error.general";
  const hasUsefulText = errorMessageByText.trim() !== "";

  const preferErrorMessageByText = !hasUsefulCode && hasUsefulText;

  const errorMessage = preferErrorMessageByText
    ? errorMessageByText
    : errorMessageByCode;

  const result = [errorMessagePrefix, errorMessage].filter(Boolean).join(": ");

  return result;
};

const getResponseData = async <T>(response: Response): Promise<T | string> => {
  if (response.status === NO_CONTENT) {
    return undefined as T;
  }

  try {
    // NOTE: Using "await" ensures that any errors from `response.json()` are
    // caught in this function, allowing the catch block to handle them
    // immediately. Without "await", the Promise is returned directly and errors
    // from `response.json()` would be caught outside this function, bypassing
    // the current catch block.
    const json: T = await response.json();

    return json;
  } catch {
    return response.text();
  }
};

type IRequestOptions = Omit<RequestInit, "body"> & { body?: object };

type RestClientConfig = { hasAuth?: boolean };

const useClient =
  (clientConfig?: RestClientConfig) =>
  async <T>(
    url: string,
    { body, ...options }: IRequestOptions = {},
    ignoreStatusCodes: number[] = []
  ): Promise<T> => {
    const auth = clientConfig?.hasAuth ? getAuthHeader() : undefined;
    const requestOptions: RequestInit = {
      method: body ? "POST" : "GET",
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...(auth ? { Authorization: JSON.stringify(auth) } : {}),
        ...options.headers,
      },
    };
    if (body) {
      requestOptions.body = JSON.stringify(body);
    }

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      if (response.status === UNAUTHORIZED) {
        const error = new RequestError(Errors.unauthenticated);
        notify(error.errors[0].message.text, { type: "error" });
        throw error;
      } else {
        const error = new Error(`${requestOptions.method || "GET"} ${url}`);
        const urlNormalised = url
          .replace(/^(?:https?:\/\/)?(?:www\.)?[^/]+/, "")
          .replace(/\/\d+(?=\/|$)/g, "");
        monitoring.captureException(error, {
          fingerprint: [requestOptions.method || "GET", urlNormalised],
          level: ignoreStatusCodes.includes(response.status)
            ? "warning"
            : "error",
          extra: {
            status: response.status,
            responseBody:
              (response.headers.get("content-type") || "") ===
              "application/json"
                ? response.json()
                : null,
            requestOptions,
          },
        });
        if (
          response.status === NOT_FOUND &&
          ignoreStatusCodes.includes(NOT_FOUND)
        ) {
          return null as T;
        }
        throw error;
      }
    }

    const responseData = await getResponseData(response);

    return responseData as T;
  };

export { getResponseData, maybeErrorMessage, NoContentSchema, URL };
export type { NoContent, RestClientConfig };
export default useClient;
