import { getLocale } from "../i18n";
import type { Address } from "../types/models/entities";
import { getCountryName } from "./country";
import { getFormattedDate } from "./date";

const EN_DASH = "\u2013";

const formatNumber = (value: number) => {
  const formatter = Intl.NumberFormat(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 6,
  });

  return formatter.format(value);
};

const formatPercentage = (value: number, minimumFractionDigits: number = 2) => {
  const formatter = Intl.NumberFormat(getLocale(), {
    style: "percent",
    minimumFractionDigits,
    maximumFractionDigits: 2,
  });

  return formatter.format(value).replace(/\s+(?=%)/g, "");
};

const formatCurrency = (value: number, currency?: string) => {
  if (!currency) {
    return formatNumber(value);
  }
  const formatter = Intl.NumberFormat(getLocale(), {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
  });

  return formatter.format(value);
};

const formatRefId = ({
  refId,
  countryCode,
  birthDate,
  type,
}: {
  refId: string;
  countryCode: string;
  birthDate?: string | null;
  type: "Private" | "Company";
}): string => {
  const hasBirthDate = type === "Private" && typeof birthDate === "string";
  const isCompany = type === "Company";

  const isSwedishSSN = refId?.length === 12;
  const isSwedishCompany =
    isCompany && countryCode === "SE" && refId?.length === 10;
  const refIdIsBirthDate = refId?.length === 8;

  if (isSwedishSSN || isSwedishCompany) {
    const index = refId.length - 4;
    const parts = [refId.slice(0, index), refId.slice(index)];
    return parts.join("-");
  }

  if (isCompany) {
    return refId;
  }

  if (refIdIsBirthDate || hasBirthDate) {
    const birthDateValue = birthDate ?? refId;

    const date = `${birthDateValue.slice(0, 4)}-${birthDateValue.slice(
      4,
      6
    )}-${birthDateValue.slice(6, 8)}`;

    const formattedDate = getFormattedDate(date);

    if (formattedDate !== undefined) {
      return formattedDate;
    }
    console.error("Couldn't format date", refIdIsBirthDate);
  }

  return "";
};

const formatAddress = ({
  line1,
  line2,
  postalCode,
  city,
  region,
  countryCode,
}: Address) =>
  [
    [line1, line2].filter(Boolean).join(" "),
    postalCode,
    city,
    region,
    getCountryName(countryCode),
  ]
    .filter(Boolean)
    .join(", ");

const formatRange = (start: number, end: number) =>
  [start, end].map(formatNumber).join(EN_DASH);

export {
  formatAddress,
  formatCurrency,
  formatNumber,
  formatPercentage,
  formatRange,
  formatRefId,
};
