import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";

import { Alert } from "../../components/design-system/Alert";
import { Button } from "../../components/design-system/Button";
import BankID from "../../components/design-system/icons/BankId";
import { Loading } from "../../components/design-system/Loading";
import { MethodStatus } from "..";

const BankIdControls = ({
  title,
  status,
  error,
  qrCode,
  sameDevice,
  retry,
  cancel,
}: {
  title: string;
  status: MethodStatus;
  error?: JSX.Element | string;
  qrCode?: string;
  sameDevice?: () => void;
  retry: () => void;
  cancel: () => void;
}) => {
  const i18n = useTranslation();

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <h3 className="tw-text-center">{title}</h3>
      {status === "inProgress" && (
        <>
          {qrCode ? (
            <QRCodeSVG
              fgColor="currentColor"
              size={256}
              value={qrCode}
              className="tw-my-2"
              role="img"
              aria-label="QR Code"
            />
          ) : (
            <Loading />
          )}
          {sameDevice && (
            <Button
              onClick={sameDevice}
              prefix={<BankID />}
              className="tw-my-2"
            >
              {i18n.t("login.bankId.sameDevice")}
            </Button>
          )}
          <Button onClick={cancel}>{i18n.t("label.cancel")}</Button>
        </>
      )}
      {status === "error" && (
        <>
          <Alert
            type="error"
            className="tw-my-4 tw-max-w-lg tw-rounded tw-border tw-border-error tw-p-2"
          >
            {error}
          </Alert>
          <Button className="tw-my-2" onClick={retry}>
            {i18n.t("label.retry")}
          </Button>
        </>
      )}
    </div>
  );
};

export default BankIdControls;
