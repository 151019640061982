import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Navigate, useParams } from "react-router-dom";

import { Button } from "../../components/design-system/Button";
import { Drawer } from "../../components/design-system/Drawer";
import {
  CertificateIcon,
  ChevronDownIcon,
  CrossIcon,
  IncreaseCapitalIcon,
  PledgedIcon,
  PlusIcon,
  ReclassificationIcon,
  ReduceCapitalIcon,
  ShareClassUpdateIcon,
  ShareIssueIcon,
  SplitIcon,
  TransferIcon,
} from "../../components/design-system/icons";
import { Align, Menu } from "../../components/design-system/Menu";
import { IconDescription } from "../../components/IconDescription";
import type { Ti18nKey } from "../../i18n";
import { APP_ROUTE } from "../../routes/constants";
import { clsxm } from "../../utils/tailwind";

type EventType =
  | "increasecapital"
  | "issueshares"
  | "pledgedsharesupdate"
  | "reclassification"
  | "reducecapital"
  | "reversesplit"
  | "sharecertificateupdate"
  | "sharetypeupdate"
  | "split"
  | "transfer";
const events: {
  type: EventType;
  title: Ti18nKey;
  description: Ti18nKey;
  Icon: typeof TransferIcon;
}[] = [
  {
    type: "transfer",
    title: "events.transfer.title",
    description: "events.transfer.description",
    Icon: TransferIcon,
  },
  {
    type: "split",
    title: "events.split.title",
    description: "events.split.description",
    Icon: SplitIcon,
  },
  {
    type: "reversesplit",
    title: "events.reverseSplit.title",
    description: "events.reverseSplit.description",
    Icon: CrossIcon,
  },
  {
    type: "issueshares",
    title: "events.issue.title",
    description: "events.issue.description",
    Icon: ShareIssueIcon,
  },
  {
    type: "reducecapital",
    title: "events.reduceCapital.title",
    description: "events.reduceCapital.description",
    Icon: ReduceCapitalIcon,
  },
  {
    type: "increasecapital",
    title: "events.increaseCapital.title",
    description: "events.increaseCapital.description",
    Icon: IncreaseCapitalIcon,
  },
  {
    type: "reclassification",
    title: "events.reclassification.title",
    description: "events.reclassification.description",
    Icon: ReclassificationIcon,
  },
  {
    type: "sharetypeupdate",
    title: "events.shareTypeUpdate.title",
    description: "events.shareTypeUpdate.description",
    Icon: ShareClassUpdateIcon,
  },
  {
    type: "sharecertificateupdate",
    title: "events.shareCertificateUpdate.title",
    description: "events.shareCertificateUpdate.description",
    Icon: CertificateIcon,
  },
  {
    type: "pledgedsharesupdate",
    title: "events.pledgedSharesUpdate.title",
    description: "events.pledgedSharesUpdate.description",
    Icon: PledgedIcon,
  },
];

type AddEventProps = {
  disabled?: boolean;
  className?: string;
  align?: Align;
  isDropdown?: boolean;
};

const AddEvent = ({
  disabled,
  className,
  align,
  isDropdown = true,
}: AddEventProps) => {
  const i18n = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [currentAction, setCurrentAction] = useState<EventType>();
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 640px)",
  });

  return (
    <>
      <Menu className={clsxm("md:tw-relative", className)}>
        <Menu.Button
          className="tw-w-full"
          color="secondary"
          variant="outline"
          disabled={disabled}
          isDropdown={isDropdown}
          icon={
            (isMobileDevice && <PlusIcon className="sm:tw-hidden" />) || (
              <ChevronDownIcon />
            )
          }
        >
          {isDropdown ? i18n.t("events.add") : <PlusIcon />}
        </Menu.Button>
        <Menu.Items
          align={align}
          className="tw-absolute tw-z-50 tw-overflow-y-scroll max-md:tw-w-full max-sm:tw-max-h-[500px] md:tw-z-10"
        >
          {events.map((event) => (
            <Menu.Item
              key={event.type}
              as="button"
              onClick={() => setCurrentAction(event.type)}
            >
              <IconDescription
                title={i18n.t(event.title)}
                description={i18n.t(event.description)}
                icon={<event.Icon className="tw-h-5 tw-w-5" />}
              />
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
      {currentAction && companyId !== undefined && (
        <Navigate
          to={`${APP_ROUTE.COMPANIES}/${companyId}/shares/${currentAction}`}
        />
      )}
    </>
  );
};

const AddEventDrawer = ({ disabled, className }: AddEventProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<EventType | null>(null);
  const { companyId } = useParams<{ companyId: string }>();
  const { t } = useTranslation();

  const openDrawer = () => setIsOpen(true);
  const closeDrawer = () => setIsOpen(false);

  return (
    <>
      <Button
        className={clsxm("tw-w-full", className)}
        onClick={openDrawer}
        disabled={disabled}
      >
        {t("events.add")}
        <PlusIcon className="tw-ml-2" />
      </Button>

      <Drawer isOpen={isOpen} position="bottom" onClose={closeDrawer}>
        <div className="tw-flex tw-h-[80vh] tw-flex-col tw-overflow-y-auto">
          {events.map((event) => (
            <button
              type="button"
              key={event.type}
              className="tw-px-4 tw-py-3.5 tw-text-left"
              onClick={() => {
                setCurrentAction(event.type);
                closeDrawer();
              }}
            >
              <IconDescription
                title={t(event.title)}
                description={t(event.description)}
                icon={<event.Icon className="tw-h-5 tw-w-5" />}
              />
            </button>
          ))}
        </div>
      </Drawer>

      {currentAction && companyId && (
        <Navigate
          to={`${APP_ROUTE.COMPANIES}/${companyId}/shares/${currentAction}`}
        />
      )}
    </>
  );
};

export { AddEventDrawer };
export default AddEvent;
