import { useTranslation } from "react-i18next";

import { useUsersQuery } from "../../../../../api/blockchain/users";
import { Loading } from "../../../../../components/design-system/Loading";
import { ManageSupportAccess } from "../../../../../components/Support/ManageSupportAccess";
import type { CompanyInvolvement } from "../../../../../types/models/company";
import { supportUserId } from "../../../../../utils/user";
import { getSupportUserRole } from "./SupportPage.utils";

const SupportSection = ({
  currentCompany,
}: {
  currentCompany: CompanyInvolvement;
}) => {
  const i18n = useTranslation();
  const usersQuery = useUsersQuery(currentCompany.orgNumber);

  if (usersQuery.isLoading) {
    return <Loading />;
  }

  const supportUser = usersQuery.data?.find(
    (user) => user.user.id === supportUserId
  );
  const supportUserRole = getSupportUserRole(supportUser);
  const canEdit =
    currentCompany.access === "Administrator" ||
    currentCompany.access === "BoardMember";

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div>
        <h2 className="tw-text-xl tw-font-medium">
          {i18n.t("support.supportAccess.title")}
        </h2>
        <p className="tw-text-sm tw-text-secondary">
          {i18n.t("support.supportAccess.description")}
        </p>
      </div>
      <div>
        <ManageSupportAccess
          enabled={canEdit}
          currentCompany={currentCompany}
          onSuccess={() => usersQuery.refetch()}
          currentSupportRole={supportUserRole}
        />
      </div>
    </div>
  );
};

export { SupportSection };
