import { KeyPair } from "postchain-client";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRenewSessionQuery } from "../api/blockchain/auth";
import { getKeyPairFromStorage, setKeyPairInStorage } from "../auth/utils";
import { Loading } from "../components/design-system/Loading";
import { APP_ROUTE } from "../routes/constants";
import type { LoggedInUser } from "../types/models/auth";
import { LoggedInUserSchema } from "../types/models/auth";
import { Hotjar } from "../utils/hotjar";
import * as monitoring from "../utils/monitoring";

type LoginProviders = "BankId" | "Truid";

const userStorageKey = "user";

const renewSessionRefetchInterval = 600 * 1000;

const setLoginTypeInStorage = (loginType: LoginProviders) => {
  window.localStorage.setItem("loginType", loginType);
};
// # Remove once https://github.com/capchapdev/capchap-frontend/pull/1757 is completed!
const getLoginTypeInStorage = () => {
  return window.localStorage.getItem("loginType");
};
// #

const removeLoginTypeInStorage = () => {
  window.localStorage.removeItem("loginType");
};

const getUserFromStorage = () => {
  const string = window.localStorage.getItem(userStorageKey);

  return string ? LoggedInUserSchema.parse(JSON.parse(string)) : undefined;
};

const setUserInStorage = (user: LoggedInUser) => {
  window.localStorage.setItem(userStorageKey, JSON.stringify(user));
};
const removeUserFromStorage = () => {
  window.localStorage.removeItem(userStorageKey);
};

const removeKeyPairFromStorage = (key: "keyPair" | "pendingKeyPair") => {
  window.localStorage.removeItem(key);
};

const getKeyPair = () => getKeyPairFromStorage("keyPair");

type Session = {
  user?: LoggedInUser;
  keyPair?: KeyPair;
  loginType?: LoginProviders;
  onSignout: () => void;
  handleSignedIn: (data: { user: LoggedInUser }, type: LoginProviders) => void;
};
const SessionContext = createContext<Session>({
  onSignout: () => "",
  handleSignedIn: () => "",
});

const useSession = () => {
  const session = useContext(SessionContext);

  return session;
};

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageIsVisible, setPageIsVisible] = useState(true);
  const [user, setUser] = useState<LoggedInUser>();
  const [keyPair, setKeyPair] = useState<KeyPair | undefined>(getKeyPair());
  const [loading, setLoading] = useState(false);
  const [loginType, setLoginType] = useState<LoginProviders>();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  monitoring.setUser(user);
  Hotjar.identify(user);

  const handleRedirect = () => {
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get("redirect_url");
    navigate(
      redirectUrl &&
        Object.values(APP_ROUTE).some((item) => redirectUrl.startsWith(item))
        ? redirectUrl
        : APP_ROUTE.HOME
    );
  };

  const handleSignedIn = (
    data: { user: LoggedInUser },
    type: LoginProviders
  ) => {
    const loggedInUser = data.user;
    const pendingKeyPairFromStorage = getKeyPairFromStorage("pendingKeyPair");
    if (!loggedInUser || !pendingKeyPairFromStorage) {
      handleSignout();
      return;
    }
    removeKeyPairFromStorage("pendingKeyPair");
    setKeyPairInStorage("keyPair", pendingKeyPairFromStorage);
    setKeyPair(pendingKeyPairFromStorage);
    setUserInStorage(loggedInUser);
    setUser(loggedInUser);
    setLoginType(type);
    setLoginTypeInStorage(type);

    if (location.pathname === APP_ROUTE.SIGN_IN) {
      handleRedirect();
    }

    handleReset();

    monitoring.addBreadcrumb({
      category: "session",
      message: "user signed in",
      level: "info",
    });
  };

  const handleSignout = () => {
    removeUserFromStorage();
    removeKeyPairFromStorage("keyPair");
    removeLoginTypeInStorage();
    setKeyPair(undefined);
    setUser(undefined);
    setLoginType(undefined);

    monitoring.addBreadcrumb({
      category: "session",
      message: "user signed out",
      level: "info",
    });
  };

  const handleReset = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useRenewSessionQuery({
    onSuccess: (data) => {
      setLoading(false);
      if (!data) {
        handleSignout();
        return;
      }

      setKeyPairInStorage("keyPair", data.keyPair);
      setKeyPair(data.keyPair);
      setUserInStorage(data.user);
      setUser(data.user);
      if (location.pathname === APP_ROUTE.SIGN_IN) {
        handleRedirect();
      }
    },
    retry: false,
    refetchInterval: renewSessionRefetchInterval,
    refetchIntervalInBackground: false,
    enabled: user && pageIsVisible,
  });

  useEffect(() => {
    const callback = () => {
      setPageIsVisible(window.document.visibilityState === "visible");
    };

    window.document.addEventListener("visibilitychange", callback);

    return () => {
      window.document.removeEventListener("visibilitychange", callback);
    };
  }, []);

  if (loading && !user) {
    return <Loading />;
  }

  return (
    <SessionContext.Provider
      value={{
        onSignout: handleSignout,
        handleSignedIn,
        user,
        keyPair,
        loginType,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export {
  getKeyPair,
  getLoginTypeInStorage,
  getUserFromStorage,
  SessionProvider,
  useSession,
};
