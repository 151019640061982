import type { EntitiesMap, Entity } from "../../../types/models/entities";
import { hiddenLedgerEvents, TParentEvent } from "../../../types/models/events";
import * as monitoring from "../../../utils/monitoring";

/**
 * !IMPORTANT! This is a hack and should be removed. We currently have
 * discrepancies between the entities in the blockchain and our backend APIs.
 * This is a temporary solution to map entities and we need to look for a better
 * long-term solution.
 *
 * @param entitiesMap The map of entities
 * @param entity An entity with an id and an optional unmaskedRefId
 * @returns An entity from the entitiesMap or a fallback entity if the entity is not found in the entitiesMap
 */
export const getEntityWithFallback = (
  entitiesMap: EntitiesMap,
  entity: {
    id: string;
    /**
     * `unmaskedRefId` will only exist in the patched share-transfer events. We
     * don't want it to be in the regular type definition of EventEntity.
     */
    unmaskedRefId?: string;
  }
): Entity | { name: string } => {
  if (Object.entries(entitiesMap).length === 0) {
    monitoring.captureException(new TypeError("Entities map is empty"), {
      contexts: { entity },
    });
    return { name: "N/A" };
  }

  const regularEntity = entitiesMap[entity.id];
  if (regularEntity !== undefined) {
    return regularEntity;
  }

  const fallbackEntity =
    "unmaskedRefId" in entity && typeof entity.unmaskedRefId === "string"
      ? Object.values(entitiesMap).find(
          ({ refId }) => refId === entity.unmaskedRefId
        )
      : undefined;

  if (fallbackEntity !== undefined) {
    return fallbackEntity;
  }

  /**
   * In case we still can't map an entity, we get an alert in Sentry and return
   * a fallback entity, just so the UI doesn't break.
   */
  monitoring.captureException(
    new TypeError("Couldn't map fallback entity using unmasked refId"),
    {
      contexts: {
        entity,
        entitiesMap: {
          keys: Object.keys(entitiesMap),
          stringified: JSON.stringify(entitiesMap),
        },
      },
    }
  );

  /**
   * Intentionally left small, so we get a compile-error if we need different
   * data from a fallback
   * */
  return { name: "N/A" };
};

export const filterEvents = (events: TParentEvent[]) => {
  return events.filter((e) => !hiddenLedgerEvents.includes(e.type));
};

export const isFirstEventInList = (
  events: TParentEvent[],
  event: TParentEvent
) => {
  const filteredEvents = filterEvents(events);
  return filteredEvents[0]?.id === event.id;
};
