import { CheckSquare } from "@phosphor-icons/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { useVerifyEmailMutation } from "../../api/rest/entities";
import { Alert } from "../../components/design-system/Alert";
import { Button } from "../../components/design-system/Button";
import { Loading } from "../../components/design-system/Loading";
import { PublicPageLayout } from "../../components/PublicPageLayout";
import { APP_ROUTE } from "../../routes/constants";

const EmailVerificationPage = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { companyId } = params;

  const i18n = useTranslation();

  const token = searchParams.get("token");
  const id = searchParams.get("id");
  const verifyEmailMutation = useVerifyEmailMutation();

  useEffect(() => {
    if (!token || !companyId || !id) {
      return;
    }
    verifyEmailMutation.mutate({
      code: token,
      orgNumber: companyId,
      id,
    });
  }, [token, id, companyId]);

  return (
    <PublicPageLayout>
      <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4 tw-p-6 tw-text-center">
        {verifyEmailMutation.isLoading && <Loading />}
        {verifyEmailMutation.isSuccess && (
          <>
            <CheckSquare className="tw-h-20 tw-w-20" />
            <h3>{i18n.t("emailVerification.success")}</h3>
            <Link to={`${APP_ROUTE.COMPANIES}/${companyId}`}>
              <Button
                isLoading={verifyEmailMutation.isLoading}
                color="primary"
                variant="solid"
              >
                {i18n.t("label.shareLedger")}
              </Button>
            </Link>
          </>
        )}
        {verifyEmailMutation.isError && (
          <>
            <Alert type="error">
              {i18n.t("error.verification.emailVerification.expired")}
            </Alert>

            <Link to={`${APP_ROUTE.COMPANIES}/${companyId}`}>
              <Button color="primary" variant="solid">
                {i18n.t("label.shareLedger")}
              </Button>
            </Link>
          </>
        )}
      </div>
    </PublicPageLayout>
  );
};

export { EmailVerificationPage };
