import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import {
  useLedgerQuery,
  useShareblocksQuery,
  useShareTypesQuery,
} from "../../api/blockchain/company";
import {
  ledgerDocumentDownload,
  useShareBlockHistory,
} from "../../api/rest/company";
import { useEntitiesQuery } from "../../api/rest/entities";
import { BottomBar } from "../../components/design-system/BottomBar";
import { DataCard } from "../../components/design-system/DataCard";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CollapseIcon,
  ExpandIcon,
  SearchIcon,
} from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { List } from "../../components/design-system/List";
import { Loading } from "../../components/design-system/Loading";
import { Menu } from "../../components/design-system/Menu";
import { IconDescription } from "../../components/IconDescription";
import { NoData } from "../../components/NoData";
import { PageWrapper } from "../../components/PageWrapper";
import { SelectionMenuItem } from "../../components/SelectionMenuItem";
import type { TDisplayProperties } from "../../components/ShareBlocks/DetailedBlockRow";
import {
  defaultDisplay,
  DetailedBlockRow,
} from "../../components/ShareBlocks/DetailedBlockRow";
import { ShareBlockDialog } from "../../components/ShareBlocks/Dialog";
import { ShareTypeDrawer } from "../../components/ShareBlocks/Drawer";
import type { SortBy } from "../../components/ShareBlocks/ShareBlocks.utils";
import {
  addSharesToSharesTypesData,
  getTotalSharesByType,
  sortBlocks,
} from "../../components/ShareBlocks/ShareBlocks.utils";
import { ShareLedgerHeader } from "../../components/ShareBlocks/ShareLedgerHeader";
import { SharesPrint } from "../../components/SharesPrint";
import { useRestrictiveConditionOptions } from "../../components/ShareTypes/SelectRestrictiveConditions";
import { useCompanyUtils } from "../../context/account";
import { getLocale } from "../../i18n";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { ShareTypeWithShares } from "../../types/models/shares";
import { getFormattedDate } from "../../utils/date";
import { downloadBlob } from "../../utils/download";
import { makeAndDownloadExcel } from "../../utils/excel";
import { generateShareledgerData } from "../../utils/excel-utils";
import { formatNumber } from "../../utils/format";
import * as monitoring from "../../utils/monitoring";
import {
  countTotalShares,
  countTotalVotes,
  getActiveBlocks,
} from "../../utils/shares";
import { clsxm } from "../../utils/tailwind";
import type { LedgerVersionDetails } from "./useLedgerVersions";

type Props = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  ledgerVersions: LedgerVersionDetails[];
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
};

type ActiveTab = "shareLedger" | "keyData";

const defaultDialogContent: ShareTypeWithShares = {
  name: "",
  voteValue: 1,
  condition: {
    consent: false,
    conversion: false,
    offerOfFirstRefusal: false,
    preemption: false,
    redemption: false,
  },
  shares: 1,
};

const Shareblocks = ({
  currentCompany,
  ledgerVersions,
  selectedVersion,
  setSelectedVersion,
}: Props) => {
  const { formatCurrency } = useCompanyUtils();
  const i18n = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>("blockNumber-asc");
  const [expandedBlocks, setExpandedBlocks] = useState<Record<number, boolean>>(
    {}
  );
  const [showCancelledShareBlocks, setShowCancelledShareBlocks] =
    useState<boolean>(false);
  const [display, setDisplay] = useState(defaultDisplay);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] =
    useState<ShareTypeWithShares>(defaultDialogContent);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedShareType, setSelectedShareType] =
    useState<ShareTypeWithShares>(defaultDialogContent);
  const [activeTab, setActiveTab] = useState<ActiveTab>("shareLedger");
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const isDesktopOrTabletDevice = useMediaQuery({
    query: "(min-width: 769px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const shareBlocksQuery = useShareblocksQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );
  const shareBlocks = shareBlocksQuery.data || [];

  const shareBlockHistoryQuery = useShareBlockHistory(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );
  const shareBlockHistory = shareBlockHistoryQuery.data || [];

  const conditionOptions = useRestrictiveConditionOptions();

  const shareTypesQuery = useShareTypesQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );
  const shareTypesData = shareTypesQuery.data || [];
  const shareTypesMap = Object.fromEntries(
    shareTypesData.map((e) => [e.name, e])
  );
  const totalSharesByType = getTotalSharesByType(shareBlocks);

  const ledgerQuery = useLedgerQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );
  const ledgerData = ledgerQuery.data;
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));
  const isLoading = shareBlocksQuery.isLoading || entitiesQuery.isLoading;
  const isSuccess = shareBlocksQuery.isSuccess && entitiesQuery.isSuccess;
  const errorCode =
    shareBlocksQuery.error?.errors[0]?.message.code ||
    entitiesQuery.error?.errors[0]?.message.code;

  const filteredShareBlocks = searchValue
    ? shareBlocks.filter((sb) => {
        const entity = entitiesMap[sb.holder.id];

        return (
          entity?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          entity?.refId.toLowerCase().includes(searchValue.toLowerCase()) ||
          sb.type.toLowerCase().includes(searchValue.toLowerCase())
        );
      })
    : shareBlocks;

  const visibleShareBlocks = filteredShareBlocks.filter(
    (block) => showCancelledShareBlocks || !block.cancelled
  );

  const sortedBlocks = sortBlocks(visibleShareBlocks, sortBy, entitiesMap);

  const displayMenuItems: Record<keyof TDisplayProperties, string> = {
    address: i18n.t("label.ownershipInfo"),
    email: i18n.t("label.email"),
    phone: i18n.t("label.phone"),
    since: i18n.t("label.shareholderSince"),
    entry: i18n.t("label.entry"),
    keyFigures: i18n.t("label.votes"),
    restrictiveConditions: i18n.t("shares.restrictiveConditions"),
    creditor: i18n.t("label.creditor"),
    certificates: i18n.t("shares.certificates"),
    history: i18n.t("shares.history"),
    trustees: i18n.t("label.trustees"),
  };
  const activeBlocks = getActiveBlocks(shareBlocks);
  const numberOfShareholders = new Set(
    activeBlocks.map((block) => block.holder.id)
  ).size;

  const totalShares = countTotalShares(activeBlocks);
  const totalVotes = countTotalVotes(activeBlocks, shareTypesMap);
  const shareCapital = ledgerData?.capital || 1;
  const quotaValue = shareCapital / totalShares;
  const approvedLedgerVersions = ledgerVersions.filter(
    (version) => version.isApproved
  );

  const versionLabel = selectedVersion
    ? `${getFormattedDate(selectedVersion.date)} ${i18n.t("label.version")} ${
        selectedVersion.version
      }`
    : "";

  const shareTypesDataWithShares = addSharesToSharesTypesData(
    shareTypesData,
    totalSharesByType
  );

  const handleCardClick = (shareTypeName: string) => {
    const shareTypeData = shareTypesDataWithShares.find(
      (shareType) => shareType.name === shareTypeName
    );

    if (!shareTypeData) {
      return;
    }

    if (isTabletOrMobileDevice) {
      setSelectedShareType(shareTypeData);
      setDrawerOpen(true);
    } else {
      setDialogContent(shareTypeData);
      setDialogOpen(true);
    }
  };

  const downloadLedger = async () => {
    setIsDownloadLoading(true);
    const response = await ledgerDocumentDownload(
      currentCompany.orgNumber,
      selectedVersion!.formatedValue
    );
    if (response.status === 200) {
      const blob = await response.blob();
      downloadBlob(
        blob,
        `${i18n.t("label.shareLedger")}_${currentCompany.name}_${
          currentCompany.orgNumber
        }_${selectedVersion!.formatedValue}.pdf`
      );
    } else {
      console.error(response);
      monitoring.captureException(
        new Error("Error downloading ledger document"),
        {
          extra: {
            status: response.status,
            text: response.statusText,
            company: currentCompany.orgNumber,
            version: selectedVersion?.formatedValue,
          },
        }
      );
    }
    setIsDownloadLoading(false);
  };

  return (
    <>
      <SharesPrint
        currentCompany={currentCompany}
        numberOfShareholders={numberOfShareholders}
        ledgerData={ledgerQuery.data}
        currentVersion={selectedVersion}
        approvedLedgerVersions={approvedLedgerVersions}
      />
      <nav className="tw-border-b tw-px-4 tw-py-4 max-md:tw-mb-6 max-md:tw-hidden">
        <ShareLedgerHeader
          currentCompany={currentCompany}
          ledgerVersions={ledgerVersions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          sortedBlocks={sortedBlocks}
          entitiesMap={entitiesMap}
          shareTypesMap={shareTypesMap}
          shareBlockHistory={shareBlockHistory}
          isDownloadLoading={isDownloadLoading}
          downloadLedger={downloadLedger}
        />
      </nav>
      {isTabletOrMobileDevice && (
        <div className="tw-flex tw-gap-6 tw-px-4 tw-pb-4 max-md:tw-pt-6">
          <button
            type="button"
            onClick={() => setActiveTab("shareLedger")}
            className="tw-relative tw-pb-1.5"
          >
            <span
              className={clsxm("tw-font-medium", {
                "tw-text-primary": activeTab === "shareLedger",
                "tw-text-neutral-500": activeTab !== "shareLedger",
              })}
            >
              {i18n.t("label.shareLedger")}
            </span>
            {activeTab === "shareLedger" && (
              <span className="tw-absolute tw-bottom-0 tw-left-1/2 tw-h-0.5 tw-w-5 tw--translate-x-1/2 tw-transform tw-bg-primary" />
            )}
          </button>
          <button
            type="button"
            onClick={() => setActiveTab("keyData")}
            className="tw-relative tw-pb-1.5"
          >
            <span
              className={clsxm("tw-font-medium", {
                "tw-text-primary": activeTab === "keyData",
                "tw-text-neutral-500": activeTab !== "keyData",
              })}
            >
              {`${i18n.t("label.keyFigures")} & ${i18n.t("label.shareClass")}`}
            </span>
            {activeTab === "keyData" && (
              <span className="tw-absolute tw-bottom-0 tw-left-1/2 tw-h-0.5 tw-w-5 tw--translate-x-1/2 tw-transform tw-bg-primary" />
            )}
          </button>
        </div>
      )}
      <PageWrapper
        data-testid="shares-layout"
        className="tw-pt-0 max-md:tw-px-0"
      >
        {errorCode && (
          <NoData
            type="error"
            title={i18n.t("error.fetch")}
            description={i18n.t(errorCode)}
          />
        )}
        {isLoading && <Loading />}
        {isSuccess && (
          <div className="md:tw-flex md:tw-flex-row">
            {(isDesktopOrTabletDevice || activeTab === "shareLedger") && (
              <div className="tw-w-5/8 tw-flex-grow tw-space-y-4 md:tw-border-r md:tw-pr-8 md:tw-pt-6">
                <div className="tw-flex tw-flex-col tw-content-start tw-gap-2 print:tw-hidden md:tw-flex-row">
                  <div className="tw-flex tw-gap-2 max-md:tw-grid max-md:tw-grid-cols-1 max-md:tw-px-4">
                    <Input
                      className="tw-h-12 tw-w-full md:tw-w-52"
                      placeholder={i18n.t("label.search")}
                      prefix={<SearchIcon />}
                      type="search"
                      value={searchValue}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                    />
                    <Menu>
                      <Menu.Button className="tw-w-full tw-rounded max-md:tw-hidden md:tw-w-auto">
                        {i18n.t("label.view")}
                      </Menu.Button>
                      <Menu.Items align="bottomLeft">
                        <div>
                          <Menu.Item
                            closeOnClick={false}
                            onClick={() =>
                              setExpandedBlocks(
                                Object.fromEntries(
                                  shareBlocks.map((block) => [
                                    block.start,
                                    true,
                                  ])
                                )
                              )
                            }
                          >
                            <IconDescription
                              title={
                                <span className="tw-text-base">
                                  {i18n.t("label.expandAll")}
                                </span>
                              }
                              icon={<ExpandIcon className="tw-h-5 tw-w-5" />}
                            />
                          </Menu.Item>
                          <Menu.Item
                            closeOnClick={false}
                            onClick={() => setExpandedBlocks({})}
                          >
                            <IconDescription
                              title={
                                <span className="tw-text-base">
                                  {i18n.t("label.collapseAll")}
                                </span>
                              }
                              icon={<CollapseIcon className="tw-h-5 tw-w-5" />}
                            />
                          </Menu.Item>
                        </div>
                        <div>
                          <div className="tw-px-4 tw-pt-2 tw-font-medium tw-text-secondary">
                            {i18n.t("label.displayProperties")}
                          </div>
                          {Object.entries(displayMenuItems).map(
                            ([key, label]) => {
                              const value =
                                display[key as keyof TDisplayProperties];

                              return (
                                <Menu.Item
                                  key={key}
                                  closeOnClick={false}
                                  onClick={() =>
                                    setDisplay({ ...display, [key]: !value })
                                  }
                                >
                                  <SelectionMenuItem checked={value}>
                                    {label}
                                  </SelectionMenuItem>
                                </Menu.Item>
                              );
                            }
                          )}
                        </div>
                        <div>
                          <div className="tw-px-4 tw-pt-2 tw-font-medium tw-text-secondary">
                            {i18n.t("label.cancelledShareBlocks")}
                          </div>
                          <Menu.Item
                            closeOnClick={false}
                            onClick={() =>
                              setShowCancelledShareBlocks((prev) => !prev)
                            }
                          >
                            <SelectionMenuItem
                              checked={showCancelledShareBlocks}
                            >
                              {i18n.t("label.cancelledShareBlocks")}
                            </SelectionMenuItem>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
                <div className="max-md:tw-px-4">
                  <div>
                    {sortedBlocks.length > 0 ? (
                      <List>
                        <div
                          className={clsxm(
                            "tw-grid tw-grid-cols-3 tw-items-center tw-justify-between tw-gap-2 tw-border-b tw-px-4 tw-py-2.5 print:tw-hidden print:tw-grid-cols-3 max-md:tw-hidden lg:tw-grid-cols-4"
                          )}
                        >
                          <button
                            type="button"
                            onClick={() => {
                              setSortBy((prevSort) =>
                                prevSort === "blockNumber-asc"
                                  ? "blockNumber-desc"
                                  : "blockNumber-asc"
                              );
                            }}
                            className="tw-flex tw-basis-3/12 tw-items-center tw-gap-1 tw-text-left"
                          >
                            <p className="tw-text-sm tw-text-neutral-500">
                              {i18n.t("label.shareBlock")}
                            </p>
                            <div className="tw-flex tw-flex-col tw-gap-0">
                              <ChevronUpIcon
                                className={`${
                                  sortBy === "blockNumber-asc"
                                    ? "tw-text-neutral-800"
                                    : "tw-text-neutral-300"
                                }`}
                                weight="bold"
                                size={12}
                              />
                              <ChevronDownIcon
                                className={`${
                                  sortBy === "blockNumber-desc"
                                    ? "tw-text-neutral-800"
                                    : "tw-text-neutral-300"
                                }`}
                                weight="bold"
                                size={12}
                              />
                            </div>
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setSortBy((prevSort) =>
                                prevSort === "name-asc"
                                  ? "name-desc"
                                  : "name-asc"
                              );
                            }}
                            className="tw-flex tw-basis-3/12 tw-items-center tw-gap-1 tw-text-left"
                          >
                            <p className="tw-text-sm tw-text-neutral-500">
                              {i18n.t("label.shareholder")}
                            </p>
                            <div className="tw-flex tw-flex-col tw-gap-0">
                              <ChevronUpIcon
                                className={`${
                                  sortBy === "name-asc"
                                    ? "tw-text-neutral-800"
                                    : "tw-text-neutral-300"
                                }`}
                                weight="bold"
                                size={12}
                              />
                              <ChevronDownIcon
                                className={`${
                                  sortBy === "name-desc"
                                    ? "tw-text-neutral-800"
                                    : "tw-text-neutral-300"
                                }`}
                                weight="bold"
                                size={12}
                              />
                            </div>
                          </button>
                        </div>
                        {sortedBlocks.map((block, idx) => (
                          <div
                            key={block.start}
                            className={clsxm("md:tw-border-b", {
                              "tw-pb-16": idx === sortedBlocks.length - 1,
                              "tw-border-none": idx === sortedBlocks.length - 1,
                            })}
                          >
                            <DetailedBlockRow
                              display={display}
                              block={block}
                              history={shareBlockHistory.find(
                                (history) =>
                                  (history.holder.id === block.holder.id ||
                                    history.holder.refId ===
                                      entitiesMap[block.holder.id]?.refId) &&
                                  history.start === block.start &&
                                  history.end === block.end
                              )}
                              entitiesMap={entitiesMap}
                              shareTypesMap={shareTypesMap}
                              ledgerData={
                                ledgerQuery.data
                                  ? {
                                      ...ledgerQuery.data,
                                      votes: { total: totalVotes },
                                    }
                                  : undefined
                              }
                              open={expandedBlocks[block.start]}
                              onOpenChange={(open) => {
                                setExpandedBlocks({
                                  ...expandedBlocks,
                                  [block.start]: open,
                                });
                              }}
                              showHeader={idx === 0}
                            />
                          </div>
                        ))}
                      </List>
                    ) : (
                      <div className="tw-rounded tw-border">
                        <NoData />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {(isDesktopOrTabletDevice || activeTab === "keyData") && (
              <div className="tw-flex tw-flex-col tw-gap-6 max-md:tw-px-4 md:tw-min-w-[300px] md:tw-pl-8 md:tw-pt-6 lg:tw-min-w-[364px]">
                <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                  <DataCard
                    title={`${i18n.t("label.shares")} ${i18n
                      .t("label.total")
                      .toLowerCase()}`}
                    description={formatNumber(totalShares)}
                    descriptionSize="base"
                    theme="grayBlack"
                  />
                  <DataCard
                    title={`${i18n.t("label.votes")} ${i18n
                      .t("label.total")
                      .toLowerCase()}`}
                    description={formatNumber(totalVotes)}
                    descriptionSize="base"
                    theme="grayBlack"
                  />
                  <DataCard
                    title={i18n.t("label.shareCapital")}
                    description={`${formatCurrency(shareCapital)}`}
                    descriptionSize="base"
                    theme="grayBlack"
                  />
                  <DataCard
                    title={i18n.t("label.quotaValue")}
                    description={formatNumber(quotaValue)}
                    descriptionSize="base"
                    theme="grayBlack"
                  />
                </div>
                <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                  {shareTypesDataWithShares.map(
                    ({ name, shares }, index, array) => {
                      const isLastItem = index === array.length - 1;
                      const isOddTotal = array.length % 2 !== 0;
                      const shouldSpanFullWidth = isLastItem && isOddTotal;

                      return (
                        <DataCard
                          key={name}
                          className={clsxm("tw-cursor-pointer", {
                            "tw-col-span-2": shouldSpanFullWidth,
                          })}
                          title={`${name}`}
                          description={`${formatNumber(shares)}`}
                          theme="grayBlack"
                          icon={<ExpandIcon className="tw-h-5 tw-w-5" />}
                          onClick={() => handleCardClick(name)}
                        />
                      );
                    }
                  )}
                </div>
                <ShareBlockDialog
                  isOpen={isDialogOpen}
                  onClose={() => setDialogOpen(false)}
                  title={dialogContent.name}
                  shares={dialogContent.shares}
                  votes={dialogContent.voteValue}
                  conditions={dialogContent.condition}
                  size="md"
                />
                {isTabletOrMobileDevice && selectedShareType && (
                  <ShareTypeDrawer
                    isOpen={isDrawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    shareType={selectedShareType}
                  />
                )}
              </div>
            )}
            <BottomBar
              selectedVersion={selectedVersion}
              setSelectedVersion={setSelectedVersion}
              ledgerVersions={ledgerVersions}
              enableExcelDownload
              isDownloadLoading={isDownloadLoading}
              downloadPdf={downloadLedger}
              downloadExcel={() => {
                const data = generateShareledgerData(
                  sortedBlocks,
                  entitiesMap,
                  shareTypesMap,
                  shareBlockHistory,
                  conditionOptions,
                  i18n
                );

                makeAndDownloadExcel(data, {
                  currentCompany,
                  title: i18n.t("label.shareLedger"),
                  version: versionLabel,
                  downloaded: i18n.t("label.downloaded", {
                    date: new Date().toLocaleDateString(getLocale()),
                  }),
                });
              }}
            />
          </div>
        )}
      </PageWrapper>
    </>
  );
};

export default Shareblocks;
