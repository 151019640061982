import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import type {
  CollectAuth,
  NewSignatureRequest,
  TruidAuthDetails,
} from "../../../types/models/auth";
import {
  CollectAuthSchema,
  TruidAuthDetailsSchema,
} from "../../../types/models/auth";
import * as monitoring from "../../../utils/monitoring";
import type { IRequestError } from "../..";
import useClient, { URL } from "../client";
import type { CollectAuthResponse } from "./BankID";

const useInitAuthMutation = (
  options?: UseMutationOptions<TruidAuthDetails, IRequestError, string>
) => {
  const client = useClient();
  return useMutation(async (pubKey: string): Promise<TruidAuthDetails> => {
    const response = await client<TruidAuthDetails>(
      `${URL.API}/Auth/Truid/Init`,
      {
        body: { pubKey },
      }
    );

    const result = TruidAuthDetailsSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return response;
    }

    return result.data;
  }, options);
};

type TruidInitSignResponse = {
  redirectUrl: string;
};

const useInitSignMutation = (
  options?: UseMutationOptions<
    TruidInitSignResponse,
    IRequestError,
    NewSignatureRequest
  >
) => {
  const client = useClient({ hasAuth: true });
  return useMutation(
    async (body: NewSignatureRequest): Promise<TruidInitSignResponse> => {
      const response = await client<TruidInitSignResponse>(
        `${URL.API}/Auth/Truid/InitSignNewApprovalProposal`,
        {
          body,
        }
      );

      return response;
    },
    options
  );
};

type CollectTruidRequest = {
  code: string;
  state: string;
  pubKey: string;
};

type CollectTruidSignatureRequest = {
  code: string;
  state: string;
  orgNumber: string;
};

const useCollectAuthMutation = (
  options?: UseMutationOptions<CollectAuth, IRequestError, CollectTruidRequest>
) => {
  const client = useClient();
  return useMutation(
    async (data: CollectTruidRequest): Promise<CollectAuth> => {
      const { code, state, pubKey } = data;
      const response = await client<CollectAuthResponse>(
        `${URL.API}/Auth/Truid/Collect`,
        {
          body: {
            code,
            state,
            pubKey,
          },
        }
      );

      const result = CollectAuthSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response as unknown as CollectAuth;
      }

      if (result.data.status === "failed") {
        throw new Error("Failed to collect auth");
      }

      return result.data;
    },
    options
  );
};

type TruidCollectSignResponse = {
  signature: string;
};

const useCollectSignatureMutation = (
  options?: UseMutationOptions<
    TruidCollectSignResponse,
    IRequestError,
    CollectTruidSignatureRequest
  >
) => {
  const client = useClient({ hasAuth: true });
  return useMutation(
    async (
      body: CollectTruidSignatureRequest
    ): Promise<TruidCollectSignResponse> => {
      const response = await client<TruidCollectSignResponse>(
        `${URL.API}/Auth/Truid/Sign/Collect`,
        {
          body,
        }
      );
      return response;
    },
    options
  );
};

export default {
  useCollectAuthMutation,
  useInitAuthMutation,
  useInitSignMutation,
  useCollectSignatureMutation,
};
