import { User } from "../../types/models/users";
import type { Policy } from "../../utils/policy";
import { assertNever } from "../../utils/type";

type ApprovalRuleBoardPercentage = {
  rule: "BoardPercentage";
  percentage: number;
};

type ApprovalRuleSpecificUsers = {
  rule: "SpecificUsers";
  users: User[];
};

type ApprovalRuleNone = {
  rule: "None";
};

type ApprovalRule =
  | ApprovalRuleBoardPercentage
  | ApprovalRuleNone
  | ApprovalRuleSpecificUsers;

type ApprovalRuleBoardPercentageResponse = {
  rule: "BoardPercentage";
  percentage: string;
};

type ApprovalRuleResponse =
  | ApprovalRuleBoardPercentageResponse
  | ApprovalRuleNone
  | ApprovalRuleSpecificUsers;

/**
 * Convert a percentage to a policy
 *
 * @param percentage Percentage as float (between 0 and 1)
 */
const percentageToPolicy = (percentage: number): Policy => {
  if (percentage > 0 && percentage < 1) {
    return "Percentage";
  }

  if (percentage === 1) {
    return "All";
  }

  if (percentage === 0) {
    return "Any";
  }

  throw RangeError("Percentage must be between 0 and 1");
};

const getApprovalPolicyRule = (approvalRule: {
  rule: "BoardPercentage" | "SpecificUsers" | "None";
  percentage?: number;
}): Policy => {
  switch (approvalRule.rule) {
    case "BoardPercentage": {
      const floatPercentage = approvalRule.percentage! / 1000;
      return percentageToPolicy(floatPercentage);
    }
    case "SpecificUsers": {
      return "Manual";
    }
    case "None": {
      return "None";
    }
    default: {
      return assertNever(approvalRule.rule);
    }
  }
};

export { getApprovalPolicyRule };
export type {
  ApprovalRule,
  ApprovalRuleBoardPercentage,
  ApprovalRuleResponse,
  ApprovalRuleSpecificUsers,
};
