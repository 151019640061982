import { UseMutationResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IRequestError } from "../../api";
import bankId from "../../api/rest/auth/BankID";
import { Loading } from "../../components/design-system/Loading";
import { BankIdAuthDetails, LoggedInUser } from "../../types/models/auth";
import BankIdControls from "./BankIdControls";
import { getOpenBankIdUri, openBankIdUri } from "./utils";

const BankIdDeviceLogin = (props: {
  initMutation: UseMutationResult<
    BankIdAuthDetails,
    IRequestError,
    string,
    unknown
  >;
  onSuccess: ({ user }: { user: LoggedInUser }) => void;
  onRetry: () => void;
  onCancel: () => void;
}) => {
  const i18n = useTranslation();
  const [bankIdLocalFailed, setBankIdLocalFailed] = useState(false);
  const [enableQueries, setEnableQueries] = useState(false);

  // Init -> inProgress
  useEffect(() => {
    if (props.initMutation.isSuccess) {
      setEnableQueries(true);
      const bankIdUri = getOpenBankIdUri(
        props.initMutation.data.autoStartToken
      );
      openBankIdUri(bankIdUri, () => {
        setBankIdLocalFailed(true);
        setEnableQueries(false);
      });
    }
  }, [props.initMutation.isSuccess]);

  // Query running whilst in progress
  const collectQuery = bankId.useCollectAuthQuery(
    props.initMutation.data?.orderRef || "",
    {
      onSuccess: (data) => {
        const { status: authStatus } = data;
        if (authStatus === "complete") {
          setEnableQueries(false);
          props.onSuccess({ user: data.user });
        }
      },
      onError: () => setEnableQueries(false),
      retry: false,
      refetchInterval: 2000,
      enabled: enableQueries,
    }
  );

  const onCancel = () => {
    setEnableQueries(false);
    props.onCancel();
  };

  const mutations = [props.initMutation, collectQuery];
  const failedMutation = mutations.find((x) => x.isError);
  const isSuccess = collectQuery.data?.status === "complete";
  const status: "init" | "success" | "inProgress" | "error" =
    failedMutation || bankIdLocalFailed
      ? "error"
      : isSuccess
      ? "success"
      : props.initMutation.isSuccess
      ? "inProgress"
      : "init";
  const error =
    failedMutation &&
    (failedMutation.error.errors
      ? i18n.t(failedMutation.error.errors[0].message.code)
      : i18n.t("error.general"));

  const onInit = () => {
    setBankIdLocalFailed(false);
    props.onRetry();
  };

  if (status === "init") {
    return <Loading />;
  }

  return (
    <BankIdControls
      title={i18n.t("login.bankId")}
      status={status}
      cancel={onCancel}
      error={
        bankIdLocalFailed ? (
          <Trans
            components={{
              helpLink: (
                <Link
                  target="_blank"
                  to="https://install.bankid.com/"
                  className="tw-text-blue-500 tw-underline"
                />
              ),
            }}
            i18nKey="auth.bankId.linkFailed"
          />
        ) : (
          error
        )
      }
      retry={onInit}
    />
  );
};

export default BankIdDeviceLogin;
