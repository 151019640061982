import type { FlagIconCode } from "react-flag-kit";
import { FlagIcon } from "react-flag-kit";

import type { Entity } from "../../../types/models/entities";
import { formatRefId } from "../../../utils/format";
import { clsxm } from "../../../utils/tailwind";
import { Description } from "../Description";
import { OfficeIcon, PersonIcon } from "../icons";

const EntityItem = ({
  value,
  customDescription,
  shouldFormatRefId = true,
  hasFlag = true,
  additional,
  displayIcon = true,
  showId = true,
  flagPosition = "right",
  className,
}: {
  value: Omit<Entity, "contact">;
  customDescription?: string;
  shouldFormatRefId?: boolean;
  hasFlag?: boolean;
  additional?: JSX.Element | JSX.Element[];
  displayIcon?: boolean;
  showId?: boolean;
  flagPosition?: "left" | "right";
  className?: string;
}) => {
  const { refId, name, countryCode, type, birthDate } = value;
  const Icon = type === "Private" ? PersonIcon : OfficeIcon;

  const description =
    shouldFormatRefId && showId
      ? formatRefId({ refId, countryCode, birthDate, type })
      : showId
      ? refId
      : "";

  return (
    <div
      className={clsxm(
        "tw-flex tw-items-center tw-space-x-3 tw-text-body print:tw-items-start",
        className
      )}
    >
      {displayIcon && (
        <div>
          <Icon className="tw-h-6 tw-w-6" />
        </div>
      )}
      <div className="tw-flex tw-flex-1 tw-items-center tw-gap-2">
        <Description
          title={
            <div
              className={clsxm(
                "tw-flex tw-items-center tw-gap-2 print:tw-items-start",
                { "tw-flex-row-reverse": flagPosition === "left" }
              )}
            >
              <p className="tw-font-medium">{name}</p>
              {hasFlag && (
                <FlagIcon
                  code={countryCode as FlagIconCode}
                  size={16}
                  className="print:tw-mt-0.5"
                />
              )}
            </div>
          }
          description={customDescription || description}
        />
        <div className="tw-ml-2 tw-flex tw-flex-grow tw-flex-wrap tw-gap-2 max-md:tw-hidden">
          {additional}
        </div>
      </div>
    </div>
  );
};

export { EntityItem };
