import { formatNumber } from "../../../utils/format";
import type { BadgeProps } from "../Badge";
import { Badge } from "../Badge";

type BadgeLabelInfo = {
  label: string;
  shares: number;
};

type BadgeListProps = {
  labelsInfo: BadgeLabelInfo[];
  color?: BadgeProps["color"];
};

const ShareholderBadge = ({ labelsInfo, color = "none" }: BadgeListProps) => (
  <div className="tw-flex tw-flex-wrap tw-gap-2">
    {labelsInfo.map((info) => (
      <Badge className="tw-text-sm" key={info.label} color={color}>
        {`${info.label} ${
          labelsInfo.length > 1 ? `(${formatNumber(info.shares)})` : ""
        }`}
      </Badge>
    ))}
  </div>
);

export { ShareholderBadge };
