import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { TDraftShareType } from "../../../types/models/draft";
import type { ShareType } from "../../../types/models/shares";
import { formatNumber } from "../../../utils/format";
import { Button } from "../../design-system/Button";
import { Description } from "../../design-system/Description";
import { TrashIcon } from "../../design-system/icons";
import { List, ListItem } from "../../design-system/List";
import { ListHeader } from "../../design-system/ListHeader";
import { Loading } from "../../design-system/Loading";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { NoData } from "../../NoData";
import { AddShareType } from "../Add";
import { ClauseBadgeList } from "../ClauseBadgeList";
import { EditShareType } from "../Edit";

type ShareTypesListProps = {
  existingShareClasses?: ShareType[];
  value: TDraftShareType[];
  onChange: (value: TDraftShareType[]) => void;
  importShareClasses?: () => void;
  isLoading?: boolean;
};

const ShareTypesList = ({
  existingShareClasses,
  value,
  onChange,
  importShareClasses,
  isLoading,
}: ShareTypesListProps) => {
  const i18n = useTranslation();

  const emptyState = useMemo(() => {
    if (!importShareClasses) {
      return <NoData />;
    }

    return (
      <NoData
        description={i18n.t("label.noShareClasses.description")}
        actions={
          <>
            <TooltipV2 content={i18n.t("importShareClasses.description")}>
              <Button onClick={importShareClasses} isLoading={isLoading}>
                {i18n.t("label.import")}
              </Button>
            </TooltipV2>
            <AddShareType
              buttonVariant="outline"
              buttonColor="secondary"
              onSuccess={(newValue) => onChange([...value, newValue])}
              list={value}
            />
          </>
        }
      />
    );
  }, [i18n, importShareClasses, onChange, value, isLoading]);
  return (
    <List
      header={
        <ListHeader
          title={i18n.t("label.shareClasses")}
          actions={
            <>
              {importShareClasses && (
                <TooltipV2 content={i18n.t("importShareClasses.description")}>
                  <Button
                    className="tw-mr-2"
                    variant="solid"
                    color="primary"
                    onClick={importShareClasses}
                    isLoading={isLoading}
                  >
                    {i18n.t("label.import")}
                  </Button>
                </TooltipV2>
              )}
              <AddShareType
                onSuccess={(newValue) => onChange([...value, newValue])}
                list={value}
              />
            </>
          }
        />
      }
    >
      {value.length === 0 && !isLoading && emptyState}
      {!isLoading &&
        value.map((shareType, typeIndex) => {
          const { name, numberOfShares, voteValue, condition } = shareType;
          const doesExist = !!existingShareClasses?.find(
            (type) => type.name === name
          );

          return (
            <ListItem key={name} interactive>
              <div className="tw-grid tw-items-center tw-gap-4 lg:tw-grid-cols-4">
                <Description
                  title={name}
                  description={i18n.t("label.numberOfVotesPerShare.count", {
                    count: voteValue,
                  })}
                />
                <Description title={formatNumber(numberOfShares)} />
                <div>
                  <ClauseBadgeList condition={condition} />
                </div>
                <div className="tw-flex tw-items-center tw-justify-end tw-gap-1">
                  <EditShareType
                    list={value}
                    initialValue={shareType}
                    onSuccess={(newValue) =>
                      onChange(
                        value.map((type, index) => {
                          if (index === typeIndex) {
                            return newValue;
                          }

                          return type;
                        })
                      )
                    }
                    sharesOnly={doesExist}
                    totalShares={numberOfShares}
                  />
                  {!doesExist && (
                    <Button
                      variant="clean"
                      size="sm"
                      onClick={() =>
                        onChange(value.filter((type) => type.name !== name))
                      }
                    >
                      <TrashIcon className="tw-h-6 tw-w-6" />
                    </Button>
                  )}
                </div>
              </div>
            </ListItem>
          );
        })}

      {isLoading && <Loading />}
    </List>
  );
};

export { ShareTypesList };
