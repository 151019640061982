import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useShareholdersQuery } from "../../../../../api/blockchain/company";
import { useUsersQuery } from "../../../../../api/blockchain/users";
import { useEntitiesQuery } from "../../../../../api/rest/entities";
import { Chip } from "../../../../../components/design-system/Chip";
import { List } from "../../../../../components/design-system/List";
import { ListHeader } from "../../../../../components/design-system/ListHeader";
import { Loading } from "../../../../../components/design-system/Loading";
import { AddEntity } from "../../../../../components/Entities/AddEntity";
import { NoData } from "../../../../../components/NoData";
import { useSession } from "../../../../../context/session";
import type { CompanyInformation } from "../../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../../types/models/company";
import { hasRequiredPermission } from "../../../../../utils/permissions";
import EntitiesList from "../../../../CompanySettings/Entities/List";

type EntitiesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};
const EntitiesPage = ({ currentCompany }: EntitiesProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const shareHoldersQuery = useShareholdersQuery(currentCompany.orgNumber);
  const handleEntitiesChange = () => {
    entitiesQuery.refetch();
  };
  const isLoading =
    entitiesQuery.isLoading ||
    usersQuery.isLoading ||
    shareHoldersQuery.isLoading;
  const errorCode = entitiesQuery.error?.errors[0]?.message.code;

  type Filter =
    | "shareholders"
    | "users"
    | "representatives"
    | "trustees"
    | "inactive";
  const [display, setDisplay] = useState<Record<Filter, boolean>>({
    shareholders: true,
    users: false,
    representatives: false,
    trustees: false,
    inactive: false,
  });
  const displayMenuItems: Record<Filter, string> = {
    shareholders: i18n.t("label.shareholders"),
    users: i18n.t("label.users"),
    representatives: i18n.t("entities.filter.representatives"),
    trustees: i18n.t("entities.filter.trustees"),
    inactive: i18n.t("entities.filter.inactive"),
  };

  const entites = useMemo(() => {
    const shareholderIds = shareHoldersQuery.data.map((x) => x.id);
    const shareHolderRefIds = shareHoldersQuery.data.map(
      (x) => x.unmaskedRefId
    );
    const userIds = (usersQuery.data || []).map((x) => x.user.id);

    return (entitiesQuery.data || []).filter((e) => {
      const isShareholder =
        shareholderIds.includes(e.id) || shareHolderRefIds.includes(e.refId);
      const isUser = userIds.includes(e.id);

      return (
        (display.shareholders && isShareholder) ||
        (display.users && isUser) ||
        (display.representatives &&
          e.parents &&
          e.parents?.filter((x) => x.type === "Company").length > 0) ||
        (display.trustees &&
          e.parents &&
          e.parents?.filter((x) => x.type === "Private").length > 0) ||
        (display.inactive &&
          !isShareholder &&
          !isUser &&
          e.parents?.length === 0)
      );
    });
  }, [entitiesQuery.data, usersQuery.data, shareHoldersQuery.data, display]);

  return (
    <List
      className="max-md:tw-overflow-x-auto"
      header={
        <ListHeader
          className="tw-bg-transparent tw-px-0 tw-pb-4 tw-pt-0"
          title={<h3 className="tw-font-medium">{i18n.t("label.entities")}</h3>}
          description={i18n.t("entity.list.description2")}
          actions={
            hasRequiredPermission("Editor", currentCompany, user) && (
              <div>
                <AddEntity
                  currentCompany={currentCompany}
                  onSuccess={handleEntitiesChange}
                  customButtonProps={{
                    children: i18n.t("label.addEntity"),
                    color: "primary",
                    variant: "solid",
                  }}
                />
              </div>
            )
          }
          actionsPosition="below"
        />
      }
    >
      <div className="tw-flex tw-flex-wrap tw-gap-2 tw-px-0 tw-pb-4 max-md:tw-px-0">
        {Object.entries(displayMenuItems).map(([key, label]) => {
          const value = display[key as Filter];
          return (
            <Chip
              key={key}
              isActive={value}
              onClick={() => setDisplay({ ...display, [key]: !value })}
            >
              {label}
            </Chip>
          );
        })}
      </div>
      {isLoading && <Loading />}
      {entites.length > 0 && (
        <div className="">
          <EntitiesList
            currentCompany={currentCompany}
            value={entites}
            onChange={handleEntitiesChange}
          />
        </div>
      )}
      {errorCode && (
        <NoData
          type="error"
          title={i18n.t("error.fetch")}
          description={i18n.t(errorCode)}
        />
      )}
    </List>
  );
};

export { EntitiesPage };
