import { useState } from "react";
import { useTranslation } from "react-i18next";

import type { TDraftShareType } from "../../../types/models/draft";
import { Button } from "../../design-system/Button";
import { EditShareTypeDialog } from "../Dialog";

type EditShareTypeProps = {
  initialValue: TDraftShareType;
  list: TDraftShareType[];
  onSuccess: (data: TDraftShareType) => void;
  sharesOnly?: boolean;
  totalShares: number;
};

const EditShareType = ({
  initialValue,
  list,
  onSuccess,
  sharesOnly,
  totalShares,
}: EditShareTypeProps) => {
  const [isOpen, setOpen] = useState(false);
  const i18n = useTranslation();

  return (
    <>
      <Button variant="clean" size="md" onClick={() => setOpen(true)}>
        {!sharesOnly || totalShares > 0
          ? i18n.t("label.edit")
          : i18n.t("label.add")}
      </Button>
      {isOpen && (
        <EditShareTypeDialog
          title={i18n.t("onboarding.action.editShareClass")}
          list={list}
          onClose={() => setOpen(false)}
          onSuccess={(data) => {
            setOpen(false);
            onSuccess(data);
          }}
          defaultValues={{
            ...initialValue,
            numberOfShares:
              initialValue.numberOfShares === 0
                ? undefined
                : initialValue.numberOfShares,
          }}
          sharesOnly={sharesOnly}
        />
      )}
    </>
  );
};

export { EditShareType };
