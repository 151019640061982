import * as Sentry from "@sentry/react";
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  withSentryReactRouterV6Routing,
} from "@sentry/react";
import type { ErrorEvent, EventHint } from "@sentry/types";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ZodError } from "zod";

import { environment } from "../config";
import type { LoggedInUser } from "../types/models/auth";

const release = import.meta.env.VITE_SENTRY_RELEASE;
const sentry_dsn = import.meta.env.VITE_SENTRY_DSN;

const normalizeDepth = 5;

const replaceFirstExceptionMessage = (
  exception: ErrorEvent["exception"],
  message: string
) => {
  if (exception?.values?.[0] === undefined) {
    return exception;
  }

  const [firstException, ...remainingExceptions] = exception.values;

  const values = [
    { ...firstException, value: message },
    ...remainingExceptions,
  ];

  return { ...exception, values };
};

const enhanceZodError = (
  event: ErrorEvent,
  fieldErrors: Record<PropertyKey, string[] | undefined>
): ErrorEvent => {
  const flattenedZodErrorMessage = JSON.stringify(fieldErrors);

  /**
   * Group all ZodErrors together, by setting the fingerprint aggressively.
   *
   * @see https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting/#group-errors-more-aggressively
   */
  const fingerprint = ["ZodError", flattenedZodErrorMessage];

  // Replace Zod's error-message with something more useful.
  const exception = replaceFirstExceptionMessage(
    event.exception,
    flattenedZodErrorMessage
  );

  return { ...event, fingerprint, exception };
};

const customInit = (): void => {
  if (!sentry_dsn) {
    console.warn("Sentry disabled. No DSN");
    return;
  }

  Sentry.init({
    dsn: sentry_dsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      const { category } = breadcrumb;
      // Change the message in `ui.*`-types to display the data-testid-attribute,
      // e.g. a button whose HTML says `<button data-testid="fancy-button" />`,
      // will say `button[data-testid="fancy-button"]` in Sentry.
      if (
        typeof category === "string" &&
        category.startsWith("ui.") &&
        hint !== undefined
      ) {
        /** @type HTMLElement */
        const { target } = hint.event;
        /** @type string | undefined */
        const { testid } = target.dataset;
        /** @type string */
        const tagName = target.tagName.toLowerCase();
        const customMessage =
          typeof testid === "string" && testid.trim().length > 0
            ? `${tagName}[data-testid="${testid}"]`
            : undefined;

        return {
          ...breadcrumb,
          message: customMessage ?? breadcrumb.message,
        };
      }

      return breadcrumb;
    },
    beforeSend: (event: ErrorEvent, hint: EventHint) => {
      const exception = hint.originalException;

      if (exception instanceof ZodError) {
        return enhanceZodError(event, exception.flatten().fieldErrors);
      }

      return event;
    },
    tracesSampleRate: 1,
    // The default value of 3 cuts off too much data.
    normalizeDepth: normalizeDepth + 1,
    environment,
    release,
    replaysSessionSampleRate: environment === "production" ? 0.1 : 1,
    replaysOnErrorSampleRate: 1,
  });
};

const customSetUser = (user?: LoggedInUser): void => {
  if (user === undefined) {
    Sentry.setUser(null);

    return;
  }

  Sentry.setUser({
    id: user.id,
    username: user.name,
    segment: `${user.role}`,
    refId: user.refId,
  });
};

export type { ErrorBoundaryProps } from "@sentry/react";
export { ErrorBoundary, setTag, withScope } from "@sentry/react";
export {
  addBreadcrumb,
  captureException,
  captureMessage,
  customInit as init,
  customSetUser as setUser,
  withSentryReactRouterV6Routing,
};
