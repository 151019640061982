import { useApprovalInfoQuery } from "../api/blockchain/company";
import {
  useApprovalRuleProposalQuery,
  useUsersQuery,
} from "../api/blockchain/users";
import { useCurrentCompany } from "../context/account";
import { getProgressItems } from "../pages/companies/[companyId]/settings/policy/ApprovalPolicy.utils";
import type { ProgressItem } from "../types/models/approval";
import { ApprovalRuleProposalResponse } from "../types/models/approval-policy";
import type { Entity } from "../types/models/entities";
import type {
  ApprovalInfo,
  ApprovalInfoResponse,
} from "../types/models/shares";
import type { UserRole } from "../types/models/users";
import { captureException } from "./monitoring";
import { assertNever } from "./type";

const getApprovers = (
  approvalInfo: ApprovalInfo,
  entitiesData: Entity[],
  usersData: UserRole[],
  currentUserId: string
): ProgressItem[] => {
  try {
    return getProgressItems(
      approvalInfo,
      entitiesData,
      usersData,
      currentUserId
    );
  } catch (err) {
    captureException(err, {
      contexts: {
        arguments: { approvalInfo, entitiesData, usersData, currentUserId },
      },
    });

    return [];
  }
};

const getApprovalsCount = (
  approvalInfo: NonNullable<ApprovalInfoResponse>
): number | undefined => {
  if (approvalInfo.rule === "BoardPercentage") {
    return approvalInfo.currentNumber;
  }

  if (approvalInfo.rule === "SpecificUsers") {
    return approvalInfo.approvedBy.length;
  }

  if (approvalInfo.rule === "None") {
    return undefined;
  }

  return assertNever(approvalInfo);
};

const getRequiredTotalApprovalsCount = (
  approvalInfo: NonNullable<ApprovalInfoResponse>
): number | undefined => {
  if (approvalInfo.rule === "BoardPercentage") {
    if (approvalInfo.requiredNumber === 0) {
      // at least one approval is required in case of approval policy rule "Any board member can approve"
      return 1;
    }

    return approvalInfo.requiredNumber;
  }
  if (approvalInfo.rule === "SpecificUsers") {
    return (
      approvalInfo.approvedBy.length + approvalInfo.pendingApprovalBy.length
    );
  }

  if (approvalInfo.rule === "None") {
    return undefined;
  }

  return assertNever(approvalInfo);
};

const useHasPendingLedgerPolicyChange = (
  orgNumber?: string
): { value: boolean; isSuccess: boolean } => {
  const currentCompany = useCurrentCompany(orgNumber);

  const approvalInfoQuery = useApprovalInfoQuery(orgNumber ?? "", "");
  const approvalRuleProposalQuery = useApprovalRuleProposalQuery(
    orgNumber ?? "",
    { enabled: orgNumber !== undefined }
  );

  const isPendingApprovalPolicyApproval =
    approvalRuleProposalQuery.isSuccess &&
    approvalRuleProposalQuery.data !== null;

  const hasTheBoardSelectedAnApprovalPolicy =
    approvalInfoQuery.isSuccess &&
    approvalInfoQuery.data !== null &&
    approvalInfoQuery.data.rule !== "None";

  return {
    value:
      currentCompany?.companyStatus === "Onboarded" &&
      (!hasTheBoardSelectedAnApprovalPolicy || isPendingApprovalPolicyApproval),
    isSuccess:
      currentCompany !== undefined &&
      approvalInfoQuery.isSuccess &&
      approvalRuleProposalQuery.isSuccess,
  };
};

const useShouldSetPolicy = (
  orgNumber: string,
  pendingApprovalData: ApprovalRuleProposalResponse | undefined,
  user?: { id: string }
) => {
  const isPendingPolicyApproval = pendingApprovalData;
  const usersQuery = useUsersQuery(orgNumber);
  const { value: isSetPolicyRequired } =
    useHasPendingLedgerPolicyChange(orgNumber);
  const userRoleInCompany = (usersQuery.data || []).find(
    (u) => u.user.id === user?.id
  )?.role;
  const userCanChangeApprovalPolicy = ["BoardMember", "Administrator"].includes(
    userRoleInCompany ?? ""
  );

  return (
    !isPendingPolicyApproval &&
    isSetPolicyRequired &&
    userCanChangeApprovalPolicy
  );
};

const useShouldApprovePolicy = (
  pendingApprovalData: ApprovalRuleProposalResponse | undefined,
  user?: { id: string }
) => {
  const isPendingPolicyApprovalByUser =
    pendingApprovalData?.pendingApprovalBy.some((x) => x.id === user?.id);

  return !!isPendingPolicyApprovalByUser;
};

export {
  getApprovalsCount,
  getApprovers,
  getRequiredTotalApprovalsCount,
  useHasPendingLedgerPolicyChange,
  useShouldApprovePolicy,
  useShouldSetPolicy,
};
