function getPageName(path: string) {
  if (path.endsWith("/shares/holders")) {
    return "label.shareholders";
  }
  if (path.endsWith("/shares/blocks")) {
    return "label.shareLedger";
  }
  if (path.endsWith("/events")) {
    return "label.events";
  }
  if (path.endsWith("/company-information")) {
    return "label.companyInformation";
  }
  if (path.match(/\/settings\/.*/)) {
    return "label.settings";
  }
  if (path.match("/administration")) {
    return "label.administration";
  }
  return null;
}

const settingLinksRegEx = /\/settings\/.*/;

const normaliseName = (name?: string) => {
  if (!name) {
    return "";
  }
  if (name !== name.toUpperCase()) {
    return name;
  }
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter, and make the rest lowercase
    .join(" ");
};

export { getPageName, normaliseName, settingLinksRegEx };
