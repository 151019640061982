import { getApprovalPolicyRule } from "../../../../../api/rest/approval-rule-policy";
import type { ProgressItem } from "../../../../../types/models/approval";
import { ProgressItemSchema } from "../../../../../types/models/approval";
import type { ApprovalRuleProposal } from "../../../../../types/models/approval-policy";
import type { Entity } from "../../../../../types/models/entities";
import type { ApprovalInfo } from "../../../../../types/models/shares";
import type { UserRole } from "../../../../../types/models/users";
import { Policy } from "../../../../../utils/policy";
import { assertNever } from "../../../../../utils/type";

type ApprovalPolicyProgressProps =
  | {
      isPendingApproval: true;
      policy: Policy;
      isPendingCurrentUserApproval: boolean;
      progress: [ProgressItem, ...ProgressItem[]];
    }
  | { isPendingApproval: false };

const getProgressItems = (
  {
    approvedBy,
    pendingApprovalBy,
  }: Pick<ApprovalInfo, "approvedBy" | "pendingApprovalBy">,
  entities: Entity[],
  users: UserRole[],
  currentUserRefId: string
): [ProgressItem, ...ProgressItem[]] => {
  const approvedProgress = approvedBy.map((user) => {
    const refId = users.find((u) => u.user.id === user.id)?.user.refId;
    const entity = entities.find((e) => e.refId === refId);

    if (!entity) {
      console.warn("Couldn't map user to entity", user);
    }

    return {
      status: "approved" as const,
      key: user.id,
      name: entity?.name ?? user.id,
      isCurrentUser: currentUserRefId === user.id,
    };
  });
  const pendingProgress = pendingApprovalBy.map((user) => {
    const refId = users.find((u) => u.user.id === user.id)?.user.refId;
    const entity = entities.find((e) => e.refId === refId);

    if (!entity) {
      console.warn("Couldn't map user to entity", user);
    }

    return {
      status: "pending" as const,
      key: user.id,
      name: entity?.name ?? user.id,
      isCurrentUser: currentUserRefId === user.id,
    };
  });

  const progress: ProgressItem[] = ProgressItemSchema.array().parse([
    ...approvedProgress,
    ...pendingProgress,
  ]);

  const [firstProgressItem, ...restProgressItems] = progress;

  if (firstProgressItem === undefined) {
    throw Error("firstProgressItem is undefined");
  }

  return [firstProgressItem, ...restProgressItems];
};

const getApprovalPolicyProgressProps = (
  approvalRuleProposal: ApprovalRuleProposal | null,
  entitiesData: Entity[],
  usersData: UserRole[],
  currentUserRefId: string
): ApprovalPolicyProgressProps => {
  const isPendingApproval = approvalRuleProposal !== null;
  if (!isPendingApproval) {
    return { isPendingApproval };
  }

  const policy = getApprovalPolicyRule(approvalRuleProposal);

  switch (approvalRuleProposal.rule) {
    case "BoardPercentage":
    case "SpecificUsers": {
      return {
        isPendingApproval: true,
        isPendingCurrentUserApproval:
          approvalRuleProposal.pendingApprovalBy.some(
            (pendingApprovalBy) => pendingApprovalBy.id === currentUserRefId
          ),
        policy,
        progress: getProgressItems(
          approvalRuleProposal,
          entitiesData,
          usersData,
          currentUserRefId
        ),
      };
    }
    default: {
      return assertNever(approvalRuleProposal);
    }
  }
};

export { getApprovalPolicyProgressProps, getProgressItems };
