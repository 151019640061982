import "./index.css";

import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useOptionsProgramQuery } from "../../api/blockchain/options";
import { Button } from "../../components/design-system/Button";
import { Loading } from "../../components/design-system/Loading";
import Options from "../../components/design-system/Options/Options";
import { useCurrentCompany } from "../../context/account";
import { useSession } from "../../context/session";
import { hasRequiredPermission } from "../../utils/permissions";
import { OptionsForm } from "./OptionsForm";
import OptionsListPage from "./OptionsListPage";

type SortBy =
  | "program"
  | "options"
  | "startDate"
  | "vesting"
  | "excersizeEnd"
  | "type";
type SortOrder = "asc" | "desc";
type SortByWithOrder = `${SortBy}-${SortOrder}`;

const OptionsPage: React.FunctionComponent = () => {
  const [showForm, setShowForm] = useState(false);
  const [sortBy, setSortBy] = useState<SortByWithOrder>("program-asc");
  const currentCompany = useCurrentCompany();
  const optionsProgramQuery = useOptionsProgramQuery(currentCompany?.orgNumber);
  const optionsPrograms = optionsProgramQuery.data || [];
  const { user } = useSession();

  if (!user || !currentCompany) {
    return null;
  }

  if (optionsProgramQuery.isLoading) {
    return <Loading />;
  }

  if (showForm) {
    return (
      <OptionsForm
        currentCompany={currentCompany}
        close={() => setShowForm(false)}
      />
    );
  }

  if (optionsPrograms.length > 0) {
    return (
      <OptionsListPage
        showForm={showForm}
        setShowForm={setShowForm}
        sortBy={sortBy}
        setSortBy={setSortBy}
        currentCompany={currentCompany}
        optionsPrograms={optionsPrograms}
      />
    );
  }

  return (
    <div className="onboarding-screen tw-relative tw-flex tw-justify-center tw-overflow-hidden">
      <OptionsSplashScreen
        hasRole={hasRequiredPermission("Editor", currentCompany, user)}
        action={() => setShowForm(true)}
      />
    </div>
  );
};

const OptionsSplashScreen: React.FunctionComponent<{
  hasRole: boolean;
  action: () => void;
}> = ({ hasRole, action }) => {
  const i18n = useTranslation();

  return (
    <div className="tw-relative tw-grid tw-max-w-7xl tw-grid-cols-1 tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-py-6 max-md:tw-items-start md:tw-grid-cols-2">
      <div className="tw-mx-16 max-md:tw-ml-0">
        <h1 className="tw-pb-1">{i18n.t("options.landing.title")}</h1>
        <p className="tw-pb-10">{i18n.t("options.landing.description")}</p>
        <Button
          color="primary"
          variant="solid"
          disabled={!hasRole}
          onClick={action}
        >
          {i18n.t("options.landing.button")}
        </Button>
      </div>
      <Options />
    </div>
  );
};

export type { SortByWithOrder };
export { OptionsPage };
