import { useTranslation } from "react-i18next";

import { Entity } from "../../../types/models/entities";
import { Condition, Shareblock } from "../../../types/models/shares";
import { getFormattedDate } from "../../../utils/date";
import { formatNumber } from "../../../utils/format";
import { Description } from "../../design-system/Description";
import { InfoIcon } from "../../design-system/icons";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { useRestrictiveConditionOptions } from "../../ShareTypes/SelectRestrictiveConditions";
import {
  EntityDetails,
  EntityRow,
  Section,
  TDisplayProperties,
} from "../DetailedBlockRow";

type ShareBlockDetailsProps = {
  displayOptions: TDisplayProperties;
  shareTypeCondition?: Condition;
  entity: Entity;
  certificateIssueDate?: string;
  block: Shareblock;
  blockVotes: number;
  blockCancelled?: boolean;
  creditor?: Entity;
  trustees?: Entity[];
};

const ShareBlockDetails: React.FC<ShareBlockDetailsProps> = ({
  displayOptions,
  shareTypeCondition,
  entity,
  creditor,
  blockVotes,
  trustees,
  block,
}) => {
  const { t } = useTranslation();
  const conditionOptions = useRestrictiveConditionOptions();
  const enabledConditions = shareTypeCondition
    ? conditionOptions.filter((option) => !!shareTypeCondition[option.value])
    : [];

  return (
    <>
      {displayOptions.keyFigures && !block.cancelled && (
        <div className="tw-grid tw-grid-cols-2 tw-gap-1 print:tw-grid-cols-2 md:tw-grid-cols-3">
          <Description
            title={formatNumber(blockVotes)}
            titleWeight="medium"
            description={t("label.votes")}
          />
        </div>
      )}
      {displayOptions.restrictiveConditions &&
        shareTypeCondition !== undefined &&
        Object.values(shareTypeCondition).some(Boolean) && (
          <div>
            <Description
              theme="grayBlack"
              title={t("shares.restrictiveConditions")}
              description={enabledConditions.map((clause) => (
                <p
                  key={clause.value}
                  className="tw-flex tw-items-center tw-gap-1"
                >
                  {clause.label}{" "}
                  <div className="tw-hidden md:tw-block">
                    <TooltipV2
                      content={clause.description}
                      placement="right-end"
                    >
                      <InfoIcon />
                    </TooltipV2>
                  </div>
                </p>
              ))}
            />
          </div>
        )}
      {displayOptions.address && (
        <Description
          theme="grayBlack"
          title={t("label.ownershipInfo")}
          description={
            <EntityDetails entity={entity} display={displayOptions} />
          }
        />
      )}
      {displayOptions.since && (
        <Description
          theme="grayBlack"
          title={t("label.shareholderSince")}
          description={getFormattedDate(block.holder.since)}
        />
      )}
      {displayOptions.certificates && block.hasCertificateSince && (
        <Section header={t("shares.certificates")}>
          {t("shares.certificates.issued", {
            date: getFormattedDate(block.hasCertificateSince),
          })}
        </Section>
      )}
      {displayOptions.creditor && creditor && (
        <Section header={t("label.creditor")}>
          <EntityRow entity={creditor} display={displayOptions} />
        </Section>
      )}
      {displayOptions.trustees && trustees && trustees.length > 0 && (
        <Section header={t("label.trustees")}>
          {trustees.map((r) => (
            <EntityRow
              key={r.id}
              entity={r}
              display={{ ...displayOptions, address: false }}
            />
          ))}
        </Section>
      )}
    </>
  );
};

export { ShareBlockDetails };
