import { clsxm } from "../../../../../../utils/tailwind";

type ProgressProps = {
  totalSteps: number;
  finishedSteps: number;
  className?: string;
};

const Progress = ({
  totalSteps,
  finishedSteps,
  className = "",
}: ProgressProps) => {
  if (totalSteps === 1) {
    return <></>;
  }

  return (
    <div className={`tw-flex tw-w-full tw-flex-row tw-gap-1 ${className}`}>
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={clsxm("tw-h-1 tw-w-full tw-rounded-md tw-px-1", {
            "tw-bg-black": index < finishedSteps,
            "tw-bg-gray-400": index >= finishedSteps,
          })}
        />
      ))}
    </div>
  );
};

export { Progress };
