import { Holding } from "@capchapdev/rell-api";
import { ArrowRight } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import { useCompanyHoldings } from "../../../api/blockchain/companyHolding";
import { usePrivateHoldings } from "../../../api/blockchain/privateHolding";
import { useRepresentativeHolding } from "../../../api/blockchain/representativeHolding";
import { useRepresentativeEntityQuery } from "../../../api/rest/entities";
import { Breadcrumb } from "../../../components/design-system/Breadcrumb";
import { Description } from "../../../components/design-system/Description";
import { ListItem } from "../../../components/design-system/List";
import { Loading } from "../../../components/design-system/Loading";
import { PageWrapper } from "../../../components/PageWrapper";
import { APP_ROUTE } from "../../../routes/constants";
import { formatNumber, formatPercentage } from "../../../utils/format";

type OwnershipListProps = {
  title: string;
  holdings: Holding[];
};

const OwnershipList = ({ title, holdings }: OwnershipListProps) => {
  const i18n = useTranslation();
  const location = useLocation();

  return (
    <PageWrapper className="tw-max-w-3xl" data-testid="ownership">
      <Breadcrumb
        links={[{ url: `${APP_ROUTE.HOME}#asset`, name: i18n.t("home.name") }]}
        current={title}
      />
      <h2 className="tw-mt-4">{title}</h2>
      <div className="tw-mt-4">
        {holdings.map((h, i) => (
          <div key={h.company.name}>
            <Link to={`${location.pathname}/${h.company.orgNumber}`}>
              <ListItem
                className="tw-list-none tw-py-3 hover:tw-bg-gray-100"
                interactive
              >
                <div className="tw-flex tw-items-center tw-justify-between">
                  <Description
                    title={<h6>{h.company.name}</h6>}
                    description={
                      <h6>{`${formatNumber(
                        h.shares.total
                      )} shares (${formatPercentage(
                        parseFloat(h.shares.percentage) / 100,
                        0
                      )})`}</h6>
                    }
                  />
                  <div>
                    <div className="tw-flex tw-items-center">
                      <ArrowRight className="tw-text-neutral-900" />
                    </div>
                  </div>
                </div>
              </ListItem>
            </Link>
            {i < holdings.length - 1 && (
              <hr className="tw-border-b-1 tw-py-0" />
            )}
          </div>
        ))}
      </div>
    </PageWrapper>
  );
};

export const PersonalOwnershipList = () => {
  const i18n = useTranslation();
  const privateHoldings = usePrivateHoldings("approved");

  if (privateHoldings.isLoading) {
    return <Loading />;
  }

  return (
    <OwnershipList
      title={i18n.t("assets.details.portfolio.personal")}
      holdings={privateHoldings.data?.holdings || []}
    />
  );
};

export const CompanyOwnershipList = () => {
  const i18n = useTranslation();
  const companyHoldings = useCompanyHoldings("approved");
  const { companyId } = useParams();
  const matchingCompany = companyHoldings.data?.find(
    (i) => i.company.orgNumber === companyId
  );

  if (companyHoldings.isLoading || !matchingCompany) {
    return <Loading />;
  }

  return (
    <OwnershipList
      title={i18n.t("assets.details.portfolio", {
        name: matchingCompany.company.name,
      })}
      holdings={matchingCompany.holdings || []}
    />
  );
};

export const RepresentativeOwnershipList = () => {
  const i18n = useTranslation();
  const representativeHoldings = useRepresentativeHolding("approved");
  const { holderId } = useParams();
  const matchingHoldings =
    representativeHoldings.data &&
    representativeHoldings.data.filter((h) => h.holder.id === holderId);
  const firstHolding = matchingHoldings && matchingHoldings[0];
  const orgNumber = firstHolding && firstHolding.holding.company.orgNumber;
  const entity = useRepresentativeEntityQuery(orgNumber, holderId).data;

  if (representativeHoldings.isLoading || !entity) {
    return <Loading />;
  }

  return (
    <OwnershipList
      title={i18n.t("assets.details.portfolio", { name: entity.name })}
      holdings={matchingHoldings?.map((h) => h.holding) || []}
    />
  );
};
