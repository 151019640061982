const BANK_ID_RETURN_HASH = "bankidReturn";
const BANK_ID_REDIRECT_TIMEOUT = 10000;

const getRedirectUrl = () => {
  const isChrome = window.navigator.userAgent.includes("CriOS");
  if (isChrome) {
    return encodeURIComponent("googlechrome://");
  }

  const isFirefox = window.navigator.userAgent.includes("FxiOS");
  if (isFirefox) {
    return encodeURIComponent("firefox://");
  }

  const isOpera = window.navigator.userAgent.includes("OPT");
  if (isOpera) {
    return encodeURIComponent(
      `${window.location.origin.replace("http", "touch-http")}${
        window.location.pathname
      }`
    );
  }
  return "";
};

const getOpenBankIdUri = (autoStartToken: string) => {
  const redirectUrl = getRedirectUrl();
  return `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirectUrl}`;
};

const openBankIdUri = (url: string, onError: () => void) => {
  const timeout = setTimeout(() => {
    // This function will execute if the bankid:// handler fails to open
    console.error("Failed to launch BankID");
    onError();
  }, BANK_ID_REDIRECT_TIMEOUT);

  // Attempt to open the BankID app
  window.location.href = url;

  // Clear the timeout if the user navigates away (i.e. the handler was successful)
  window.addEventListener("blur", () => {
    clearTimeout(timeout);
  });
};

export { BANK_ID_RETURN_HASH, getOpenBankIdUri, openBankIdUri };
