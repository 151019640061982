import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Truid from "../../api/rest/auth/Truid";
import { Alert } from "../../components/design-system/Alert";
import { Button } from "../../components/design-system/Button";
import { Loading } from "../../components/design-system/Loading";
import { useCurrentCompany } from "../../context/account";
import { SignMethodProps } from "..";

const TruidSign = (props: SignMethodProps) => {
  const i18n = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const initSignMutation = Truid.useInitSignMutation();
  const collectSignatureMutation = Truid.useCollectSignatureMutation();
  const currentCompany = useCurrentCompany();

  const truidPendingSignatureData =
    !!searchParams.get("truidCode") && !!searchParams.get("truidState")
      ? {
          code: searchParams.get("truidCode")!,
          state: searchParams.get("truidState")!,
        }
      : undefined;

  if (
    props.request &&
    !truidPendingSignatureData &&
    initSignMutation.isIdle &&
    !initSignMutation.data &&
    !initSignMutation.error
  ) {
    initSignMutation
      .mutateAsync(props.request)
      .then(({ redirectUrl }) => {
        window.location.href = redirectUrl;
        return redirectUrl;
      })
      .catch(() => {});
  }

  const onCancel = () => {
    // Keep the current path but clear search parameters
    navigate({
      pathname: window.location.pathname,
      search: "",
    });
    props.onCancel();
  };

  if (
    currentCompany?.orgNumber &&
    truidPendingSignatureData &&
    collectSignatureMutation.isIdle &&
    !collectSignatureMutation.data &&
    !collectSignatureMutation.isError
  ) {
    collectSignatureMutation
      .mutateAsync({
        code: truidPendingSignatureData.code,
        state: truidPendingSignatureData.state,
        orgNumber: currentCompany?.orgNumber,
      })
      .then(({ signature }) => {
        props.onSuccess({ signature });
        return signature;
      })
      .catch((error) => {
        onCancel();
        throw error;
      });
  }

  const isLoading =
    initSignMutation.isLoading || collectSignatureMutation.isLoading;
  const isError = initSignMutation.isError || collectSignatureMutation.isError;
  const error =
    isError && (initSignMutation.error ?? collectSignatureMutation.error);
  if (error) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <Alert className="tw-my-2" type="error">
          {i18n.t(error.errors[0].message.code)}
        </Alert>
        <Button
          className="tw-my-2"
          onClick={() => {
            onCancel();
          }}
        >
          {i18n.t("label.retry")}
        </Button>
      </div>
    );
  }
  return (
    <>
      <h3 className="tw-text-center">
        {i18n.t("approval-policy.signWithTruid.title")}
      </h3>
      <p className="tw-my-2 tw-text-center">
        {i18n.t("approval-policy.signWithTruid.text")}
      </p>
      {isLoading && <Loading />}
    </>
  );
};

export { TruidSign };
