import * as monitoring from "../utils/monitoring";

const DEFAULT_LOCALE = "en-US" satisfies Locale;

const locales = { "en-US": "English", "sv-SE": "Svenska" };
const localesShort = { "en-US": "EN", "sv-SE": "SV" };

type Locale = keyof typeof locales;

const localeStorageKey = "locale";
const savedLocale: Locale = window.localStorage.getItem(
  localeStorageKey
) as Locale;

const splitLocale = (locale: string): string | undefined =>
  locale.toLowerCase().replace("_", "-").split("-").at(-1);

type LocaleKey = Locale extends `${infer Language}-${infer Territory}`
  ? Lowercase<Language | Territory>
  : false;

const localeMap: Record<LocaleKey, Locale> = {
  en: "en-US",
  us: "en-US",
  se: "sv-SE",
  sv: "sv-SE",
};

const isLocaleMapKey = (maybeLocaleKey = ""): maybeLocaleKey is LocaleKey =>
  maybeLocaleKey in localeMap;

const mapLocale = (locale: string): Locale => {
  const key = splitLocale(locale);

  return isLocaleMapKey(key) ? localeMap[key] : DEFAULT_LOCALE;
};

const setLocale = (locale: Locale) => {
  monitoring.setTag(localeStorageKey, locale);
  window.localStorage.setItem(localeStorageKey, locale);
};

const localeFromBrowser = () => {
  const browserLanguage =
    navigator.languages && navigator.languages.length > 0
      ? navigator.languages[0]
      : navigator.language;

  const locale = mapLocale(browserLanguage ?? DEFAULT_LOCALE);
  setLocale(locale);

  return locale;
};

const getLocale = (): Locale =>
  savedLocale ? mapLocale(savedLocale) : localeFromBrowser();

export type { Locale };
export { DEFAULT_LOCALE, getLocale, locales, localesShort, setLocale };
