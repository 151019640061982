import type {
  CollectAuthUser,
  GenerateQrCodeResponse,
} from "@capchapdev/auth-api";
import type {
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useSession } from "../../../context/session";
import type {
  BankIdAuthDetails,
  CollectAuth,
  CollectSign,
  NewSignatureRequest,
  QRCodeResponse,
} from "../../../types/models/auth";
import {
  BankIdAuthDetailsSchema,
  CollectAuthSchema,
  CollectSignSchema,
  QRCodeResponseSchema,
} from "../../../types/models/auth";
import * as monitoring from "../../../utils/monitoring";
import type { IRequestError } from "../..";
import useClient, { NoContentSchema, URL } from "../client";

type CollectAuthResponse = {
  status: string;
  hintCode?: string | null;
  user?: CollectAuthUser;
  signature?: string | null;
};

const useInitAuthMutation = (
  options?: UseMutationOptions<BankIdAuthDetails, IRequestError, string>
) => {
  const client = useClient();
  return useMutation(async (pubKey: string): Promise<BankIdAuthDetails> => {
    const response = await client<BankIdAuthDetails>(`${URL.API}/Auth/Init`, {
      body: { pubKey },
    });

    const result = BankIdAuthDetailsSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return response;
    }

    return result.data;
  }, options);
};

const useQRCodeQuery = (
  startToken: string,
  options?: UseQueryOptions<QRCodeResponse, IRequestError>
) => {
  const client = useClient();
  return useQuery<QRCodeResponse, IRequestError>(
    ["qrCode"],
    async (): Promise<QRCodeResponse> => {
      const response = await client<GenerateQrCodeResponse>(
        `${URL.API}/Auth/GenerateQrCode`,
        { body: { startToken } }
      );

      const result = QRCodeResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response as QRCodeResponse;
      }

      return result.data;
    },
    options
  );
};

const useCancelMutation = () => {
  const client = useClient();
  return useMutation(async (orderRef: string) => {
    const response = await client<undefined>(`${URL.API}/Auth/Cancel`, {
      body: { orderRef },
    });

    const result = NoContentSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return response;
    }

    return result.data;
  });
};

const useCollectAuthQuery = (
  orderRef: string,
  options?: UseQueryOptions<CollectAuth, IRequestError>
) => {
  const client = useClient({ hasAuth: true });
  return useQuery<CollectAuth, IRequestError>(
    ["collectAuth", orderRef],
    async (): Promise<CollectAuth> => {
      const response = await client<CollectAuthResponse>(
        `${URL.API}/Auth/Collect`,
        { body: { orderRef } }
      );

      const result = CollectAuthSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response as CollectAuth;
      }

      if (result.data.status === "failed") {
        throw new Error("Failed to collect auth");
      }

      return result.data;
    },
    options
  );
};

const useCollectSignQuery = (
  orderRef: string,
  options?: UseQueryOptions<CollectSign, IRequestError>
) => {
  const client = useClient({ hasAuth: true });
  return useQuery<CollectSign, IRequestError>(
    ["collectSign", orderRef],
    async (): Promise<CollectSign> => {
      const response = await client<CollectSign>(
        `${URL.API}/Auth/Sign/Collect`,
        { body: { orderRef }, method: "POST" }
      );

      const result = CollectSignSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response;
      }

      if (result.data.status === "failed") {
        throw new Error("Failed to collect sign");
      }

      return result.data;
    },
    options
  );
};

const useInitSignMutation = (
  options?: UseMutationOptions<
    BankIdAuthDetails,
    IRequestError,
    NewSignatureRequest
  >
) => {
  const client = useClient({ hasAuth: true });
  const { user } = useSession();

  return useMutation(
    ["initSign", "BankID", user?.refId],
    async (body: NewSignatureRequest): Promise<BankIdAuthDetails> => {
      const response = await client<BankIdAuthDetails>(
        `${URL.API}/Auth/InitSignNewApprovalProposal`,
        {
          body,
        }
      );

      const result = BankIdAuthDetailsSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response;
      }

      return result.data;
    },
    options
  );
};

export default {
  useCancelMutation,
  useCollectAuthQuery,
  useCollectSignQuery,
  useInitAuthMutation,
  useInitSignMutation,
  useQRCodeQuery,
};

export type { CollectAuthResponse };
