import { Trans, useTranslation } from "react-i18next";

import { useCompanyUtils } from "../../../../context/account";
import type { EntitiesMap } from "../../../../types/models/entities";
import type { IncreaseCapitalEvent } from "../../../../types/models/events";
import { formatNumber } from "../../../../utils/format";
import { Description } from "../../../design-system/Description";
import { IncreaseCapitalIcon } from "../../../design-system/icons";
import AuditLine from "./AuditLine";

export const IncreaseCapitalAuditLine = (props: {
  event: IncreaseCapitalEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const { formatCurrency } = useCompanyUtils();
  const createdBy = props.entitiesMap[props.event.user.id];

  return (
    <AuditLine
      title={i18n.t("events.increaseCapital.title")}
      icon={<IncreaseCapitalIcon />}
      description={
        <Trans
          i18nKey="events.increaseCapital.content"
          values={{
            capital: formatCurrency(props.event.shares.capital),
            quotaBefore: formatCurrency(props.event.shares.quotaValue.before),
            quotaAfter: formatCurrency(props.event.shares.quotaValue.after),
          }}
        />
      }
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.createdBy")}
              description={createdBy!.name}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.capital")}
              description={formatNumber(parseFloat(props.event.shares.capital))}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.quota.before")}
              description={formatCurrency(props.event.shares.quotaValue.before)}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.quota.after")}
              description={formatCurrency(props.event.shares.quotaValue.after)}
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
