import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useCompaniesQuery } from "../../api/blockchain/company";
import { useRegisterSwedishCompanyWithContactDetailsMutation } from "../../api/rest/administration";
import { useMyUnregisteredCompaniesQuery } from "../../api/rest/person";
import type { BadgeProps } from "../../components/design-system/Badge";
import { Badge } from "../../components/design-system/Badge";
import { Button } from "../../components/design-system/Button";
import { Loading } from "../../components/design-system/Loading";
import { PageWrapper } from "../../components/PageWrapper";
import { useSession } from "../../context/session";
import { APP_ROUTE } from "../../routes/constants";
import type { CompanyInvolvement } from "../../types/models/company";
import { roleLabels } from "../../types/models/users";
import { sortByName } from "../../utils/sort";
import { HomePageCard } from "..";
import { SucessfullyOnboardedDialog } from "../Administration/AddCompany/SuccessfullyOnboardedDIalog";
import { Welcome } from "../Administration/AddCompany/Welcome";

const EmptyShareLedger = () => {
  const i18n = useTranslation();
  const session = useSession();

  return (
    <PageWrapper className="tw-max-w-xl" data-testid="ledgers-empty">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-p-6 tw-text-center">
        <h3>{i18n.t("shareLedgers.emptyState.title")}</h3>
        <p className="tw-text-sm">
          {session.loginType === "Truid"
            ? i18n.t("shareLedgers.emptyState.Truid.firstParagraph")
            : i18n.t("shareLedgers.emptyState.firstParagraph")}
        </p>
        <p className="tw-text-sm">
          {i18n.t("shareLedgers.emptyState.secondParagraph")}
        </p>
        <a
          href="https://kvanta.com/contact"
          rel="noreferrer noopener"
          target="_blank"
        >
          <Button variant="solid" className="tw-bg-neutral-900">
            {i18n.t("signUp.contactUs")}
          </Button>
        </a>
      </div>
    </PageWrapper>
  );
};

const getBadgeVariant = (company: CompanyInvolvement) => {
  if (
    company.companyStatus === "Onboarding" ||
    company.ledgerStatus === "NoEvents"
  ) {
    return "noEvents";
  }
  if (company.companyStatus === "Liquidated") {
    return "liquidated";
  }
  if (company.companyStatus === "UnderLiquidation") {
    return "underLiquidation";
  }
  if (company.ledgerStatus === "NoPolicy") {
    return "boardApprovalRequired";
  }
  if (company.ledgerStatus === "Draft" || company.ledgerStatus === "Pending") {
    return "unapproved";
  }
  if (company.ledgerStatus === "Approved") {
    return "approved";
  }
  throw new Error(`Badge variant for ${company.orgNumber} cannot be found`);
};

const CompanyBadge = ({ company }: { company: CompanyInvolvement }) => {
  const i18n = useTranslation();
  const badgeVariants: Record<
    | "approved"
    | "noEvents"
    | "unapproved"
    | "underLiquidation"
    | "liquidated"
    | "boardApprovalRequired",
    BadgeProps
  > = {
    noEvents: {
      children: i18n.t("badge.startRegistration"),
      color: "none",
    },
    boardApprovalRequired: {
      children: i18n.t("badge.boardApprovalRequired"),
      color: "blue",
    },
    unapproved: { children: i18n.t("badge.pendingApproval"), color: "warning" },
    approved: { children: i18n.t("label.approved"), color: "success" },
    underLiquidation: {
      children: i18n.t("label.underLiquidation"),
      color: "warning",
    },
    liquidated: { children: i18n.t("label.liquidated"), color: "none" },
  };
  const badgeProps = badgeVariants[getBadgeVariant(company)];
  return <Badge {...badgeProps} />;
};

const UnregisteredCompanies = () => {
  const i18n = useTranslation();
  const companies = useCompaniesQuery();
  const unregisteredCompaniesQuery = useMyUnregisteredCompaniesQuery();
  const unregisteredCompanies =
    unregisteredCompaniesQuery.data?.unregisteredCompanies ?? [];

  const mutationOptions = {
    onSuccess: () => {
      companies?.refetch();
      setOpen(undefined);
      setShowSuccessPopup(true);
    },
  };
  const addSwedishCompanyEntityWithContactDetailsMutation =
    useRegisterSwedishCompanyWithContactDetailsMutation(mutationOptions);

  const [currentlyOpenCompanyId, setOpen] = useState<string | undefined>();
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);

  return (
    <>
      {unregisteredCompanies.map((company) => (
        <button
          key={company.id}
          onClick={() => setOpen(company.id)}
          type="button"
        >
          <HomePageCard
            title={company.name}
            description={i18n.t("role.boardMember")}
            footer={<Badge color="none">{i18n.t("company.welcome.cta")}</Badge>}
            arrowLabel={i18n.t("company.welcome.action")}
          />
          {currentlyOpenCompanyId === company.id && (
            <Welcome
              companyName={company.name}
              isLoading={
                addSwedishCompanyEntityWithContactDetailsMutation.isLoading
              }
              orgNumber={company.id}
              onClose={() => {
                addSwedishCompanyEntityWithContactDetailsMutation.reset();
                setOpen(undefined);
              }}
              onSubmit={(data) => {
                addSwedishCompanyEntityWithContactDetailsMutation.mutate(data);
              }}
            />
          )}
        </button>
      ))}
      {showSuccessPopup && (
        <SucessfullyOnboardedDialog
          onClose={() => setShowSuccessPopup(false)}
        />
      )}
    </>
  );
};

const Ledgers = () => {
  const i18n = useTranslation();
  const companies = useCompaniesQuery();
  const unregisteredCompaniesQuery = useMyUnregisteredCompaniesQuery();
  const unregisteredCompanies =
    unregisteredCompaniesQuery.data?.unregisteredCompanies ?? [];
  const sortedCompanyInvolvements = useMemo(
    () => sortByName(companies.data ?? []),
    [companies.data]
  );

  if (
    companies.isLoading ||
    (!companies.data && unregisteredCompaniesQuery.isLoading)
  ) {
    return <Loading />;
  }

  if (
    sortedCompanyInvolvements.length === 0 &&
    unregisteredCompanies.length === 0
  ) {
    return <EmptyShareLedger />;
  }

  return (
    <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
      {sortedCompanyInvolvements.map((company) => (
        <Link
          to={
            company.companyStatus === "Onboarding"
              ? `${APP_ROUTE.COMPANIES}/${company.orgNumber}/onboarding`
              : `${APP_ROUTE.COMPANIES}/${company.orgNumber}/shares/holders`
          }
          key={company.orgNumber}
        >
          <HomePageCard
            title={company.name}
            description={
              roleLabels[company.access] && i18n.t(roleLabels[company.access])
            }
            footer={
              company.access !== "Viewer" ? (
                <CompanyBadge company={company} />
              ) : undefined
            }
          />
        </Link>
      ))}
      <UnregisteredCompanies />
    </div>
  );
};

export { Ledgers };
