import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Truid from "../../api/rest/auth/Truid";
import { Button } from "../../components/design-system/Button";
import { notify } from "../../components/design-system/Notifications";
import { PublicPageLayout } from "../../components/PublicPageLayout";
import { useSession } from "../../context/session";
import { APP_ROUTE } from "../../routes/constants";
import { getKeyPairFromStorage } from "../utils";

const TruidCompleteLoginPage = () => {
  const [searchParams] = useSearchParams();
  const { handleSignedIn, user } = useSession();

  const [waitForResult, setWaitForResult] = useState(false);
  const navigate = useNavigate();
  const i18n = useTranslation();
  const isInIframe = window.self !== window.top && window.frameElement;

  const collectTruidAuthMutation = Truid.useCollectAuthMutation({
    onSuccess: (data) => {
      const { status } = data;
      if (status === "success") {
        handleSignedIn({ user: data.user }, "Truid");
      }
    },
    onError: () => notify(i18n.t("error.general"), { type: "error" }),
  });

  const onCollectTruidAuth = (code: string, state: string) => {
    const pubKey =
      getKeyPairFromStorage("pendingKeyPair")?.pubKey.toString("hex") ?? "";
    return collectTruidAuthMutation.mutate({
      code,
      state,
      pubKey,
    });
  };

  useEffect(() => {
    if (!searchParams || waitForResult) {
      return;
    }

    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const error = searchParams.get("error");
    const response = code ?? error;
    const hasWrongParams = !state || !response;

    if (hasWrongParams) {
      console.error(
        "Wrong search params in Truid response!",
        window.location.href
      );
      return;
    }

    if (isInIframe) {
      window.top?.postMessage(
        {
          code,
          state,
          error,
          type: "TruidResponse",
        },
        window.location.origin
      );
      setWaitForResult(true);
    } else if (collectTruidAuthMutation?.isIdle && !waitForResult) {
      onCollectTruidAuth(response, state);
      setWaitForResult(true);
    }
  }, [searchParams, waitForResult, isInIframe]);

  useEffect(() => {
    if (user && !isInIframe) {
      navigate(APP_ROUTE.HOME);
    }
  }, [user, navigate, isInIframe]);

  if (!isInIframe && collectTruidAuthMutation?.isError) {
    return (
      <PublicPageLayout>
        <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-space-y-8">
          {i18n.t("auth.Truid.loginFailed")}

          <Button
            onClick={() => {
              collectTruidAuthMutation?.reset();
              navigate(APP_ROUTE.SIGN_IN);
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("auth.backToLogin")}
          </Button>
        </div>
      </PublicPageLayout>
    );
  }

  return null;
};

export { TruidCompleteLoginPage };
