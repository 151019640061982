import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import type React from "react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { Progress } from "../../../pages/companies/[companyId]/settings/policy/components/Progress";
import { clsxm } from "../../../utils/tailwind";
import { Button } from "../Button";
import { LogoIcon } from "../icons";

type Dialog2Props = {
  isOpen?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  headerChildren?: React.ReactNode;
  actions?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  stackedActions?: boolean;
  showCancel?: boolean;
};

const Dialog2 = ({
  isOpen = true,
  onClose = () => {},
  children,
  headerChildren,
  actions,
  isLoading,
  className = "",
  stackedActions = true,
  showCancel = true,
}: Dialog2Props) => {
  const { t } = useTranslation();
  const [isExiting, setIsExiting] = useState(false);

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      onClose();
    }, 200);
  };

  return (
    <Transition appear show={isOpen && !isExiting} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-50"
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-150"
          enterFrom="tw-opacity-0 tw-translate-y-full"
          enterTo="tw-opacity-100 tw-translate-y-0"
          leave="tw-ease-in tw-duration-150"
          leaveFrom="tw-opacity-100 tw-translate-y-0"
          leaveTo="tw-opacity-0 tw-translate-y-full"
        >
          <HeadlessDialog.Panel
            className={`tw-flex tw-h-full tw-w-full tw-transform tw-flex-col tw-overflow-hidden tw-bg-white tw-align-middle tw-shadow-lg tw-transition-all ${className}`}
          >
            <HeadlessDialog.Title
              as="div"
              className="tw-flex tw-h-20 tw-items-center tw-justify-between tw-px-4 tw-pt-6 md:tw-px-8"
            >
              <LogoIcon
                className={`tw-w-36 ${
                  headerChildren ? "tw-hidden md:tw-block" : ""
                }`}
              />
              <div className="tw-flex tw-grow tw-justify-center">
                {headerChildren}
              </div>

              <div className="tw-flex tw-w-36 tw-justify-end">
                {showCancel && (
                  <Button
                    onClick={handleClose}
                    variant="clean"
                    disabled={isLoading}
                    className="md:tw-block"
                  >
                    {t("label.finish")}
                  </Button>
                )}
              </div>
            </HeadlessDialog.Title>
            <div className="tw-h-[calc(100vh-6rem)] tw-overflow-auto">
              {children}
            </div>
            {actions && (
              <div
                className={clsxm(
                  "tw-flex tw-items-center tw-justify-end tw-gap-2 tw-border-t tw-px-4 tw-py-4 md:tw-flex-row md:tw-px-12",
                  {
                    "tw-flex-col": stackedActions,
                    "tw-flex-row": !stackedActions,
                  }
                )}
              >
                {actions}
              </div>
            )}
          </HeadlessDialog.Panel>
        </Transition.Child>
      </HeadlessDialog>
    </Transition>
  );
};

type ProgressDialogProps = {
  children: React.ReactNode;
  totalSteps: number;
  currentStep: number;
  progressClassName?: string;
  actions: React.ReactNode;
  isLoading: boolean;
  onClose: () => void;
};

const ProgressDialog = ({
  children,
  totalSteps,
  currentStep,
  progressClassName,
  actions,
  isLoading,
  onClose,
}: ProgressDialogProps) => (
  <Dialog2
    isOpen
    onClose={() => onClose()}
    stackedActions={false}
    headerChildren={
      totalSteps > 0 && (
        <Progress
          className={clsxm(
            "tw-max-w-xl tw-items-center md:tw-px-4",
            progressClassName
          )}
          totalSteps={totalSteps}
          finishedSteps={currentStep}
        />
      )
    }
    actions={actions}
    isLoading={isLoading}
    showCancel={currentStep < totalSteps || totalSteps === 0}
  >
    <div>{children}</div>
  </Dialog2>
);

export { ProgressDialog };
