import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useApprovalInfoQuery } from "../../../api/blockchain/company";
import { useParentEventsQuery } from "../../../api/blockchain/events";
import { useApprovalRuleProposalQuery } from "../../../api/blockchain/users";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { Banner } from "../../../components/design-system/Banner";
import { isSSN } from "../../../components/Entities/CreateEntityForm/CreateEntityForm";
import { getVersionToApprove } from "../../../components/History/History.utils";
import { useSession } from "../../../context/session";
import { useCompanyEvents } from "../../../hooks/useCompanyEvents";
import { useToggles } from "../../../hooks/useToggles";
import { APP_ROUTE } from "../../../routes/constants";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import {
  useShouldApprovePolicy,
  useShouldSetPolicy,
} from "../../../utils/approvalUtils";
import { hasRequiredPermission } from "../../../utils/permissions";
import { useCompanyDataComparison } from "../../CompanyShares/EventsValidation.utils";

type ActionBannerProps = {
  company: CompanyInvolvement | CompanyInformation;
};

const ActionBanner: FunctionComponent<ActionBannerProps> = ({ company }) => {
  const { isFeatureEnabled } = useToggles();
  const location = useLocation();
  const i18n = useTranslation();
  const { user } = useSession();

  const { pendingEvents, pendingRollbackEvents } = useCompanyEvents(
    company.orgNumber
  );
  const eventsQuery = useParentEventsQuery({
    orgNumber: company.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = useMemo(
    () => (eventsQuery.isSuccess ? eventsQuery.data.data : []),
    [eventsQuery]
  );

  const versionToApprove = useMemo(() => getVersionToApprove(events), [events]);
  const versionToApproveApprovalInfo = useApprovalInfoQuery(
    company.orgNumber,
    versionToApprove?.value
  );
  const isLedgerPendingApprovalByUser =
    user !== undefined &&
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    versionToApproveApprovalInfo.data.rule !== "None" &&
    versionToApproveApprovalInfo.data.status === "Pending" &&
    versionToApproveApprovalInfo.data.pendingApprovalBy.some(
      ({ id }) => id === user.id
    );
  const pendingApprovalRuleProposalQuery = useApprovalRuleProposalQuery(
    company.orgNumber
  );
  const pendingApprovalData = pendingApprovalRuleProposalQuery.data;
  const shouldSetPolicy = useShouldSetPolicy(
    company.orgNumber,
    pendingApprovalData,
    user
  );
  const shouldApprovePolicy = useShouldApprovePolicy(pendingApprovalData, user);

  const entitiesQuery = useEntitiesQuery(company.orgNumber);
  const entitiesMissingPassport = (entitiesQuery.data || []).filter(
    (entity) =>
      entity.type === "Private" &&
      !isSSN(entity.refId) &&
      !entity.passportNumber
  );
  const isEditor = hasRequiredPermission("Editor", company, user);

  const companyDataMismatch = useCompanyDataComparison(company.orgNumber);

  const notifications = [
    {
      check: shouldSetPolicy || shouldApprovePolicy,
      message: i18n.t(
        shouldApprovePolicy
          ? "approvalNotification.boardMember"
          : "approvalNotification.administrator"
      ),
      link: `${APP_ROUTE.COMPANIES}/${company.orgNumber}/settings`,
      linkText: i18n.t("approvalNotification.link"),
    },
    {
      check:
        isLedgerPendingApprovalByUser &&
        (pendingEvents.length > 0 || pendingRollbackEvents.length > 0),
      message: i18n.t(
        pendingEvents.length > 0
          ? "pendingEventsNotification.count"
          : "pendingRollbackEventsNotification.count",
        {
          count: pendingEvents.length + pendingRollbackEvents.length,
        }
      ),
      link: `${APP_ROUTE.COMPANIES}/${company.orgNumber}/events`,
      linkText: i18n.t("eventsNotification.approve"),
    },
    {
      check: isEditor && entitiesMissingPassport.length > 0,
      message: i18n.t("passportRequiredNotification.count", {
        count: entitiesMissingPassport.length,
      }),
      link: `${APP_ROUTE.COMPANIES}/${company.orgNumber}/settings/entities`,
      linkText: i18n.t("label.continue"),
    },
    {
      check:
        isFeatureEnabled("ENABLE_CROSS_VALIDATION") &&
        isEditor &&
        companyDataMismatch?.some(
          (x) => x.ledger === null || x.companyData === null
        ),
      message: i18n.t("dataMismatchNotification"),
      link: `${APP_ROUTE.COMPANIES}/${company.orgNumber}/events/validate`,
      linkText: "View",
    },
  ];

  const activeNotifications = notifications.filter(
    (n) => n.check && location.pathname !== n.link
  );

  return (
    <>
      {activeNotifications.map((n) => (
        <Banner
          key={n.link}
          link={n.link}
          message={n.message}
          linkText={n.linkText}
        />
      ))}
    </>
  );
};

export default ActionBanner;
