import { z } from "zod";

const BankIdAuthDetailsSchema = z.object({
  orderRef: z.string(),
  qrStartToken: z.string(),
  autoStartToken: z.string(),
});

const TruidAuthDetailsSchema = z.object({
  state: z.string(),
  authorizationUrl: z.string(),
});

type BankIdAuthDetails = z.infer<typeof BankIdAuthDetailsSchema>;
type TruidAuthDetails = z.infer<typeof TruidAuthDetailsSchema>;

const newApprovalProposalSignRequestSchema = z.object({
  language: z.enum(["sv", "en"]),
  confirmAsOfficial: z.boolean(),
  percentage: z.number().min(0).max(100).optional(),
  manualApprovers: z.array(z.string()).optional(),
});

type NewSignatureRequest = z.infer<typeof newApprovalProposalSignRequestSchema>;

const QRCodeResponseSchema = z.object({
  qrCode: z.string(),
});

type QRCodeResponse = z.infer<typeof QRCodeResponseSchema>;

const LoggedInUserSchema = z.object({
  name: z.string(),
  id: z.string(),
  refId: z.string(),
  role: z.enum(["Administrator"]).nullable(),
});

type LoggedInUser = z.infer<typeof LoggedInUserSchema>;

// BankID error messages: https://www.bankid.com/en/utvecklare/guider/teknisk-integrationsguide/meddelanden
const BankIdStatusSchema = z.enum(["failed", "pending", "complete"]);
const TruidStatusSchema = z.enum(["success"]);

const CollectAuthFailedSchema = z.object({
  status: z.literal(BankIdStatusSchema.Values.failed),
  hintCode: z.enum([
    "certificateErr",
    "expiredTransaction",
    "startFailed",
    "userCancel",
  ]),
  user: z.null(),
});

const CollectAuthPendingSchema = z.object({
  status: z.literal(BankIdStatusSchema.Values.pending),
  hintCode: z.enum([
    "noClient",
    "outstandingTransaction",
    "started",
    "userSign",
  ]),
  user: z.null(),
});

const CollectAuthCompleteSchema = z.object({
  status: z.literal(BankIdStatusSchema.Values.complete),
  user: LoggedInUserSchema,
});

const CollectTruidAuthCompleteSchema = z.object({
  status: z.literal(TruidStatusSchema.Values.success),
  user: LoggedInUserSchema,
});

const CollectAuthSchema = z.discriminatedUnion("status", [
  CollectAuthFailedSchema,
  CollectAuthPendingSchema,
  CollectAuthCompleteSchema,
  CollectTruidAuthCompleteSchema,
]);

type CollectAuth = z.infer<typeof CollectAuthSchema>;

const CollectSignCompleteUserSchema = z.object({
  id: z.null(),
  name: z.string(),
  refId: z.string(),
  role: z.null(),
});

const CollectSignFailedSchema = z.object({
  status: z.literal(BankIdStatusSchema.Values.failed),
  signature: z.null(),
  hintCode: z.enum([
    "certificateErr",
    "expiredTransaction",
    "startFailed",
    "userCancel",
  ]),
  user: z.null(),
});
const CollectSignPendingSchema = z.object({
  status: z.literal(BankIdStatusSchema.Values.pending),
  hintCode: z.enum([
    "noClient",
    "outstandingTransaction",
    "started",
    "userSign",
  ]),
  signature: z.null(),
  user: z.null(),
});
const CollectSignCompleteSchema = z.object({
  status: z.literal(BankIdStatusSchema.Values.complete),
  hintCode: z.null(),
  signature: z.string(),
  user: CollectSignCompleteUserSchema,
});

const CollectSignSchema = z.discriminatedUnion("status", [
  CollectSignFailedSchema,
  CollectSignPendingSchema,
  CollectSignCompleteSchema,
]);

type CollectSign = z.infer<typeof CollectSignSchema>;

const TestAuthMockSchema = CollectAuthSchema;

type TestAuthMock = z.infer<typeof TestAuthMockSchema>;

export type {
  BankIdAuthDetails,
  CollectAuth,
  CollectSign,
  LoggedInUser,
  NewSignatureRequest,
  QRCodeResponse,
  TestAuthMock,
  TruidAuthDetails,
};
export {
  BankIdAuthDetailsSchema,
  CollectAuthSchema,
  CollectSignSchema,
  LoggedInUserSchema,
  newApprovalProposalSignRequestSchema,
  QRCodeResponseSchema,
  TestAuthMockSchema,
  TruidAuthDetailsSchema,
};
