import { Globe } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { setLocale } from "../../i18n";
import { localesShort } from "../../i18n/locale";
import { LogoIcon } from "../design-system/icons";

const PublicPageLayout = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const newLocale = i18n.language === "en-US" ? "sv-SE" : "en-US";

  return (
    <div className="tw-flex tw-h-screen tw-flex-col tw-items-center tw-p-6">
      <div className="tw-grid tw-w-full tw-grid-cols-4 tw-items-center">
        <div />
        <div className="tw-col-span-2 tw-flex tw-justify-center">
          <LogoIcon />
        </div>
        <div className="tw-flex tw-justify-end">
          <button
            onClick={() => setLocale(newLocale)}
            className="tw-flex tw-gap-2"
            type="button"
          >
            <span className="tw-text-primary">{localesShort[newLocale]}</span>
            <Globe />
          </button>
        </div>
      </div>
      <div className="tw-flex tw-w-full tw-flex-1 tw-items-center">
        {children}
      </div>
    </div>
  );
};

export { PublicPageLayout };
