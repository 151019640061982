import { encryption } from "postchain-client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import authTest, { MockTruidAuthRequest } from "../../api/rest/auth/authTest";
import { Button } from "../../components/design-system/Button";
import { FormGroup, FormLabel } from "../../components/design-system/FormGroup";
import { Input } from "../../components/design-system/Input";
import { notify } from "../../components/design-system/Notifications";
import { SelectCountry } from "../../components/SelectCountry";
import { AuthMethodProps } from "..";
import { setKeyPairInStorage } from "../utils";

const MockedTruidLogin = (props: AuthMethodProps) => {
  const i18n = useTranslation();
  const [TruidMockState, setTruidLoginState] = useState<
    Partial<MockTruidAuthRequest>
  >({
    countryCode: "SE",
  });

  const initMockTruidAuthMutation = authTest.useInitMockTruidMutation({
    onSuccess: (data) => {
      const { status } = data;
      if (status === "success") {
        props.onSuccess(data);
      } else {
        notify(i18n.t("error.general"), { type: "error" });
      }
    },
    onError: () => {
      // todo: show real error
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const onInitMockTruidSignIn = (request: MockTruidAuthRequest) => {
    const cachedData = JSON.parse(
      window.localStorage.getItem("mockTruidData") ?? "{}"
    );

    const passportNumber = request?.passportNumber ?? cachedData.passportNumber;
    const TruidUserId = request?.TruidUserId ?? cachedData.TruidUserId;
    const countryCode = request?.countryCode ?? cachedData.countryCode;

    window.localStorage.setItem(
      "mockTruidData",
      JSON.stringify({
        passportNumber,
        TruidUserId,
        countryCode,
      })
    );
    initMockTruidAuthMutation.reset();
    const newKeyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", newKeyPair);
    initMockTruidAuthMutation.mutate({
      passportNumber,
      TruidUserId,
      countryCode,
      pubKey: newKeyPair.pubKey.toString("hex"),
    });
  };

  const handleMockTruid = () => {
    onInitMockTruidSignIn(TruidMockState as MockTruidAuthRequest);
  };

  return (
    <div className="tw-w-96 tw-px-4">
      <form
        className="tw-flex tw-w-full tw-flex-col tw-gap-4"
        onSubmit={(event) => {
          handleMockTruid();
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <FormGroup>
          <FormLabel htmlFor="passportNumber">Passport Number</FormLabel>
          <Input
            value={TruidMockState.passportNumber}
            id="passportNumber"
            type="text"
            onChange={(e) =>
              setTruidLoginState({
                ...TruidMockState,
                passportNumber: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="TruidUserId">Truid UserId</FormLabel>
          <Input
            id="TruidUserId"
            value={TruidMockState.TruidUserId}
            type="text"
            onChange={(e) =>
              setTruidLoginState({
                ...TruidMockState,
                TruidUserId: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="countryCode">Nationality</FormLabel>
          <SelectCountry
            name="countryCode"
            onChange={(newValue) =>
              setTruidLoginState({
                ...TruidMockState,
                countryCode: newValue,
              })
            }
            value={TruidMockState.countryCode as string}
          />
        </FormGroup>
        {initMockTruidAuthMutation?.isError && (
          <div className="tw-flex tw-justify-between">
            <pre>
              {JSON.stringify(initMockTruidAuthMutation?.error, null, 2)}
            </pre>
          </div>
        )}
        <div className="tw-flex tw-justify-between">
          <Button
            type="button"
            onClick={() => props.onCancel()}
            disabled={initMockTruidAuthMutation?.isLoading}
          >
            Back
          </Button>
          <Button type="submit" disabled={initMockTruidAuthMutation?.isLoading}>
            {initMockTruidAuthMutation?.isError ? "Try again" : "Mock Truid"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MockedTruidLogin;
