import * as React from "react";

import diamond1x from "./assets/diamond.png";
import diamond2x from "./assets/diamond@2x.png";

const SignInFormLogo: React.FunctionComponent = () => {
  return (
    <img
      src={diamond1x}
      srcSet={`${diamond1x} 1x, ${diamond2x} 2x`}
      alt="Kvanta's Diamond-logo"
      className="tw-mx-auto tw-h-24 tw-w-24"
      width="96"
      height="96"
    />
  );
};

export { SignInFormLogo };
