import { Placement } from "@floating-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useActiveApprovalRuleQuery } from "../../../api/blockchain/company";
import { useParentEventsQuery } from "../../../api/blockchain/events";
import { useApprovalRuleProposalQuery } from "../../../api/blockchain/users";
import { useStartApprovalMutation } from "../../../api/rest/company";
import { useSession } from "../../../context/session";
import { APP_ROUTE } from "../../../routes/constants";
import { TParentEvent } from "../../../types/models/events";
import { LedgerVersion } from "../../../types/models/shares";
import {
  useShouldApprovePolicy,
  useShouldSetPolicy,
} from "../../../utils/approvalUtils";
import { AnnotationPanel } from "../../AnnotationPanel";
import { Button } from "../../design-system/Button";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { ApprovalAction } from "../types";
import approvalImgUrl from "./approval.png";

const key = "hideApprovalHelp";

function savePanelHidden(state: { [key: string]: boolean }) {
  localStorage.setItem(key, JSON.stringify(state));
}

function getPanelHiddenState(): { [key: string]: boolean } {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : {};
}

type StartApprovalProps = {
  orgNumber: string;
  currentVersion: LedgerVersion;
  setIsLoading: (value: boolean) => void;
  disabled: boolean;
  placement: Placement;
  showPanel: boolean;
  setCurrentAction: (value: ApprovalAction) => void;
  className?: string;
};

const StartApproval = ({
  orgNumber,
  currentVersion,
  setIsLoading,
  disabled,
  placement,
  showPanel,
  setCurrentAction,
  className,
}: StartApprovalProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const [isEventsLoading, setIsEventsLoading] = useState(false);
  const [panelHiddenState, setPanelHiddenState] = useState(
    getPanelHiddenState()
  );
  const eventsQuery = useParentEventsQuery({
    orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
    onSuccess: (_: TParentEvent[]) => {
      if (isEventsLoading) {
        setIsEventsLoading(false);
        setIsLoading(false);
      }
    },
  });
  const activeApprovalRuleQuery = useActiveApprovalRuleQuery(orgNumber);
  const noApprovalRule =
    activeApprovalRuleQuery.isSuccess &&
    activeApprovalRuleQuery.data &&
    activeApprovalRuleQuery.data.rule === "None";
  const pendingApprovalRuleProposalQuery =
    useApprovalRuleProposalQuery(orgNumber);
  const pendingApprovalData = pendingApprovalRuleProposalQuery.data;
  const shouldSetPolicy = useShouldSetPolicy(
    orgNumber,
    pendingApprovalData,
    user
  );
  const shouldApprovePolicy = useShouldApprovePolicy(pendingApprovalData, user);
  const isApprovalPanelOpen =
    !panelHiddenState[orgNumber] &&
    showPanel &&
    disabled &&
    noApprovalRule &&
    (shouldSetPolicy || shouldApprovePolicy);
  const startLedgerApprovalMutation = useStartApprovalMutation(
    orgNumber,
    // TODO: Submit the correct version (e.g. pending events vs draft events), not just the current version
    currentVersion,
    {
      onSuccess: () => {
        setIsEventsLoading(true);
        eventsQuery.refetch();
      },
    }
  );

  return (
    <AnnotationPanel
      className={className}
      content={
        <div className="tw-flex tw-max-w-xs tw-flex-col tw-gap-y-2">
          <div className="tw-flex tw-h-20 tw-items-center tw-justify-center tw-bg-kvanta-purple">
            <img
              src={approvalImgUrl}
              className="tw-h-4/5"
              alt="approval policy icon"
            />
          </div>
          <span className="tw-text-wrap">
            {i18n.t("events.approvalHelp.description")}
          </span>
          <div className="tw-ml-auto tw-flex tw-gap-2">
            <Button
              onClick={() => {
                savePanelHidden({ ...panelHiddenState, [orgNumber]: true });
                setPanelHiddenState({ ...panelHiddenState, [orgNumber]: true });
              }}
            >
              {i18n.t("events.approvalHelp.close")}
            </Button>
            <Link to={`${APP_ROUTE.COMPANIES}/${orgNumber}/settings`}>
              <Button color="primary" variant="solid">
                {i18n.t("events.approvalHelp.setPolicy")}
              </Button>
            </Link>
          </div>
        </div>
      }
      placement={placement}
      isOpen={isApprovalPanelOpen}
    >
      <TooltipV2
        content={
          disabled ? i18n.t("initiatePolicy.approvalPolicyRequired") : null
        }
      >
        <Button
          className="tw-w-full"
          color="primary"
          disabled={disabled}
          isLoading={startLedgerApprovalMutation.isLoading || isEventsLoading}
          variant="solid"
          onClick={() => {
            setIsLoading(true);
            setCurrentAction("initiate");
            startLedgerApprovalMutation.mutate();
          }}
        >
          {i18n.t("label.startApproval")}
        </Button>
      </TooltipV2>
    </AnnotationPanel>
  );
};

export { StartApproval };
