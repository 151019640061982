import {
  ParentEventWithStatus,
  useParentEventsQuery,
} from "../../api/blockchain/events";
import { useCurrentCompany } from "../../context/account";
import { useSession } from "../../context/session";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import {
  LedgerApprovalEvent,
  parentEventTypes,
  type TParentEvent,
} from "../../types/models/events";
import {
  getEventsWithApproval,
  getEventsWithLabel,
} from "../../utils/events-utils";
import { splitEvents } from "./split-events";

type CompanyEvents = {
  draftEvents: (TParentEvent & {
    label: string;
    approval?: LedgerApprovalEvent;
  })[];
  pendingEvents: (TParentEvent & {
    label: string;
    approval?: LedgerApprovalEvent;
  })[];
  pendingRollbackEvents: (TParentEvent & {
    label: string;
    approval?: LedgerApprovalEvent;
  })[];
  approvedEvents: (TParentEvent & {
    label: string;
    approval?: LedgerApprovalEvent;
  })[];
  isSuccess: boolean;
};

function filterPolicyEvents(events: ParentEventWithStatus[]) {
  return events.filter(
    (e) =>
      e.type !== parentEventTypes.LedgerPolicyApproval &&
      e.type !== parentEventTypes.LedgerRollbackRejected &&
      e.type !== parentEventTypes.LedgerApprovalRejected
  );
}

const useCompanyEvents = (orgNumber?: string): CompanyEvents => {
  const company = useCurrentCompany(orgNumber);
  const { user } = useSession();
  const eventsQuery = useParentEventsQuery({
    orgNumber: company?.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });

  const eventsWithApproval = getEventsWithApproval(
    eventsQuery.data?.data ?? []
  );
  const events = filterPolicyEvents(eventsWithApproval);
  const labelledEvents = getEventsWithLabel(events);
  const eventsByStatus = splitEvents(labelledEvents);

  const { approvedEvents } = eventsByStatus;
  const userRole = (company as CompanyInvolvement)?.access;
  const supportRole =
    user?.role === "Administrator"
      ? (company as CompanyInformation)?.supportRole
      : null;

  const isViewer =
    userRole === "Viewer" || (!userRole && supportRole === "Viewer");

  if (isViewer) {
    return {
      draftEvents: [],
      pendingEvents: [],
      pendingRollbackEvents: [],
      approvedEvents,
      isSuccess: eventsQuery.isSuccess,
    };
  }

  return {
    ...eventsByStatus,
    isSuccess: eventsQuery.isSuccess,
  };
};

export { useCompanyEvents };
export type { CompanyEvents };
