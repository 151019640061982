import { clsxm } from "../../../utils/tailwind";

type Color = "error" | "success" | "warning" | "blue" | "none";

type BadgeProps = React.HTMLAttributes<HTMLSpanElement> & {
  color?: Color;
};

const Badge = ({ color = "none", className, ...rest }: BadgeProps) => (
  <div
    className={clsxm(
      "tw-inline-flex tw-whitespace-nowrap tw-rounded tw-px-2 tw-py-1 tw-text-xs",
      className,
      {
        "tw-bg-error tw-text-white": color === "error",
        "tw-bg-amber-100 tw-text-amber-900": color === "warning",
        "tw-bg-green-100 tw-text-green-900": color === "success",
        "tw-bg-sky-100 tw-text-sky-900": color === "blue",
        "tw-rounded-sm tw-border tw-border-neutral-200 tw-text-neutral-800":
          color === "none",
      }
    )}
    {...rest}
  />
);

export { Badge };
export type { BadgeProps };
