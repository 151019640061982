import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/design-system/Button";
import { PlusIcon, SortIcon } from "../../components/design-system/icons";
import { Table } from "../../components/design-system/Table";
import { PageWrapper } from "../../components/PageWrapper";
import { APP_ROUTE } from "../../routes/constants";
import { CompanyInformation } from "../../types/models/administration";
import { CompanyInvolvement } from "../../types/models/company";
import { OptionsProgram } from "../../types/models/options";
import { clsxm } from "../../utils/tailwind";
import { SortByWithOrder } from "./Options";
import OptionsListItem from "./OptionsTable/OptionsListItem";

type OptionsListPageProps = {
  showForm: boolean;
  setShowForm: (show: boolean) => void;
  sortBy: SortByWithOrder;
  setSortBy: (sortBy: SortByWithOrder) => void;
  currentCompany: CompanyInformation | CompanyInvolvement;
  optionsPrograms: OptionsProgram[];
};

const OptionsListPage: React.FunctionComponent<OptionsListPageProps> = ({
  setShowForm,
  sortBy,
  setSortBy,
  currentCompany,
  optionsPrograms,
}) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const isMobileOrTabletDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const handleRowClick = (id: string) => {
    navigate(
      `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/options/${id}`
    );
  };

  const getCurrentSortLabel = (): string => {
    return `${i18n.t("label.program")} ${i18n.t(
      sortBy.split("-")[1] === "asc" ? "sortBy.name.asc" : "sortBy.name.desc"
    )}`;
  };

  const sortedPrograms = [...optionsPrograms].sort((a, b) => {
    const [sortByColumn, sortOrder] = sortBy.split("-");
    if (sortByColumn === "program") {
      return sortOrder === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    }
    if (sortByColumn === "options") {
      return sortOrder === "asc"
        ? (a.totalAmountOfOptions ?? 0) - (b.totalAmountOfOptions ?? 0)
        : (b.totalAmountOfOptions ?? 0) - (a.totalAmountOfOptions ?? 0);
    }
    if (sortByColumn === "startDate") {
      return sortOrder === "asc"
        ? new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        : new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    }
    if (sortByColumn === "excersizeEnd") {
      return sortOrder === "asc"
        ? new Date(a.strikeEndDate).getTime() -
            new Date(b.strikeEndDate).getTime()
        : new Date(b.strikeEndDate).getTime() -
            new Date(a.strikeEndDate).getTime();
    }
    if (sortByColumn === "type") {
      return sortOrder === "asc"
        ? a.type.localeCompare(b.type)
        : b.type.localeCompare(a.type);
    }
    return 0;
  });

  const rows = sortedPrograms.map((program, index) => (
    <OptionsListItem
      key={program.id}
      program={program}
      hasBorder={index < sortedPrograms.length - 1}
      currentCompany={currentCompany}
      handleRowClick={handleRowClick}
    />
  ));

  return (
    <PageWrapper className="tw-py-4">
      <header className="tw-flex tw-items-center tw-justify-between tw-pb-6">
        <div className="tw-flex tw-flex-col tw-gap-1">
          <h2 className="tw-text-[28px] tw-font-medium tw-text-primary">
            {i18n.t("options.programs.heading")}
          </h2>
          <div className="tw-text-sm tw-text-secondary">
            <Trans i18nKey="options.programs.text" />
          </div>
        </div>
        <div
          className={clsxm({
            "tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-gap-2 tw-border-t-[1px] tw-bg-white tw-px-4 tw-py-3":
              isMobileOrTabletDevice,
          })}
        >
          <Button className="tw-w-full" onClick={() => setShowForm(true)}>
            <PlusIcon className="tw-mr-1" />
            {i18n.t("options.register.optionsProgram")}
          </Button>
        </div>
      </header>
      <div className="md:tw-overflow-x-auto">
        {!isMobileOrTabletDevice ? (
          <Table
            columns={[
              {
                name: "program",
                title: i18n.t("label.program"),
                className: "tw-pl-0",
              },
              {
                name: "options",
                title: i18n.t("options.participants.options"),
              },
              {
                name: "startDate",
                title: i18n.t("options.programs.startDate"),
              },
              {
                name: "excersizeEnd",
                title: i18n.t("options.programs.endDate"),
              },
              {
                name: "type",
                title: i18n.t("options.create.type"),
                className: "tw-pr-0",
              },
            ]}
            sortBy={sortBy}
            setSortBy={(value) => setSortBy(value as SortByWithOrder)}
          >
            {rows}
          </Table>
        ) : (
          <table className="tw-w-full">
            <thead>
              <tr>
                <td colSpan={6}>
                  <Button
                    className="tw-border-none tw-text-sm"
                    onClick={() =>
                      setSortBy(
                        sortBy.split("-")[1] === "asc"
                          ? "program-desc"
                          : "program-asc"
                      )
                    }
                  >
                    <div className="tw-flex tw-items-center tw-gap-1 tw-text-secondary">
                      {getCurrentSortLabel()}
                      <SortIcon size={24} />
                    </div>
                  </Button>
                </td>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        )}
      </div>
    </PageWrapper>
  );
};

export default OptionsListPage;
