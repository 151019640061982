import { CheckCircle, WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../api/blockchain/events";
import {
  useCompanyCasesQuery,
  useCompanyShareCapitalEventsQuery,
} from "../../api/rest/administration";
import { Alert } from "../../components/design-system/Alert";
import { Breadcrumb } from "../../components/design-system/Breadcrumb";
import { Description } from "../../components/design-system/Description";
import { Disclosure } from "../../components/design-system/Disclosure";
import {
  CrossIcon,
  FoundationIcon,
  IncreaseCapitalIcon,
  ReduceCapitalIcon,
  ShareIssueIcon,
  SplitIcon,
} from "../../components/design-system/icons";
import { Loading } from "../../components/design-system/Loading";
import { Tab } from "../../components/design-system/Tab";
import { APP_ROUTE } from "../../routes/constants";
import type {
  CompanyInformation,
  CompanyShareCapitalHistory,
} from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import { TParentEvent } from "../../types/models/events";
import { getFormattedDate } from "../../utils/date";
import { formatCurrency, formatNumber } from "../../utils/format";
import eventCodes from "./eventCodes.json";
import {
  calculateRatio,
  useCompanyDataComparison,
} from "./EventsValidation.utils";

type EventsValidationProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const EventsValidation: React.FunctionComponent<EventsValidationProps> = ({
  currentCompany,
}) => {
  const i18n = useTranslation();
  const companyDataMismatch = useCompanyDataComparison(
    currentCompany.orgNumber
  );
  // Temp for data dump
  const shareCapitalEventsQuery = useCompanyShareCapitalEventsQuery(
    currentCompany.orgNumber
  );
  // Temp for data dump
  const casesQuery = useCompanyCasesQuery(currentCompany.orgNumber);
  const parentEventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const parentEvents =
    parentEventsQuery.data?.data.filter((e) =>
      [
        "CompanyFoundation",
        "DecreaseCapital",
        "DecreaseCapitalCancelShares",
        "ShareIssue",
        "IncreaseCapitalBonusIssue",
        "ShareSplit",
        "ReverseShareSplit",
      ].includes(e.type)
    ) || [];
  const common =
    companyDataMismatch?.filter((x) => x.ledger && x.companyData) || [];
  const ledgerOnly =
    companyDataMismatch?.filter((x) => x.ledger && !x.companyData) || [];
  const companyDataOnly =
    companyDataMismatch?.filter((x) => !x.ledger && x.companyData) || [];
  const [expanded, setExpanded] = useState<string[]>([]);

  if (!companyDataMismatch) {
    return <Loading />;
  }

  const codes = eventCodes as { [key: string]: string };

  const getEventTypeFormatted = (type: string) => {
    switch (type) {
      case "CompanyFoundation":
        return {
          title: i18n.t("events.companyFoundation.title"),
          icon: <FoundationIcon />,
        };
      case "ShareIssue":
        return {
          title: i18n.t("events.issue.title"),
          icon: <ShareIssueIcon />,
        };
      case "IncreaseCapitalBonusIssue":
        return {
          title: i18n.t("events.bonusIssue.title"),
          icon: <IncreaseCapitalIcon />,
        };
      case "DecreaseCapitalCancelShares":
        return {
          title: i18n.t("events.decreaseCapitalCancelShares.title"),
          icon: <ReduceCapitalIcon />,
        };
      case "DecreaseCapital":
        return {
          title: i18n.t("events.reduceCapital.title"),
          icon: <ReduceCapitalIcon />,
        };
      case "ShareSplit":
        return {
          title: i18n.t("events.split.title"),
          icon: <SplitIcon />,
        };
      case "ReverseShareSplit":
        return {
          title: i18n.t("events.reverseSplit.title"),
          icon: <CrossIcon />,
        };
      default:
        throw new Error(`Event ${type} not found`);
    }
  };

  const getEventLine = (
    event: TParentEvent | CompanyShareCapitalHistory,
    date: string,
    details: { [key: string]: string }
  ) => {
    const { title, icon } = getEventTypeFormatted(event.type);
    return (
      <Disclosure>
        <div className="tw-flex tw-items-center tw-justify-between">
          <span className="tw-text-secondary">{getFormattedDate(date)}</span>
          <div className="tw-flex tw-gap-2">
            {icon}
            <span>{title}</span>
          </div>
          <div>
            <Disclosure.Button
              open={expanded.includes(event.id)}
              onClick={() =>
                expanded.includes(event.id)
                  ? setExpanded(expanded.filter((x) => x !== event.id))
                  : setExpanded([...expanded, event.id])
              }
            />
          </div>
        </div>
        <Disclosure.Panel>
          <div className="tw-flex tw-gap-4">
            {Object.entries(details).map(([k, v]) => (
              <Description title={k} description={v} key={k} />
            ))}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    );
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Breadcrumb
          links={[
            {
              url: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`,
              name: i18n.t("label.corporateActions"),
            },
          ]}
          current="Cross-Validation"
        />
        <h1>Bolagsverket cross-validation</h1>
        <p className="tw-text-secondary">Some description</p>
        <Tab.Group>
          <Tab.List>
            <Tab>List</Tab>
            <Tab>Side by side</Tab>
            <Tab>Data</Tab>
          </Tab.List>
          <Tab.Panel>
            <div className="tw-w-full tw-p-10">
              <div className="tw-flex tw-justify-center tw-space-x-8">
                <div className="tw-w-1/3 tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-md">
                  <h2 className="tw-mb-4 tw-text-xl tw-font-semibold">
                    Common Events
                  </h2>
                  <ul className="tw-list-none">
                    {common.length > 0 ? (
                      common.map((event) => (
                        <li
                          className="tw-mb-2 tw-rounded tw-bg-gray-200 tw-p-2"
                          key={event.ledger?.id}
                        >
                          {event.ledger?.type}
                        </li>
                      ))
                    ) : (
                      <li>No common events</li>
                    )}
                  </ul>
                </div>

                <div className="tw-w-1/3 tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-md">
                  <h2 className="tw-mb-4 tw-text-xl tw-font-semibold tw-text-orange-500">
                    Missing in Ledger
                  </h2>
                  <ul className="tw-list-none">
                    {companyDataOnly.length > 0 ? (
                      companyDataOnly.map((event) => (
                        <li
                          className="tw-mb-2 tw-rounded tw-bg-orange-100 tw-p-2 tw-text-orange-600"
                          key={event.companyData?.id}
                        >
                          {event.companyData?.type}
                        </li>
                      ))
                    ) : (
                      <li className="tw-text-orange-500">
                        All events are present in List A
                      </li>
                    )}
                  </ul>
                </div>

                <div className="tw-w-1/3 tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-md">
                  <h2 className="tw-mb-4 tw-text-xl tw-font-semibold tw-text-orange-500">
                    Missing in Bolagsverket
                  </h2>
                  <ul className="tw-list-none">
                    {ledgerOnly.length > 0 ? (
                      ledgerOnly.map((event) => (
                        <li
                          className="tw-mb-2 tw-rounded tw-bg-orange-100 tw-p-2 tw-text-orange-600"
                          key={event.ledger?.id}
                        >
                          {event.ledger?.type}
                        </li>
                      ))
                    ) : (
                      <li className="tw-text-orange-500">
                        All events are present in List B
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <table className="tw-w-full tw-table-auto tw-border-collapse">
              <thead>
                <tr>
                  <th aria-labelledby="isMatching" />
                  <th className="tw-border tw-px-6 tw-py-2.5 tw-font-semibold">
                    Ledger
                  </th>
                  <th className="tw-border tw-px-6 tw-py-2.5 tw-font-semibold">
                    Bolagsverket
                  </th>
                </tr>
              </thead>
              <tbody>
                {companyDataMismatch?.map(({ ledger, companyData }) => (
                  <tr
                    key={`${ledger ? ledger.id : ""}-${
                      companyData ? companyData.id : ""
                    }`}
                  >
                    <td>
                      {ledger && companyData ? (
                        <CheckCircle color="green" />
                      ) : (
                        <WarningCircle color="red" />
                      )}
                    </td>
                    {ledger ? (
                      <td className="tw-border tw-px-6 tw-py-2.5 tw-font-normal">
                        {getEventLine(ledger, ledger.date.split(".")[0]!, {
                          ...("shares" in ledger &&
                            "total" in ledger.shares && {
                              Shares: formatNumber(ledger.shares.total),
                            }),
                          ...("shares" in ledger &&
                            "capital" in ledger.shares && {
                              Capital: formatCurrency(
                                parseFloat(ledger.shares.capital)
                              ),
                            }),
                          ...((ledger.type === "ShareSplit" ||
                            ledger.type === "ReverseShareSplit") && {
                            Ratio: `${ledger.ratio.x}:${ledger.ratio.y}`,
                          }),
                        })}
                      </td>
                    ) : (
                      <td className="tw-border">
                        <Alert type="error" className="tw-justify-center">
                          No data found
                        </Alert>
                      </td>
                    )}
                    {companyData ? (
                      <td className="tw-border tw-px-6 tw-py-2.5 tw-font-normal">
                        {getEventLine(
                          companyData,
                          companyData.decisionDate || companyData.date,
                          {
                            "Case ID": companyData.id,
                            ...(companyData.changes.shares && {
                              Shares: formatNumber(companyData.changes.shares),
                            }),
                            ...(companyData.changes.capital && {
                              Capital: formatCurrency(
                                companyData.changes.capital
                              ),
                            }),
                            ...(companyData.type === "ShareSplit" && {
                              Ratio: calculateRatio(
                                companyData.shares - companyData.changes.shares,
                                companyData.changes.shares
                              ),
                            }),
                          }
                        )}
                      </td>
                    ) : (
                      <td className="tw-border">
                        <Alert type="error" className="tw-justify-center">
                          No data found
                        </Alert>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Tab.Panel>
          <Tab.Panel>
            <h1>BV share capital changes</h1>
            <ul>
              {shareCapitalEventsQuery.data?.history.map((i) => (
                <li key={i.id}>{JSON.stringify(i)}</li>
              ))}
            </ul>
            <h1>BV cases</h1>
            <ul>
              {casesQuery.data?.cases.map((i) => (
                <li key={i.caseId}>
                  {JSON.stringify({
                    ...i,
                    codes: i.codes.map((c) => ({
                      key: c,
                      description: codes[c],
                    })),
                  })}
                </li>
              ))}
            </ul>
            <h1>Our events</h1>
            <ul>
              {parentEvents.map((i) => (
                <li key={i.id}>{JSON.stringify(i)}</li>
              ))}
            </ul>
          </Tab.Panel>
        </Tab.Group>
      </div>
    </>
  );
};

export { EventsValidation };
