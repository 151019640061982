import type React from "react";

import type { ShareHolderWithMetricsAndEntity } from "../../../types/models/shares";
import {
  formatNumber,
  formatPercentage,
  formatRefId,
} from "../../../utils/format";
import { Description } from "../../design-system/Description";
import { EntityItem } from "../../design-system/EntityItem";
import { ShareholderBadge } from "../../design-system/ShareholderBadgeList/ShareholderBadge";

type ShareHoldersRowProps = {
  holder: ShareHolderWithMetricsAndEntity & {
    dilutedShares?: number;
    dilutedVotes?: number;
  };
  totalShares: number;
  totalVotes: number;
  dilutedTotals: { totalVotes: number; totalShares: number };
};

const ShareHoldersRow: React.FC<ShareHoldersRowProps> = ({
  holder,
  totalShares,
  totalVotes,
  dilutedTotals,
}) => (
  <tr className="tw-border-b-[1px] tw-border-neutral-200">
    <td className="tw-px-6 tw-py-4">
      <Description
        title={
          <EntityItem
            value={holder.entity}
            showId={false}
            hasFlag={false}
            displayIcon={false}
          />
        }
        description={
          formatRefId({
            refId: holder.entity.refId,
            countryCode: holder.entity.countryCode,
            birthDate: holder.entity.birthDate,
            type: holder.type,
          }) || "-"
        }
      />
    </td>
    <td
      className="tw-px-6 tw-py-4"
      data-testid={`${holder.entity.name}-shares`}
    >
      {holder.nrOfShares ? (
        <Description
          title={formatPercentage(holder.nrOfShares / totalShares)}
          description={formatNumber(holder.nrOfShares)}
        />
      ) : null}
    </td>
    {holder.dilutedShares && (
      <td
        className="tw-px-6 tw-py-4"
        data-testid={`${holder.entity.name}-dilutedShares`}
      >
        <Description
          title={formatPercentage(
            holder.dilutedShares / dilutedTotals.totalShares
          )}
          description={formatNumber(holder.dilutedShares)}
        />
      </td>
    )}
    <td className="tw-px-6 tw-py-4" data-testid={`${holder.entity.name}-votes`}>
      {holder.nrOfVotes ? (
        <Description
          title={formatPercentage(holder.nrOfVotes / totalVotes)}
          description={formatNumber(holder.nrOfVotes)}
        />
      ) : null}
    </td>
    {holder.dilutedVotes && (
      <td
        className="tw-px-6 tw-py-4"
        data-testid={`${holder.entity.name}-dilutedVotes`}
      >
        <Description
          title={formatPercentage(
            holder.dilutedVotes / dilutedTotals.totalVotes
          )}
          description={formatNumber(holder.dilutedVotes)}
        />
      </td>
    )}
    <td
      className="tw-max-w-[200px] tw-px-6 tw-py-4"
      colSpan={holder.dilutedShares || holder.dilutedVotes ? 1 : 2}
    >
      <div className="tw-flex tw-justify-between">
        <ShareholderBadge
          labelsInfo={Object.entries(holder.sharesByType).map(
            ([label, shares]) => ({
              label,
              shares,
            })
          )}
        />
      </div>
    </td>
  </tr>
);

export { ShareHoldersRow };
