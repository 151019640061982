import { Loading } from "../../../../components/design-system/Loading";
import { PageWrapper } from "../../../../components/PageWrapper";
import { useCurrentCompany } from "../../../../context/account";
import { OnboardingPage } from "../../../CompanySettings/Onboarding/Onboarding";
import { Audit } from "../../../CompanyShares/Audit";

const AuditLogPage: React.FunctionComponent = () => {
  const currentCompany = useCurrentCompany();

  if (currentCompany === undefined) {
    return <Loading />;
  }

  if (currentCompany.companyStatus === "Onboarded") {
    return (
      <PageWrapper
        className="max-sm:tw-px-4 max-sm:tw-py-0"
        data-testid="company-events-page"
      >
        <Audit currentCompany={currentCompany} />
      </PageWrapper>
    );
  }

  return <OnboardingPage />;
};

export { AuditLogPage };
