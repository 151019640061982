import { KeyPair } from "postchain-client";

type KeyPairStorage = { publicKey: string; privateKey: string };

export const setKeyPairInStorage = (
  key: "keyPair" | "pendingKeyPair",
  keypair: KeyPair
) => {
  const object: KeyPairStorage = {
    publicKey: keypair.pubKey.toString("hex"),
    privateKey: keypair.privKey.toString("hex"),
  };

  window.localStorage.setItem(key, JSON.stringify(object));
};

export const getKeyPairFromStorage = (
  key: "keyPair" | "pendingKeyPair"
): KeyPair | undefined => {
  const string = window.localStorage.getItem(key);
  if (!string) {
    return undefined;
  }
  const object = JSON.parse(string) as KeyPairStorage;

  return {
    pubKey: Buffer.from(object.publicKey, "hex"),
    privKey: Buffer.from(object.privateKey, "hex"),
  };
};
