import { encryption } from "postchain-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import bankId from "../../api/rest/auth/BankID";
import { notify } from "../../components/design-system/Notifications";
import isMobileDevice from "../../utils/isMobileDevice";
import { AuthMethodProps } from "..";
import { setKeyPairInStorage } from "../utils";
import BankIdDeviceLogin from "./BankIdDeviceLogin";
import BankIdMobileLogin from "./BankIdMobileLogin";

const BankIdLogin = (props: AuthMethodProps) => {
  const i18n = useTranslation();
  const mobile = isMobileDevice();
  const [device, setDevice] = useState(false);

  const initMutation = bankId.useInitAuthMutation({
    onError: () => {
      notify(i18n.t("error.general"), { type: "error" });
    },
  });
  const cancelMutation = bankId.useCancelMutation();

  const onInit = () => {
    // TODO: Set and pass to component so that it doesn't create key or modify storage
    const keyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", keyPair);
    initMutation.mutate(keyPair.pubKey.toString("hex"));
  };

  const onCancel = () => {
    if (initMutation.data?.orderRef) {
      cancelMutation.mutate(initMutation.data?.orderRef);
    }
    props.onCancel();
  };

  // Init
  useEffect(() => onInit(), []);

  // If on a mobile device or the "Same device" option is clicked
  if (mobile || device) {
    return (
      <BankIdDeviceLogin
        initMutation={initMutation}
        onRetry={onInit}
        onSuccess={props.onSuccess}
        onCancel={onCancel}
      />
    );
  }

  return (
    <BankIdMobileLogin
      initMutation={initMutation}
      onRetry={onInit}
      onSuccess={props.onSuccess}
      onCancel={onCancel}
      onClickSameDevice={() => setDevice(true)}
    />
  );
};

export default BankIdLogin;
