import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IRequestError } from "../../../api";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { Button } from "../../../components/design-system/Button";
import { DistributionProgress } from "../../../components/design-system/DistributionProgress";
import { EntityItem } from "../../../components/design-system/EntityItem";
import {
  FormError,
  FormErrorList,
  FormGroup,
} from "../../../components/design-system/FormGroup";
import { CrossIcon, PlusIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { Select } from "../../../components/design-system/Select";
import { EditEntity } from "../../../components/Entities/Edit";
import { PageWrapper } from "../../../components/PageWrapper";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { Entity } from "../../../types/models/entities";
import {
  OptionsProgram,
  OptionsProgramParticipant,
} from "../../../types/models/options";
import { AddParticipantDialog } from "./AddParticipantDialog";

const LOCALSTORAGE_KEY = "draftOptionParticipants";

function saveStateToLocalStorage(
  orgNumber: string,
  type: "Warrants" | "EmployeeStockOptions",
  participants: OptionsProgramParticipant[]
) {
  const current =
    localStorage.getItem(LOCALSTORAGE_KEY) &&
    JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? "{}");
  localStorage.setItem(
    LOCALSTORAGE_KEY,
    JSON.stringify({ ...current, [orgNumber]: { type, participants } })
  );
}

function loadStateFromLocalStorage(
  orgNumber: string,
  type: "Warrants" | "EmployeeStockOptions"
): OptionsProgramParticipant[] {
  const data:
    | {
        [orgNumber: string]: {
          type: "Warrants" | "EmployeeStockOptions";
          participants: OptionsProgramParticipant[];
        };
      }
    | undefined =
    localStorage.getItem(LOCALSTORAGE_KEY) &&
    JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? "{}");
  if (data && data[orgNumber] && data[orgNumber].type === type) {
    return data[orgNumber].participants;
  }
  return [];
}

function deleteStateFromLocalStorage() {
  localStorage.removeItem(LOCALSTORAGE_KEY);
}

type ParticipantsProps = {
  form: string;
  programType: "Warrants" | "EmployeeStockOptions";
  currentCompany: CompanyInvolvement | CompanyInformation;
  selectedProgram?: OptionsProgram;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  maxTotal?: number;
  onSubmit: (data: OptionsProgramParticipant[]) => void;
  error: IRequestError | null;
};

const Participants = ({
  form,
  programType,
  currentCompany,
  selectedProgram,
  entitiesQuery,
  maxTotal,
  onSubmit,
  error,
}: ParticipantsProps) => {
  const i18n = useTranslation();
  const [addParticipantIsOpen, setAddParticipantIsOpen] = useState(false);
  const enableCompanies = programType === "Warrants";
  const entities = (entitiesQuery.data || []).filter(
    (x) => enableCompanies || x.type === "Private"
  );
  const typeOptions = [
    { value: "Employee", label: i18n.t("options.participants.role.employee") },
    {
      value: "BoardMember",
      label: i18n.t("options.participants.role.boardMember"),
    },
  ];

  const defaultValues: { participants: OptionsProgramParticipant[] } = {
    participants:
      selectedProgram?.participants ||
      loadStateFromLocalStorage(currentCompany.orgNumber, programType) ||
      [],
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "participants",
  });
  const items = useWatch({
    control,
    name: "participants",
  });

  useEffect(() => {
    if (!selectedProgram) {
      saveStateToLocalStorage(currentCompany.orgNumber, programType, items);
    }
  }, [items, programType, selectedProgram]);

  const remainingTotal = useMemo(() => {
    const totalUsed = items
      .map(({ amountOfOptions }) =>
        Number.isNaN(amountOfOptions) ? 0 : amountOfOptions
      )
      .reduce((partialSum, a) => partialSum + a, 0);
    const total = totalUsed - maxTotal!;
    return total;
  }, [items]);

  const partipantsWithEntity: {
    p: OptionsProgramParticipant;
    index: number;
    entity: Entity;
  }[] = fields
    .map((p, index) => ({
      p,
      index,
      entity: entities.find(({ id }) => id === p.participant.id)!,
    }))
    .sort((a, b) => {
      if (!a.p.role || !b.p.role) {
        return 0;
      }
      if (a.p.role < b.p.role) {
        return -1;
      }
      if (a.p.role > b.p.role) {
        return 1;
      }
      return 0;
    });

  return (
    <PageWrapper className="tw-flex tw-h-[calc(100vh-10rem)] tw-max-w-3xl tw-flex-col tw-justify-start tw-px-0 tw-pb-0 tw-pt-6">
      <form
        onSubmit={(event) => {
          clearErrors("participants");
          handleSubmit((data) => {
            if (remainingTotal > 0) {
              setError("participants", {
                type: "manual",
                message: `Total cannot exceed ${maxTotal}`,
              });
              return;
            }
            onSubmit(data.participants);
          })(event);
        }}
        id={form}
      >
        <div className="tw-h-full tw-space-y-4 tw-overflow-auto tw-pb-4 max-md:tw-px-4">
          <div>
            <h2>{i18n.t("options.participants.title")}</h2>
            <p className="tw-mt-0">
              {i18n.t("options.participants.description")}
            </p>
          </div>
          <Button
            className="tw-w-full"
            variant="outline"
            color="primary"
            onClick={() => setAddParticipantIsOpen(true)}
          >
            <PlusIcon />{" "}
            {i18n.t(
              enableCompanies
                ? "options.participants.button.company"
                : "options.participants.button"
            )}
          </Button>
          {addParticipantIsOpen && (
            <AddParticipantDialog
              title={i18n.t("options.participants.add.title")}
              enableCompanies={enableCompanies}
              programType={programType}
              onClose={() => setAddParticipantIsOpen(false)}
              onSuccess={(value) => {
                append(value);
                setAddParticipantIsOpen(false);
              }}
              selectedEntities={items.map(({ participant }) => participant.id)}
              entitiesQuery={entitiesQuery}
              currentCompany={currentCompany}
            />
          )}
          {partipantsWithEntity.length > 0 && (
            <table className="tw-w-full tw-table-fixed">
              <thead className="tw-text-left">
                <tr className="tw-border-b">
                  <th
                    scope="col"
                    className="tw-px-6 tw-py-2.5 tw-text-sm tw-font-normal tw-text-neutral-500"
                    colSpan={2}
                  >
                    {i18n.t(
                      enableCompanies
                        ? "options.participants.entity"
                        : "options.participants.person"
                    )}
                  </th>
                  <th
                    scope="col"
                    className="tw-px-6 tw-py-2.5 tw-text-sm tw-font-normal tw-text-neutral-500"
                    aria-label="Actions"
                  />
                  {programType === "EmployeeStockOptions" && (
                    <th
                      scope="col"
                      className="tw-py-2.5 tw-text-sm tw-font-normal tw-text-neutral-500 max-md:tw-hidden"
                    >
                      {i18n.t("options.participants.role")}
                    </th>
                  )}
                  <th
                    scope="col"
                    className="tw-px-2 tw-py-2.5 tw-text-sm tw-font-normal tw-text-neutral-500"
                  >
                    {i18n.t("options.participants.options")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {partipantsWithEntity.map(({ p, index, entity }) => (
                  <tr
                    className="tw-border-b-[1px] tw-border-neutral-200"
                    key={p.participant.id}
                  >
                    <td colSpan={2}>
                      <EntityItem value={entity} className="tw-col-span-2" />
                    </td>
                    <td className="tw-py-4 max-md:tw-px-0">
                      <EditEntity
                        currentCompany={currentCompany}
                        value={entity}
                        onSuccess={() => entitiesQuery.refetch()}
                      />
                      <Button
                        variant="clean"
                        size="sm"
                        onClick={() => remove(index)}
                      >
                        <CrossIcon className="tw-h-6 tw-w-6" />
                      </Button>
                    </td>
                    {programType === "EmployeeStockOptions" && (
                      <td className="tw-py-4 max-md:tw-hidden">
                        <Controller
                          name={`participants.${index}.role`}
                          control={control}
                          rules={{
                            required: i18n.t("error.validation.required"),
                          }}
                          render={({
                            field: { value, onChange, onBlur },
                            fieldState,
                          }) => (
                            <FormGroup>
                              <Select
                                menuPosition="fixed"
                                value={typeOptions.find(
                                  (option) => option.value === value
                                )}
                                onChange={(option) => {
                                  if (option) {
                                    onChange(option.value);
                                  }
                                }}
                                onBlur={onBlur}
                                options={typeOptions}
                              />
                              <FormError>{fieldState.error?.message}</FormError>
                            </FormGroup>
                          )}
                        />
                      </td>
                    )}
                    <td className="tw-px-2 tw-py-4 max-md:tw-px-0">
                      <FormGroup>
                        <Input
                          id="max"
                          {...register(
                            `participants.${index}.amountOfOptions`,
                            {
                              required: i18n.t("error.validation.required"),
                              valueAsNumber: true,
                              min: {
                                value: 1,
                                message: i18n.t("error.validation.range.min", {
                                  min: 1,
                                }),
                              },
                            }
                          )}
                          type="number"
                          defaultValue={p.amountOfOptions}
                          min={1}
                        />
                        {errors.participants &&
                          errors.participants[index] &&
                          errors.participants[index].amountOfOptions && (
                            <FormError>
                              {
                                errors.participants[index].amountOfOptions
                                  .message
                              }
                            </FormError>
                          )}
                      </FormGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {errors.participants && (
            <FormError>{errors.participants.message}</FormError>
          )}
          <DistributionProgress
            diff={remainingTotal}
            allString="options.validation.all"
            moreString="options.validation.moreToDistribute"
            tooManyString="options.validation.tooMany"
          />
        </div>
      </form>
      {error && <FormErrorList error={error} />}
    </PageWrapper>
  );
};

export { deleteStateFromLocalStorage, Participants };
