import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { useCompaniesQuery } from "../../../api/blockchain/company";
import { useCompanyDetailsQuery } from "../../../api/rest/administration";
import { useDraftOnboardingQuery } from "../../../api/rest/draft";
import { useCompleteOnboardingMutation } from "../../../api/rest/onboarding";
import { CrossIcon } from "../../../components/design-system/icons";
import { Loading } from "../../../components/design-system/Loading";
import { notify } from "../../../components/design-system/Notifications";
import { PageWrapper } from "../../../components/PageWrapper";
import { useStepper } from "../../../hooks/useStepper";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type {
  ShareCapital as TShareCapital,
  TDraftShareBlock,
  TDraftShareType,
} from "../../../types/models/draft";
import { draftName } from "./constants";
import ShareBlocks from "./ShareBlocks";
import ShareCapital from "./ShareCapital";
import ShareClasses from "./ShareClasses";
import type { FormChange, FormState } from "./types";

type CompanyOnboardingProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const NUMBER_OF_STEPS = 3;

const CompanyOnboarding = ({ currentCompany }: CompanyOnboardingProps) => {
  const [form, setForm] = useState<FormState>({
    shareCapital: { formationDate: "", currency: "SEK" },
    shareClasses: [],
    shareBlocks: [],
  });
  const {
    currentStep,
    handleNext: handleStepSuccess,
    handlePrevious: handleStepCancel,
    jumpToStep,
  } = useStepper(NUMBER_OF_STEPS);
  const i18n = useTranslation();
  const navigate = useNavigate();
  const companies = useCompaniesQuery();

  const handleFormChange = (values: FormChange) => {
    setForm((prev) => ({ ...prev, ...values }));
  };

  const handleInitialState = (
    data: [TShareCapital, TDraftShareType[], TDraftShareBlock[]]
  ) => {
    const [capital, classes, blocks] = data;

    // #region Boolean cast
    /*
     * TODO: Check if a empty strings and zeroes really are invalid values by
     * investigating the console.assert-calls below, then remove the Boolean
     * cast and explicitly check for undefined instead.
     */
    const hasCapital = Boolean(
      //
      capital.formationDate && capital.totalShares && capital.capitalAmount
    );
    console.assert(
      capital.formationDate?.trim() !== "",
      "Invalid formation date"
    );
    console.assert(capital.totalShares !== 0, "Invalid total shares");
    console.assert(capital.capitalAmount !== 0, "Invalid capital amount");
    // #endregion

    const hasClasses = classes.length > 0;
    const hasBlocks = blocks.length > 0;

    if (!hasCapital) {
      jumpToStep(0);

      return;
    }

    handleFormChange({ shareCapital: capital });

    if (!hasClasses) {
      jumpToStep(1);

      return;
    }

    handleFormChange({ shareClasses: classes });

    if (!hasBlocks) {
      jumpToStep(2);

      return;
    }

    handleFormChange({ shareBlocks: blocks });

    jumpToStep(2);
  };

  const companyNotOnboarded = currentCompany.companyStatus === "Onboarding";

  const onboardingQuery = useDraftOnboardingQuery(
    currentCompany.orgNumber,
    draftName,
    { onSuccess: handleInitialState }
  );

  const completeOnboardingMutation = useCompleteOnboardingMutation(
    currentCompany.orgNumber,
    {
      onSuccess: () => {
        companies?.refetch();
        navigate(`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`);
      },
      onError: (error) => {
        notify(i18n.t(error.errors[0].message.code), { type: "error" });
      },
    }
  );

  const descriptionList = [
    i18n.t("onboarding.shareCapital.description"),
    i18n.t("onboarding.shareClasses.description"),
    i18n.t("onboarding.shareBlocks.description"),
  ];

  const companyDetailsQuery = useCompanyDetailsQuery(currentCompany.orgNumber);
  const companyDetails = companyDetailsQuery.isSuccess
    ? companyDetailsQuery.data
    : undefined;
  if (onboardingQuery.isLoading || companyDetailsQuery.isLoading) {
    return <Loading />;
  }

  return (
    <PageWrapper data-testid="onboarding">
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>
            {`${i18n.t("label.step")} ${currentStep + 1} ${i18n.t(
              "label.of"
            )} ${NUMBER_OF_STEPS} - ${i18n.t(
              "onboarding.initialCompanyFormation"
            )}`}
          </h4>
          <div className="tw-text-secondary">
            {descriptionList[currentStep]}
          </div>
        </div>
        <Link
          to={
            companyNotOnboarded
              ? `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/onboarding`
              : "../"
          }
          className="tw-text-body"
        >
          <CrossIcon className="tw-h-6 tw-w-6" />
        </Link>
      </header>
      <div>
        {currentStep === 0 && (
          <ShareCapital
            companyDetails={companyDetails}
            currentCompany={currentCompany}
            initialData={form}
            onFormChange={handleFormChange}
            onSuccess={handleStepSuccess}
          />
        )}
        {currentStep === 1 && (
          <ShareClasses
            companyDetails={companyDetails}
            currentCompany={currentCompany}
            initialData={form}
            onFormChange={handleFormChange}
            onSuccess={handleStepSuccess}
            onCancel={handleStepCancel}
          />
        )}
        {currentStep === 2 && (
          <ShareBlocks
            currentCompany={currentCompany}
            initialData={form}
            onFormChange={handleFormChange}
            onSuccess={() => {
              completeOnboardingMutation.mutate();
            }}
            onCancel={handleStepCancel}
            isLoading={completeOnboardingMutation.isLoading}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export { CompanyOnboarding };
