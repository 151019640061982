import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../../api/blockchain/events";
import { useRejectLedgerMutation } from "../../../api/rest/company";
import {
  LedgerApprovalInitializedEvent,
  LedgerRollbackPending,
  TParentEvent,
} from "../../../types/models/events";
import { LedgerVersion } from "../../../types/models/shares";
import { Button } from "../../design-system/Button";

type RejectProps = {
  orgNumber: string;
  currentVersion: LedgerVersion;
};

const Reject = ({ orgNumber, currentVersion }: RejectProps) => {
  const i18n = useTranslation();
  const [isEventsLoading, setIsEventsLoading] = useState(false);
  const eventsQuery = useParentEventsQuery({
    orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
    onSuccess: (_: TParentEvent[]) => {
      if (isEventsLoading) {
        setIsEventsLoading(false);
      }
    },
  });
  const events = eventsQuery.data?.data || [];
  const rollbackPending = events.find(
    (e) => e.type === "LedgerRollbackPending"
  ) as LedgerRollbackPending;
  const approvalInitialisedEvent = events.find(
    (e) => e.type === "LedgerApprovalInitialized"
  ) as LedgerApprovalInitializedEvent;
  const rejectLedgerApprovalMutation = useRejectLedgerMutation(
    orgNumber,
    currentVersion,
    rollbackPending,
    approvalInitialisedEvent,
    {
      onSuccess: () => {
        setIsEventsLoading(true);
        eventsQuery.refetch();
      },
    }
  );

  return (
    <Button
      className="tw-w-full"
      color="secondary"
      variant="outline"
      isLoading={rejectLedgerApprovalMutation.isLoading || isEventsLoading}
      onClick={() => {
        rejectLedgerApprovalMutation.mutate();
      }}
    >
      {i18n.t(rollbackPending ? "label.rejectRollback" : "label.rejectPending")}
    </Button>
  );
};

export { Reject };
