import { useTranslation } from "react-i18next";

import type { SupportAccess } from "../../types/models/users";
import { Description } from "../design-system/Description";
import type { OptionProps, SingleValueProps } from "../design-system/Select";
import {
  components,
  Option as SelectOption,
  Select,
} from "../design-system/Select";

type Option = { value: SupportAccess; label: string; description: string };

const RoleOption = (props: OptionProps<Option, false>) => {
  const { data, isDisabled } = props;
  const disabledRoleStyle = isDisabled ? "disabled" : "default";

  return (
    <SelectOption {...props}>
      <Description
        title={data.label}
        description={data.description}
        theme={disabledRoleStyle}
      />
    </SelectOption>
  );
};

const SingleValue = (props: SingleValueProps<Option, false>) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>{data.label}</components.SingleValue>
  );
};

type SelectRoleProps = {
  value: SupportAccess;
  onChange: (entityType?: SupportAccess) => void;
  noAccess?: boolean;
  isApprover?: boolean;
  enabled?: boolean;
};

const SelectRole: React.FunctionComponent<SelectRoleProps> = ({
  value,
  onChange,
  noAccess = false,
  isApprover,
  enabled,
}: SelectRoleProps) => {
  const i18n = useTranslation();

  const options: {
    value: SupportAccess;
    label: string;
    description: string;
    isDisabled?: boolean;
  }[] = [
    {
      value: "Viewer",
      label: i18n.t("role.viewer"),
      description: i18n.t("role.viewer.description"),
      isDisabled: isApprover,
    },
    {
      value: "Editor",
      label: i18n.t("role.editor"),
      description: i18n.t("role.editor.description"),
    },
    {
      value: "Administrator",
      label: i18n.t("role.administrator"),
      description: i18n.t("role.administrator.description"),
    },
  ];

  if (noAccess) {
    options.push({
      value: "No access",
      label: i18n.t("role.noAccess"),
      description: i18n.t("role.noAccess.description"),
    });
  }

  return (
    <Select
      name="selectRole"
      isDisabled={!enabled}
      value={options.find((c) => c.value === value)}
      options={options}
      onChange={(newValue) => onChange(newValue?.value)}
      components={{ Option: RoleOption, SingleValue }}
      menuPosition="fixed"
    />
  );
};

export { SelectRole };
