import { useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { LedgerApprovalEvent } from "../../../../types/models/events";
import { Description } from "../../../design-system/Description";
import { CheckmarkIcon } from "../../../design-system/icons";
import AuditLine from "./AuditLine";

export const LedgerApprovalAuditLine = (props: {
  event: LedgerApprovalEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const approvingUsers =
    props.event.version === "1" ? [props.event.user] : props.event.users;
  const approvedBy = approvingUsers.map((u) => props.entitiesMap[u.id]?.name);

  return (
    <AuditLine
      title={i18n.t("events.ledgerApproval.title")}
      icon={<CheckmarkIcon />}
      description={i18n.t("events.ledgerApproval.title")}
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.approvedBy")}
              description={approvedBy.join(", ")}
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
