import { encryption } from "postchain-client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import authTest from "../../api/rest/auth/authTest";
import { Button } from "../../components/design-system/Button";
import { Loading } from "../../components/design-system/Loading";
import { notify } from "../../components/design-system/Notifications";
import { AuthMethodProps } from "..";
import { setKeyPairInStorage } from "../utils";

const MockedBankIdLogin = (props: AuthMethodProps) => {
  const i18n = useTranslation();

  const initMockBankIdAuthMutation = authTest.useInitMockBankIdMutation({
    onSuccess: (data) => {
      const { status } = data;
      if (status === "complete") {
        props.onSuccess({ user: data.user });
      } else if (status === "failed") {
        notify(i18n.t("error.general"), { type: "error" });
      }
    },
    onError: () => {
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const handleInitMockBankIdSignIn = () => {
    const promptRefId =
      window.prompt(
        "Personnummer",
        window.localStorage.getItem("mockRefId") || ""
      ) ?? "";

    const refId = promptRefId || window.localStorage.getItem("mockRefId")!;
    window.localStorage.setItem("mockRefId", refId);

    if (refId.trim() === "") {
      return;
    }

    const newKeyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", newKeyPair);
    initMockBankIdAuthMutation.mutate({
      refId,
      pubKey: newKeyPair.pubKey.toString("hex"),
      countryCode: "SE",
    });
  };

  // On init
  useEffect(() => handleInitMockBankIdSignIn(), []);

  return (
    <div className="tw-flex tw-flex-col">
      <h3 className="tw-text-center">{i18n.t("login.tip")} mock</h3>
      <Loading />
      <Button color="primary" variant="clean" onClick={props.onCancel}>
        {i18n.t("label.cancel")}
      </Button>
    </div>
  );
};

export default MockedBankIdLogin;
