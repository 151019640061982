import { MagicWand } from "@phosphor-icons/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useActiveApprovalRuleQuery } from "../../../../../../api/blockchain/company";
import { useUserConfirmedLedgerAsOfficial } from "../../../../../../api/blockchain/officialLedger";
import {
  useApprovalRuleProposalQuery,
  useUsersQuery,
} from "../../../../../../api/blockchain/users";
import { getApprovalPolicyRule } from "../../../../../../api/rest/approval-rule-policy";
import authTest from "../../../../../../api/rest/auth/authTest";
import { useEntitiesQuery } from "../../../../../../api/rest/entities";
import {
  useApprovalPercentage,
  useApprovalSpecific,
  useConfirmLedgerAsOfficialAndApprovalPercentage,
  useConfirmLedgerAsOfficialAndApprovalSpecific,
} from "../../../../../../api/rest/events";
import { useUpdateUsersMutation } from "../../../../../../api/rest/users";
import BankIdSign from "../../../../../../auth/BankId/BankIdSign";
import { TruidSign } from "../../../../../../auth/Truid/TruidSign";
import { Alert } from "../../../../../../components/design-system/Alert";
import { Button } from "../../../../../../components/design-system/Button";
import { Description } from "../../../../../../components/design-system/Description";
import {
  BankIdIcon,
  TruidIcon,
} from "../../../../../../components/design-system/icons";
import { Loading } from "../../../../../../components/design-system/Loading";
import { notify } from "../../../../../../components/design-system/Notifications";
import { ProgressDialog } from "../../../../../../components/design-system/ProgressDialog";
import { PageWrapper } from "../../../../../../components/PageWrapper";
import { UpgradeUserModal } from "../../../../../../components/UpgradeUserModal";
import { featureToggles } from "../../../../../../config";
import {
  getLoginTypeInStorage,
  useSession,
} from "../../../../../../context/session";
import { useToggles } from "../../../../../../hooks/useToggles";
import type { CompanyInformation } from "../../../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../../../types/models/company";
import * as monitoring from "../../../../../../utils/monitoring";
import type { Policy } from "../../../../../../utils/policy";
import Done from "../components/Done";
import { AcceptLedgerAsOfficial } from "./AcceptLedgerAsOfficial";
import { ChangeApprovalPolicy } from "./ChangeApprovalPolicy";
import {
  checkManualApproverHasViewer,
  getApproversIds,
  getViewerApproverIds,
  getViewerApprovers,
} from "./ChangeApprovalPolicy.utils";
import PreviewNewPolicyProposalChanges from "./PreviewNewPolicyProposalChanges";

type ChangeApprovalPolicyProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

type NewPolicy = {
  rule: Policy;
  approvers: string[];
  percentage?: number;
};

type NewApprovalPolicyState = {
  acceptLedgerAsOfficial?: boolean;
  newPolicy: NewPolicy;
};

type Step =
  | "INITIAL_STEP"
  | "ACCEPT_LEDGER_AS_OFFICIAL_STEP"
  | "SELECT_POLICY_STEP"
  | "PREVIEW_STEP"
  | "SIGN_STEP"
  | "DONE_STEP";

const steps: Readonly<Record<Step, number>> = Object.freeze({
  INITIAL_STEP: 0,
  ACCEPT_LEDGER_AS_OFFICIAL_STEP: 1,
  SELECT_POLICY_STEP: 2,
  PREVIEW_STEP: 3,
  SIGN_STEP: 4,
  DONE_STEP: 5,
});

function saveStateToLocalStorage(
  orgNumber: string,
  state: NewApprovalPolicyState
) {
  localStorage.setItem(
    "pendingApprovalPolicyChange",
    JSON.stringify({ state, orgNumber })
  );
}

function loadStateFromLocalStorage(): NewApprovalPolicyState {
  return JSON.parse(localStorage.getItem("pendingApprovalPolicyChange") ?? "{}")
    ?.state;
}

function clearStateFromLocalStorage() {
  localStorage.removeItem("pendingApprovalPolicyChange");
}

const ChangeShareLedgerSettings: React.FunctionComponent<
  ChangeApprovalPolicyProps
> = ({ currentCompany }) => {
  const i18n = useTranslation();
  // Remove once https://github.com/capchapdev/capchap-frontend/pull/1757 is completed
  const { user, loginType: loginTypeInState } = useSession();
  const loginTypeInStorage = getLoginTypeInStorage();
  const loginType = loginTypeInState ?? loginTypeInStorage;
  // #
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const userRoleInCompany = useMemo(() => {
    if (!usersQuery.isSuccess || !usersQuery.data || !user) {
      return undefined;
    }
    return usersQuery.data.find((u) => u.user.id === user?.id)?.role;
  }, [user, usersQuery.data, usersQuery.isSuccess]);
  const userCanChangeApprovalPolicy = ["BoardMember", "Administrator"].includes(
    userRoleInCompany ?? ""
  );

  const { isFeatureEnabled } = useToggles();
  const mockSignEnabled =
    isFeatureEnabled(featureToggles.ENABLE_MOCK_SIGN) &&
    user?.role === "Administrator";
  const getMockedSignatureMutation = authTest.useGetMockedSignatureMutation();
  const [upgradeUsersModal, setUpgradeUsersModal] = useState<boolean>(false);
  const updateUsersMutation = useUpdateUsersMutation(currentCompany.orgNumber, {
    onSuccess: () => {
      setUpgradeUsersModal(false);
      notify(<Description title={i18n.t("label.upgradeUsers.notify")} />, {
        type: "success",
      });
      usersQuery.refetch();
      setCurrentStep(currentStep + 1);
    },
  });
  const userApprovedLedgerAsOfficialQuery = useUserConfirmedLedgerAsOfficial(
    currentCompany.orgNumber
  );
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);

  const approversOptions = useMemo(
    () =>
      (entitiesQuery.isSuccess && entitiesQuery.data
        ? entitiesQuery.data
        : []
      ).filter((entity) => entity.type === "Private"),
    [entitiesQuery.data, entitiesQuery.isSuccess]
  );

  const [state, setState] = useState<NewApprovalPolicyState>({
    newPolicy: {
      rule: "None",
      approvers: [],
      percentage: undefined,
    },
  });
  const [currentStep, setCurrentStep] = useState(steps.INITIAL_STEP);

  const confirmLedgerAsOfficialAndApprovalRulePercentageMutation =
    useConfirmLedgerAsOfficialAndApprovalPercentage(currentCompany.orgNumber);

  const approvalRulePercentageMutation = useApprovalPercentage(
    currentCompany.orgNumber
  );

  const approvalRuleSpecificMutation = useApprovalSpecific(
    currentCompany.orgNumber
  );

  const confirmLedgerAsOfficialAndApprovalRuleSpecificMutation =
    useConfirmLedgerAsOfficialAndApprovalSpecific(currentCompany.orgNumber);

  const activeApprovalRuleQuery = useActiveApprovalRuleQuery(
    currentCompany.orgNumber
  );

  const ruleToCurrentPolicy = useMemo(
    () =>
      activeApprovalRuleQuery.data
        ? getApprovalPolicyRule(activeApprovalRuleQuery.data)
        : null,
    [activeApprovalRuleQuery.data]
  );

  const approvalRuleProposal = useApprovalRuleProposalQuery(
    currentCompany.orgNumber,
    { enabled: false } // need to listen to lodinng events only
  );

  const mustConfirmLedgerAsOfficial = useMemo(() => {
    const userConfirmedLedgerAsOfficial =
      userApprovedLedgerAsOfficialQuery.data === 1;
    const ledgerConfirmedAsOfficial =
      currentCompany.confirmedAsOfficial.status === "Completed";
    return !userConfirmedLedgerAsOfficial && !ledgerConfirmedAsOfficial;
  }, [
    currentCompany.confirmedAsOfficial.status,
    userApprovedLedgerAsOfficialQuery.data,
  ]);

  const initialMustConfirmLedgerAsOfficialRef = useRef(
    mustConfirmLedgerAsOfficial
  );

  function getPercentageFromRule() {
    switch (state.newPolicy.rule) {
      case "Any":
        return 0;
      case "All":
        return 1;
      case "Percentage":
        return (state.newPolicy.percentage ?? 0) / 100;
      default:
        return null;
    }
  }

  function createNewProposalForBoardMember(signature: string) {
    if (userRoleInCompany !== "BoardMember" && !mockSignEnabled) {
      return;
    }
    if (["Percentage", "Any", "All"].includes(state.newPolicy.rule)) {
      const percentage = getPercentageFromRule();
      const data = {
        percentage: percentage as number,
        signature,
      };
      if (mustConfirmLedgerAsOfficial) {
        confirmLedgerAsOfficialAndApprovalRulePercentageMutation.mutate(data);
      } else {
        approvalRulePercentageMutation.mutate(data);
      }
    } else if (state.newPolicy.rule === "Manual") {
      const data = {
        users: state.newPolicy.approvers,
        signature,
      };
      if (mustConfirmLedgerAsOfficial) {
        confirmLedgerAsOfficialAndApprovalRuleSpecificMutation.mutate(data);
      } else {
        approvalRuleSpecificMutation.mutate(data);
      }
    }
    setCurrentStep(steps.DONE_STEP);
    clearStateFromLocalStorage();
  }

  function toNextStep(approvers?: string[]) {
    if (currentStep === steps.SELECT_POLICY_STEP && approvers) {
      const manualApproverhasViewer = checkManualApproverHasViewer(
        usersQuery.data ?? [],
        approvers
      );

      if (manualApproverhasViewer) {
        setUpgradeUsersModal(true);
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  }

  const isLoading = useMemo(
    () =>
      activeApprovalRuleQuery.isLoading ||
      userApprovedLedgerAsOfficialQuery.isLoading ||
      usersQuery.isLoading ||
      confirmLedgerAsOfficialAndApprovalRulePercentageMutation.isLoading ||
      confirmLedgerAsOfficialAndApprovalRuleSpecificMutation.isLoading ||
      approvalRulePercentageMutation.isLoading ||
      approvalRuleSpecificMutation.isLoading ||
      getMockedSignatureMutation.isLoading ||
      approvalRuleProposal.isLoading,
    [
      activeApprovalRuleQuery.isLoading,
      approvalRulePercentageMutation.isLoading,
      approvalRuleSpecificMutation.isLoading,
      confirmLedgerAsOfficialAndApprovalRulePercentageMutation.isLoading,
      confirmLedgerAsOfficialAndApprovalRuleSpecificMutation.isLoading,
      userApprovedLedgerAsOfficialQuery.isLoading,
      usersQuery.isLoading,
      approvalRuleProposal.isLoading,
      getMockedSignatureMutation.isLoading,
    ]
  );

  const error = useMemo(
    () =>
      activeApprovalRuleQuery.error ||
      userApprovedLedgerAsOfficialQuery.error ||
      usersQuery.error ||
      confirmLedgerAsOfficialAndApprovalRulePercentageMutation.error ||
      confirmLedgerAsOfficialAndApprovalRuleSpecificMutation.error ||
      approvalRulePercentageMutation.error ||
      approvalRuleSpecificMutation.error ||
      getMockedSignatureMutation.error,
    [
      activeApprovalRuleQuery.error,
      approvalRulePercentageMutation.error,
      approvalRuleSpecificMutation.error,
      confirmLedgerAsOfficialAndApprovalRulePercentageMutation.error,
      confirmLedgerAsOfficialAndApprovalRuleSpecificMutation.error,
      userApprovedLedgerAsOfficialQuery.error,
      usersQuery.error,
      getMockedSignatureMutation.error,
    ]
  );

  useEffect(() => {
    const stateFromLocalStorage = loadStateFromLocalStorage();
    const code = searchParams.get("truidCode");
    const signatureRequestState = searchParams.get("truidState");
    if (!!stateFromLocalStorage && !!code && !!signatureRequestState) {
      setState(stateFromLocalStorage);
      setCurrentStep(steps.SIGN_STEP);
      return;
    }
    if (currentCompany.confirmedAsOfficial.status === "Completed") {
      setCurrentStep(steps.SELECT_POLICY_STEP);
      return;
    }

    userApprovedLedgerAsOfficialQuery
      .refetch()
      .then((response) => {
        if (response.data) {
          setCurrentStep(steps.SELECT_POLICY_STEP);
        } else {
          setCurrentStep(steps.ACCEPT_LEDGER_AS_OFFICIAL_STEP);
        }

        return response.data;
      })
      .catch(() => ({}));
  }, []);

  useEffect(() => {
    if (currentStep === steps.SIGN_STEP && loginType === "Truid") {
      saveStateToLocalStorage(currentCompany.orgNumber, state);
    }
  }, [currentStep, loginType]);

  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (componentRef.current) {
        componentRef.current.scrollIntoView({ behavior: "auto" });
      }
    }, 0);
  }, [currentStep]);

  const stepToFormId = {
    [steps.ACCEPT_LEDGER_AS_OFFICIAL_STEP]: "accept-ledger-as-official",
    [steps.SELECT_POLICY_STEP]: "select-policy",
  };

  const currentState: NewPolicy | undefined = useMemo(() => {
    if (
      activeApprovalRuleQuery.data &&
      activeApprovalRuleQuery.data.rule !== "None"
    ) {
      if (activeApprovalRuleQuery.data.rule === "BoardPercentage") {
        const percentage = activeApprovalRuleQuery.data.percentage / 10;
        if (percentage === 0) {
          return {
            rule: "Any",
            approvers: [],
          };
        }
        if (percentage === 100) {
          return {
            rule: "All",
            approvers: [],
          };
        }
        return {
          rule: "Percentage",
          approvers: [],
          percentage,
        };
      }
      if (activeApprovalRuleQuery.data.rule === "SpecificUsers") {
        return {
          rule: "Manual",
          approvers: activeApprovalRuleQuery.data.users.map((x) => x.id),
        };
      }
    }
    return undefined;
  }, [activeApprovalRuleQuery.data]);

  const stepComponent = useMemo(() => {
    const language: "sv" | "en" = i18n.i18n.language.split("-")[0] as
      | "sv"
      | "en";

    switch (currentStep) {
      case steps.ACCEPT_LEDGER_AS_OFFICIAL_STEP:
        return (
          <AcceptLedgerAsOfficial
            isChecked={!!state.acceptLedgerAsOfficial}
            formId={stepToFormId[steps.ACCEPT_LEDGER_AS_OFFICIAL_STEP]!}
            onSubmit={(isChecked) => {
              if (state.acceptLedgerAsOfficial !== isChecked) {
                setState((prevState) => ({
                  ...prevState,
                  acceptLedgerAsOfficial: isChecked,
                }));
              }
              toNextStep();
            }}
            companyName={currentCompany.name}
          />
        );
      case steps.SELECT_POLICY_STEP:
        if (!ruleToCurrentPolicy) {
          return null;
        }
        return (
          <ChangeApprovalPolicy
            currentCompany={currentCompany}
            formId={stepToFormId[steps.SELECT_POLICY_STEP]!}
            users={usersQuery.data ?? []}
            existingPolicy={currentState}
            currentValues={state.newPolicy}
            onSubmit={(data) => {
              setState((prevState) => ({
                ...prevState,
                newPolicy: {
                  rule: data.rule,
                  approvers: data.approvers || [],
                  percentage: data.percentage || undefined,
                },
              }));
              toNextStep(data.approvers);
            }}
          />
        );
      case steps.PREVIEW_STEP:
        return (
          <PreviewNewPolicyProposalChanges
            policy={state.newPolicy}
            acceptLedgerAsOfficial={state.acceptLedgerAsOfficial}
            entities={entitiesQuery.data ?? []}
          />
        );
      case steps.SIGN_STEP: {
        const manualApprovers =
          state.newPolicy.approvers.map((approver) => {
            const entity = approversOptions?.find((e) => e.id === approver);
            if (!entity) {
              monitoring.captureException(
                "Could not find entity for manual approver",
                {
                  extra: {
                    approver,
                    entities: entitiesQuery.data,
                  },
                }
              );
              throw new Error("Could not find entity for manual approver");
            }
            return entity.name;
          }) ?? [];
        const payload = {
          language,
          confirmAsOfficial: !!state.acceptLedgerAsOfficial,
          manualApprovers:
            state.newPolicy.rule === "Manual" ? manualApprovers : undefined,
          percentage:
            state.newPolicy.rule === "All"
              ? 100
              : state.newPolicy.rule === "Any"
              ? 0
              : state.newPolicy.rule === "Percentage"
              ? state.newPolicy.percentage
              : undefined,
        };
        if (loginType === "Truid") {
          return (
            <TruidSign
              request={payload}
              onSuccess={({ signature }) =>
                createNewProposalForBoardMember(signature)
              }
              onCancel={() => clearStateFromLocalStorage()}
            />
          );
        }
        return (
          <BankIdSign
            request={payload}
            onSuccess={({ signature }) => {
              createNewProposalForBoardMember(signature);
            }}
            onCancel={() => setCurrentStep(currentStep - 1)}
          />
        );
      }

      case steps.DONE_STEP:
        return (
          <Done
            orgNumber={currentCompany.orgNumber}
            onlyInitiation={userRoleInCompany === "Administrator"}
          />
        );
      default:
        return null;
    }
  }, [
    currentStep,
    state,
    currentCompany,
    ruleToCurrentPolicy,
    usersQuery.data,
    entitiesQuery.data,
    loginType,
    i18n.i18n.language,
  ]);

  if (ruleToCurrentPolicy === undefined) {
    return <>{i18n.t("error.approvalPolicy.fetchError")}</>;
  }

  const viewerApproverIds = getViewerApproverIds(
    usersQuery.data ?? [],
    getApproversIds(state.newPolicy.approvers)
  );
  const viewerApprovers = getViewerApprovers(
    approversOptions,
    viewerApproverIds
  );

  const totalSteps = initialMustConfirmLedgerAsOfficialRef.current ? 3 : 2;
  const skippedSteps = initialMustConfirmLedgerAsOfficialRef.current ? 0 : 1;
  const finishedSteps = [
    steps.ACCEPT_LEDGER_AS_OFFICIAL_STEP,
    steps.SELECT_POLICY_STEP,
    steps.PREVIEW_STEP,
  ].includes(currentStep)
    ? currentStep - 1 - skippedSteps
    : steps.SIGN_STEP
    ? currentStep - 2 - skippedSteps
    : steps.DONE_STEP
    ? totalSteps
    : 0;

  function canGoBack() {
    if (mustConfirmLedgerAsOfficial) {
      return (
        currentStep !== steps.ACCEPT_LEDGER_AS_OFFICIAL_STEP &&
        currentStep !== steps.DONE_STEP
      );
    }

    return (
      currentStep !== steps.SELECT_POLICY_STEP &&
      currentStep !== steps.DONE_STEP
    );
  }

  function createNewProposalForAdmin() {
    if (userRoleInCompany !== "Administrator") {
      return;
    }
    if (["Percentage", "Any", "All"].includes(state.newPolicy.rule)) {
      approvalRulePercentageMutation.mutate({
        percentage: getPercentageFromRule() as number,
      });
    } else if (state.newPolicy.rule === "Manual") {
      approvalRuleSpecificMutation.mutate({
        users: state.newPolicy.approvers,
      });
    }
    setCurrentStep(steps.DONE_STEP);
  }

  const signStep = steps.PREVIEW_STEP;

  const actions = userCanChangeApprovalPolicy ? (
    <div className="tw-flex tw-gap-2">
      {canGoBack() && (
        <Button
          className="tw-w-full md:tw-w-auto"
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
          disabled={isLoading}
        >
          {currentStep === steps.SIGN_STEP
            ? i18n.t("label.cancel")
            : i18n.t("label.back")}
        </Button>
      )}
      {(currentStep === steps.ACCEPT_LEDGER_AS_OFFICIAL_STEP ||
        (currentStep === steps.SELECT_POLICY_STEP && !canGoBack()) ||
        (mustConfirmLedgerAsOfficial &&
          currentStep === steps.SELECT_POLICY_STEP)) && (
        <Button
          className="tw-w-full md:tw-w-auto"
          color="primary"
          variant="solid"
          type="submit"
          form={stepToFormId[currentStep]}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {i18n.t("label.next")}
        </Button>
      )}
      {currentStep === signStep && userRoleInCompany === "BoardMember" && (
        <Button
          className="tw-w-full md:tw-w-auto"
          color="primary"
          variant="solid"
          prefix={
            loginType === "Truid" ? <TruidIcon color="white" /> : <BankIdIcon />
          }
          disabled={isLoading}
          onClick={() => setCurrentStep(steps.SIGN_STEP)}
          isLoading={isLoading}
        >
          {i18n.t("approvalPolicy.signAndApprovePolicy")}
        </Button>
      )}
      {currentStep === signStep && mockSignEnabled && (
        <Button
          className="tw-w-full md:tw-w-auto"
          color="kvanta-elegant"
          variant="solid"
          prefix={<MagicWand />}
          disabled={isLoading}
          onClick={() => {
            getMockedSignatureMutation
              .mutateAsync()
              .then((mockedSignature) => {
                if (mockedSignature) {
                  createNewProposalForBoardMember(mockedSignature);
                }
                return mockedSignature;
              })
              .catch(() => {});
          }}
          isLoading={isLoading}
        >
          {i18n.t("approvalPolicy.mockSignAndApprovePolicy")}
        </Button>
      )}
      {currentStep === signStep && userRoleInCompany === "Administrator" && (
        <Button
          className="tw-w-full md:tw-w-auto"
          color="primary"
          variant="solid"
          disabled={isLoading}
          onClick={() => createNewProposalForAdmin()}
          isLoading={isLoading}
        >
          {i18n.t("label.send")}
        </Button>
      )}
      {currentStep === steps.DONE_STEP && (
        <Button
          className="tw-w-full md:tw-w-auto"
          color="primary"
          variant="solid"
          disabled={isLoading}
          onClick={() => {
            navigate("../");
          }}
        >
          {i18n.t("label.done")}
        </Button>
      )}
    </div>
  ) : null;

  return (
    <ProgressDialog
      onClose={() => {
        navigate("../");
      }}
      isLoading={isLoading}
      totalSteps={totalSteps}
      currentStep={finishedSteps}
      actions={actions}
    >
      <div ref={componentRef}>
        <PageWrapper
          className={`tw-flex tw-h-full tw-max-w-xl tw-flex-col tw-pb-12 tw-pt-6 ${
            [steps.DONE_STEP, steps.SIGN_STEP].includes(currentStep)
              ? "tw-justify-center"
              : "tw-justify-start"
          }`}
        >
          {isLoading && <Loading />}
          {!isLoading && !error && userCanChangeApprovalPolicy && stepComponent}
          {!isLoading && !error && !userCanChangeApprovalPolicy && (
            <Alert className="tw-my-2" type="error">
              {i18n.t("error.verification.unauthorized")}
            </Alert>
          )}
          {!isLoading && error && (
            <Alert className="tw-my-2" type="error">
              {i18n.t(error.errors[0].message.code)}
            </Alert>
          )}
        </PageWrapper>
      </div>

      {upgradeUsersModal && (
        <UpgradeUserModal
          mutation={updateUsersMutation}
          onClose={() => setUpgradeUsersModal(false)}
          allUserIds={viewerApproverIds}
          viewerApprovers={viewerApprovers}
        />
      )}
    </ProgressDialog>
  );
};

export { ChangeShareLedgerSettings };
export type { NewPolicy };
